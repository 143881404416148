import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';
import * as yup from 'yup';

import SUPPORTED_LANGUAGES from 'constants/speechSynthesisSupportedLanguages';
import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import InputRich2 from 'uikit/InputRich2';
import Select from 'uikit/Select';
import Switch from 'uikit/Switch';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Flashcard/Editor',
  });
  const { t: tLanguage } = useTranslation();

  const [, , { setValue: setTitleLang }] = useField('titleLang');
  const [, , { setValue: setFeedbackLang }] = useField('feedbackLang');
  const [{ value: useSpeech }, , { setValue: setUseSpeech }] =
    useField('useSpeech');

  function handleFeedbackLangChange({ target: { value: newLang } }) {
    setFeedbackLang(newLang);
    storeJS.set('feedbackLang', newLang);
  }

  function handleTitleLangChange({ target: { value: newLang } }) {
    setTitleLang(newLang);
    storeJS.set('titleLang', newLang);
  }

  function handleUseSpeechChange() {
    setUseSpeech(!useSpeech);
    storeJS.set('useSpeech', !useSpeech);
  }

  return (
    <div className={cx('container', className)}>
      {useSpeech && (
        <Select
          className={cx('select')}
          label={t('title-language-label')}
          name="titleLang"
          onChange={handleTitleLangChange}
        >
          {SUPPORTED_LANGUAGES.map((language) => (
            <option
              disabled={language.code === ''}
              key={language.code}
              value={language.code}
            >
              {tLanguage(language.name)}
            </option>
          ))}
        </Select>
      )}
      <InputRich2
        data-testid="feedback"
        label={t('verso-label')}
        name="feedback"
        placeholder={t('verso-placeholder')}
      />
      {useSpeech && (
        <Select
          className={cx('select', 'select-feedback')}
          label={t('feedback-language-label')}
          name="feedbackLang"
          onChange={handleFeedbackLangChange}
        >
          {SUPPORTED_LANGUAGES.map((language) => (
            <option
              disabled={language.code === ''}
              key={language.code}
              value={language.code}
            >
              {tLanguage(language.name)}
            </option>
          ))}
        </Select>
      )}
      <Options className={cx('options')}>
        <Tags className={cx('field')} />
        <Switch
          className={cx('field')}
          label={t('use-speech')}
          name="useSpeech"
          onChange={handleUseSpeechChange}
        />
        <Switch
          className={cx('field')}
          label={t('randomize-label')}
          name="randomizeFlashcard"
        />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Flashcard/Editor/recto-error-required')),
    feedback: yup
      .string()
      .trim()
      .required(t('Questions/Flashcard/Editor/verso-error-required')),
    titleLang: yup.string().trim().default('fr'),
    feedbackLang: yup.string().trim().default('fr'),
    useSpeech: yup.bool().default(true),
    randomizeFlashcard: yup.boolean().default(false),
  });

export default Editor;
