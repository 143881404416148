import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import { formatMatches } from '../utils';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { theme } = useContext(Context);

  const matches = formatMatches(question.content.matches);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      {matches.map((match, sourceIndex) => {
        const solution = question.solution[sourceIndex];
        const user = matches.find((m) => m.idx === solution?.user);
        const correct = matches.find((m) => m.idx === solution?.correct);

        return (
          <div className={cx('match')} key={match.idx}>
            <FormattedText className={cx('match-title')}>
              {match.source}
            </FormattedText>
            <div className={cx('answers')}>
              {!solution?.isCorrect && (
                <div className={cx('answer', 'wrong')}>
                  <Icon className={cx('icon')} name="times" />
                  <FormattedText className={cx('text')}>
                    {user?.destination || '___'}
                  </FormattedText>
                </div>
              )}
              <div className={cx('answer', 'correct')}>
                <Icon className={cx('icon')} name="check" />
                <FormattedText className={cx('text')}>
                  {correct?.destination || '___'}
                </FormattedText>
              </div>
            </div>
          </div>
        );
      })}
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = (question) =>
  question.content.matches.map(() => -1);

Verso.defaultSolution = (question) =>
  question.content.matches.map((match) => ({
    idx: match.idx,
    user: null,
    correct: match.idx,
    isCorrect: false,
  }));


export default Verso;
