import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ALPHABET from 'constants/alphabet';
import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Matching/Aggregate',
  });

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('correct-answers', {
                count: aggregation.nCorrectAnswers,
              })}
            </span>
            <span
              className={cx('total-answers', {
                'total-answers-wrong': aggregation.nCorrectAnswers === 0,
              })}
            >
              {` / ${aggregation.nTotalAnswers}`}
            </span>
          </p>
        </div>
        {question.content.matches.map((match, index) => {
          const successPercentage =
            (aggregation.aggregatedAnswers[index] * 100) /
            aggregation.nTotalAnswers;
          return (
            <div key={match.idx}>
              <p className={cx('good-answers')}>
                {t('good-answers', {
                  count: aggregation.aggregatedAnswers[index],
                })}
              </p>
              <div className={cx('match')} key={match.idx}>
                <FormattedText className={cx('match-source')}>
                  {match.source}
                </FormattedText>
                <div className={cx('match-index', 'source-index')}>
                  {index + 1}
                </div>
                <div className={cx('result')}>
                  <div className={cx('gauge')}>
                    <div
                      className={cx('progression')}
                      style={{
                        width: `${successPercentage}%`,
                      }}
                    />
                    <div className={cx('animation')} />
                  </div>
                </div>
                <div className={cx('match-index', 'destination-index')}>
                  {ALPHABET[index]}
                </div>
                <FormattedText className={cx('match-destination')}>
                  {match.destination}
                </FormattedText>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
