import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';

import styles from './LegendPin.module.scss';

const cx = classNames.bind(styles);

const LegendPin = ({ index, isCorrect, positionData }) => {
  const { theme } = useContext(Context);

  return (
    <div
      className={cx('legend-pin', `theme-${theme}`, { isCorrect })}
      style={{ left: `${positionData.left}px`, top: `${positionData.top}px` }}
    >
      {index + 1}
    </div>
  );
};
LegendPin.propTypes = {
  index: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  positionData: PropTypes.object.isRequired,
};

export default LegendPin;
