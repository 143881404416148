import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationFolderUpdate } from 'gql/mutations/folder';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/ModalLegacy';

import styles from './FolderEdit.module.scss';

const cx = classNames.bind(styles);

const FolderEdit = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Dashboard/FolderEdit',
  });

  const mutationFolderUpdate = useMutationFolderUpdate();

  const {
    data: {
      editor: { status: editorStatus, folderEditing },
    },
  } = useQueryLocalEditor();

  async function handleSave(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationFolderUpdate(folderEditing, variables);
      mutationEditorClear();
      trackEvent('folder', 'update');
    } catch (err) {
      form.setStatus('500');
      reportError('folder', 'update', err);
    }
  }

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(t('title-error-required'))
      .max(256, t('title-error-required')),
  });

  const initialValues = folderEditing
    ? {
        title: '',
        ...folderEditing,
      }
    : {};

  const isModalOpen = editorStatus === EDITOR_STATUSES.FOLDER_UPDATE;

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form className={cx('modal', 'large')}>
            <Button
              aria-label={t('aria-close')}
              className={cx('close')}
              icon="times"
              onClick={mutationEditorClear}
            />
            <h1 className={cx('title')}>{t('title')}</h1>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('folder-edit-error')}
              </p>
            )}
            <InputText
              autoFocus
              className={cx('field')}
              label={t('title-label')}
              name="title"
              maxLength={256}
            />
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('save-folder')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FolderEdit;
