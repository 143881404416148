import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Essay/Editor',
  });

  return (
    <div className={cx('container', className)}>
      <InputRich
        data-testid="feedback"
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Essay/Editor/title-error-required')),
    feedback: yup.string().trim(),
  });

export default Editor;
