import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalApp } from 'gql/queries/local';
import useSiteContext from 'hooks/useSiteContext';

import SubPage from './SubPage';

const SubPageContainer = ({ isOpen, ...props }) => {
  const { t } = useTranslation();
  const { isInTeams } = useSiteContext();
  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();
  return (
    <SubPage
      isInTeams={isInTeams}
      isMenuExpanded={isMenuExpanded}
      isOpen={isOpen}
      t={t}
      {...props}
    />
  );
};

SubPageContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SubPageContainer;
