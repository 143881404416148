import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import CheckBoxTrueFalse from 'uikit/CheckBoxTrueFalse';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/TrueOrFalse/Recto',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>
          {t('true-or-false-instructions')}
        </div>
      )}
      {question.content.choices.map((choice, index) => {
        const answerIndex = question.answer
          ? question.answer.findIndex((a) => a.idx === choice.idx)
          : index;
        // eslint-disable-next-line
        return (
          <div className={cx('choice')} key={choice.idx}>
            <FormattedText className={cx('choice-title')}>
              {choice.title}
            </FormattedText>
            <CheckBoxTrueFalse
              isDisabled={isDisabled}
              name={`answer.${answerIndex}.answer`}
              shouldHideError
            />
          </div>
        );
      })}
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: undefined,
  }));

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          answer: yup.boolean().required(),
        }),
      )
      .compact(),
  });

export default Recto;
