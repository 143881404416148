import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/ShortAnswer/Recto',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <InputText
        autoCapitalize="off"
        autoComplete="off"
        autoFocus
        autoCorrect="off"
        data-testid="short-answer-answer"
        isDisabled={isDisabled}
        label={t('answer')}
        name="answer"
        spellCheck="false"
        shouldHideError
      />
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => '';

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup.string().trim().required(),
  });

export default Recto;
