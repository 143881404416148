import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Modal from 'uikit/ModalLegacy';

import AudioPreview from '../AudioPreview';
import styles from './AudioRecorder.module.scss';

const cx = classNames.bind(styles);

const AudioRecorder = ({
  audioDuration,
  audioPreview,
  audioRef,
  className,
  btnRecordStop,
  handleCurrentTimeUpdate,
  handleModalCloseAndReset,
  handleRecordAudio,
  handleRecordedAudioReset,
  handleRecordingRestart,
  handleSelect,
  isLoading,
  isModalOpen,
  isRecording,
  isRestarting,
  isSubmitting,
  t,
  theme,
  timer,
}) => (
  <div
    className={cx(
      className,
      'container',
      `theme-${theme}`,
    )}
  >
    <Button
      className={cx('open-modal-button')}
      icon="microphone-alt"
      intent="white"
      onClick={handleRecordAudio}
    >
      {t('uikit/AudioPicker/AudioRecorder/record')}
    </Button>
    <Modal
      className={cx(
        { 'hidden-overlay': isRecording },
        `theme-${theme}`,
      )}
      isOpen={isModalOpen}
      onExit={isLoading || isRecording ? null : handleModalCloseAndReset}
    >
      {isLoading && <h1 className={cx('countdown')}>{timer}</h1>}
      {(isLoading || isRecording) && (
        <div className={cx('audio-preview')}>
          <div className={cx('stream')}>
            <Icon className={cx('microphone-icon')} name="microphone-alt" />
            {[1, 2, 3].map(() => (
              <div className={cx('circle', { animated: isRecording })} />
            ))}
          </div>
          <audio
            autoPlay
            id="audioStream"
            muted
            onTimeUpdate={handleCurrentTimeUpdate}
            ref={audioRef}
          >
            <track default kind="captions" />
          </audio>
          <div className={cx('controller')}>
            <Button
              className={cx('stop-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="stop"
              intent="white"
              ref={btnRecordStop}
            />
            <div className={cx('duration')}>{audioDuration}</div>
            <Button
              className={cx('restart-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="arrow-rotate-right"
              intent="white"
              onClick={handleRecordingRestart}
            />
            <Button
              className={cx('delete-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="trash-alt"
              intent="white"
              onClick={handleModalCloseAndReset}
            />
          </div>
        </div>
      )}
      {audioPreview && !isRestarting && (
        <div className={cx('modal', 'large')}>
          <div className={cx('header')}>
            <h1 className={cx('title')}>
              {t('uikit/AudioPicker/AudioRecorder/save-record')}
            </h1>
            <Button
              aria-label={t('uikit/AudioPicker/AudioRecorder/aria-close')}
              icon="times"
              onClick={handleModalCloseAndReset}
            />
          </div>
          {audioPreview && !isRecording && (
            <div className={cx('recorded-preview-container')}>
              <div className={cx('recorded-preview')}>
                <AudioPreview audio={audioPreview} />
                <div className={cx('footer')}>
                  <Button
                    className={cx('retake-button')}
                    onClick={handleRecordedAudioReset}
                    size="large"
                  >
                    {t('uikit/AudioPicker/AudioRecorder/delete')}
                  </Button>
                  <Button
                    className={cx('submit-button')}
                    intent="primary"
                    isLoading={isSubmitting}
                    onClick={handleSelect}
                    size="large"
                  >
                    {t('uikit/AudioPicker/AudioRecorder/select')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {isRecording ? (
            <div
              aria-label={t('uikit/AudioPicker/AudioRecorder/aria-recording')}
              role="alert"
            />
          ) : (
            audioPreview && (
              <div
                aria-label={t(
                  'uikit/AudioPicker/AudioRecorder/aria-recording-over',
                )}
                role="alert"
              />
            )
          )}
        </div>
      )}
    </Modal>
  </div>
);

AudioRecorder.propTypes = {
  audioDuration: PropTypes.number.isRequired,
  audioPreview: PropTypes.string,
  audioRef: PropTypes.object,
  className: PropTypes.string,
  btnRecordStop: PropTypes.object,
  handleCurrentTimeUpdate: PropTypes.func.isRequired,
  handleModalCloseAndReset: PropTypes.func.isRequired,
  handleRecordAudio: PropTypes.func.isRequired,
  handleRecordedAudioReset: PropTypes.func.isRequired,
  handleRecordingRestart: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isRestarting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  timer: PropTypes.number.isRequired,
};

AudioRecorder.defaultProps = {
  btnRecordStop: undefined,
  className: undefined,
  audioPreview: undefined,
  audioRef: undefined,
};

export default AudioRecorder;
