import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/ShortAnswer/Aggregate',
  });

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('correct-answers', {
                count: aggregation.nCorrectAnswers,
              })}
            </span>
            <span
              className={cx('total-answers', {
                'total-answers-wrong': aggregation.nCorrectAnswers === 0,
              })}
            >
              {` / ${aggregation.nTotalAnswers}`}
            </span>
          </p>
        </div>
        <p className={cx('answers-title')}>{t('frequent-answers')}</p>
        <div className={cx('user-answers-container')}>
          {aggregation.aggregatedAnswers.map((answer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cx('answer')} key={index}>
              <p className={cx('answer-title')}>{answer.title}</p>
              <div className={cx('occurrence')}>
                <div className={cx('occurrence-number')}>
                  {answer.occurrence}
                </div>
                <Icon className={cx('occurrence-icon')} name="user" />
              </div>
            </div>
          ))}
        </div>
        <div className={cx('good-answers-container')}>
          <p className={cx('good-answers-title')}>{t('good-answers')}</p>
          <div
            className={cx('good-answers', {
              multiple: question.content.answers.length > 1,
            })}
          >
            {question.content.answers.map((goodAnswer, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className={cx('good-answer')} key={index}>
                {goodAnswer}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
