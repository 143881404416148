import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useMutationCourseUpdate } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import styles from './CourseTitle.module.scss';

const cx = classNames.bind(styles);

const CourseTitle = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseTitle',
  });

  const mutationCourseUpdate = useMutationCourseUpdate();

  const [isEditing, setEditing] = useState(false);

  const handleEditDone = async (values) => {
    try {
      const title = extract(values.title, yup.string().trim().max(256));
      if (title === course.title) {
        setEditing(false);
        return;
      }
      await mutationCourseUpdate(course, {
        title,
      });
      trackEvent('course', 'update');
    } catch (err) {
      reportError('course', 'update', err);
    } finally {
      setEditing(false);
    }
  };

  const handleTitleEdit = () => {
    setEditing(true);
  };

  const handleTitleMount = (ref) => {
    if (ref) {
      ref.select();
    }
  };

  return (
    <div className={cx('container', className)}>
      {isEditing ? (
        <Formik
          initialValues={{
            title: course.title,
          }}
          onSubmit={handleEditDone}
        >
          {({ isSubmitting, submitForm }) => (
            <Form className={cx('form')}>
              <InputText
                className={cx('field')}
                name="title"
                onBlur={submitForm}
                ref={handleTitleMount}
              />
              <Button icon="check" isLoading={isSubmitting} type="submit" />
            </Form>
          )}
        </Formik>
      ) : (
        <div className={cx('title-container')}>
          <h1 className={cx('title')}>{course.title}</h1>
          <Button
            aria-label={t('edit')}
            className={cx('edit-button')}
            icon="pencil"
            onClick={handleTitleEdit}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

CourseTitle.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default CourseTitle;
