import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { notify } from 'uikit/Notifications';
import SubPage from 'uikit/SubPageLegacy';
import Tabs from 'uikit/Tabs';

import QuestionImportCourse from './QuestionImportCourse/QuestionImportCourse';
import QuestionImportFile from './QuestionImportFile/QuestionImportFile';
import QuestionImportList from './QuestionImportList/QuestionImportList';
import QuestionImportPublicCourses from './QuestionImportPublicCourses/QuestionImportPublicCourses';
import QuestionImportWooclap from './QuestionImportWooclap/QuestionImportWooclap';

import styles from './QuestionImport.module.scss';

const cx = classNames.bind(styles);

const QuestionImport = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionImport',
  });

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const handleClose = (questions) => {
    if (Array.isArray(questions)) {
      if (!questions.length) {
        notify('error', t('question-import-error-empty'));
      } else {
        notify(
          'success',
          t('question-import-success', {
            count: questions.length,
          }),
        );
      }
    }
    mutationEditorClear();
  };

  const isModalOpen = editorStatus === EDITOR_STATUSES.QUESTION_IMPORT;

  return (
    <SubPage
      isOpen={isModalOpen}
      onExit={handleClose}
      subtitle={course.title}
      title={t('import')}
    >
      <div className={cx('subpage')}>
        <Tabs className={cx('tabs')}>
          <Tabs.Tab label={t('from-course')}>
            <QuestionImportCourse
              course={course}
              handleModalClose={handleClose}
            />
          </Tabs.Tab>
          <Tabs.Tab label={t('from-file')}>
            <QuestionImportFile
              course={course}
              handleModalClose={handleClose}
            />
          </Tabs.Tab>
          <Tabs.Tab label={t('from-list')}>
            <QuestionImportList
              course={course}
              handleModalClose={handleClose}
            />
          </Tabs.Tab>
          <Tabs.Tab label={t('from-wooclap')}>
            <QuestionImportWooclap
              course={course}
              handleModalClose={handleClose}
            />
          </Tabs.Tab>
          <Tabs.Tab label={t('from-public-courses')}>
            <QuestionImportPublicCourses course={course} />
          </Tabs.Tab>
        </Tabs>
      </div>
    </SubPage>
  );
};

QuestionImport.propTypes = {
  course: PropTypes.object.isRequired,
};

export default QuestionImport;
