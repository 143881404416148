import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/ModalLegacy';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled = false, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Categorization/Recto',
  });

  const { theme } = useContext(Context);

  const [selectedSourceIndex, setSelectedSourceIndex] = useState(-1);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  function handleDestinationSelect(destinationIdx) {
    setAnswer(
      answer.map((destinationsIdx, sourceIndex) =>
        sourceIndex === selectedSourceIndex
          ? destinationsIdx.includes(destinationIdx)
            ? destinationsIdx.filter(
                (SelectedDestinationId) =>
                  SelectedDestinationId !== destinationIdx,
              )
            : [...destinationsIdx, destinationIdx]
          : destinationsIdx.filter(
              (SelectedDestinationId) =>
                SelectedDestinationId !== destinationIdx,
            ),
      ),
    );
  }

  function handleDestinationRemove(sourceIndex, destinationIdx) {
    if (!isDisabled) {
      setAnswer(
        answer.map((destinationsIdx, index) =>
          sourceIndex === index
            ? destinationsIdx.filter((idx) => idx !== destinationIdx)
            : destinationsIdx,
        ),
      );
    }
    handleSourceSelect(-1);
  }

  function handleSourceSelect(sourceIndex) {
    if (!isDisabled) {
      setSelectedSourceIndex(sourceIndex);
    }
  }

  const destinations =
    question.content.categories.destinations ||
    question.content.categories.flatMap((category) => category.destinations);

  const sources =
    question.content.categories.sources ||
    question.content.categories.map((category) => ({
      idx: category.idx,
      title: category.source,
    }));

  const selectedSource =
    selectedSourceIndex === -1 ? undefined : sources[selectedSourceIndex];

  return (
    <>
      <div className={cx('container', className, `theme-${theme}`)}>
        <FormattedText as="h1" className={cx('title')}>
          {question.title}
        </FormattedText>
        {!isDisabled && (
          <div className={cx('instructions')}>{t('instructions')}</div>
        )}
        {sources.map((source, sourceIndex) => {
          const categorized = destinations.filter((destination) =>
            answer[sourceIndex].includes(destination.idx),
          );
          return (
            <div
              className={cx('category', {
                isDisabled,
              })}
              key={source.idx}
            >
              <div
                className={cx('inner')}
                onClick={() => handleSourceSelect(sourceIndex)}
                role="button"
              >
                <FormattedText className={cx('category-title')}>
                  {source.title}
                </FormattedText>
                {categorized.length > 0 ? (
                  <div className={cx('category-destinations')}>
                    {categorized.map((destination) => (
                      <div className={cx('category-destination-container')}>
                        <FormattedText className={cx('category-destination')}>
                          {destination.title}
                        </FormattedText>
                        <button
                          aria-label={t('aria-remove-categorization')}
                          className={cx('remove')}
                          onClick={() =>
                            handleDestinationRemove(
                              sourceIndex,
                              destination.idx,
                            )
                          }
                          type="button"
                        >
                          <Icon name="times" />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{t('category')}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Modal isOpen={!!selectedSource} onExit={() => handleSourceSelect(-1)}>
        <div className={cx('modal', `theme-${theme}`)}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={() => handleSourceSelect(-1)}
          />
          <FormattedText className={cx('modal-title')}>
            {selectedSource?.title}
          </FormattedText>
          {destinations.map((destination) => (
            // eslint-disable-next-line
            <button
              className={cx('category', 'inner', {
                isSelected: answer[selectedSourceIndex]?.includes(
                  destination.idx,
                ),
                isSelectedForOtherCourse: answer
                  .filter((a, index) => selectedSourceIndex !== index)
                  .some((a) => a.includes(destination.idx)),
              })}
              key={destination.idx}
              onClick={() => handleDestinationSelect(destination.idx)}
            >
              <FormattedText tabIndex={-1}>{destination.title}</FormattedText>
            </button>
          ))}
          <Button
            className={cx('validate-button')}
            intent="primary"
            onClick={() => handleSourceSelect(-1)}
            size="large"
          >
            {t('validate')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  question.content.categories.sources
    ? question.content.categories.sources.map(() => [])
    : question.content.categories.map(() => []);

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(yup.array().of(yup.number().integer()).required())
      .test('atLeastOne', (arr) => arr.some((v) => v.length)),
  });

export default Recto;
