import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import CheckBoxTrueFalse from 'uikit/CheckBoxTrueFalse';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/TrueOrFalse/Editor',
  });

  const [{ value: choices }, , { setValue: setChoices }] = useField('choices');
  const [{ value: addFeedbacks }, , { setValue: setAddFeedbacks }] =
    useField('feedbacks');

  useEffect(() => {
    const choicesFeedbacks = choices.filter(
      (choice) => choice.feedback !== null && choice.feedback !== '',
    );
    setAddFeedbacks(choicesFeedbacks.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChoiceAdd() {
    setChoices([
      ...choices,
      {
        idx: choices.length,
        title: '',
        answer: false,
        feedback: '',
      },
    ]);
  }

  function handleChoiceComplete(model) {
    if (model) {
      setChoices([
        ...choices,
        {
          idx: choices.length,
          title: model,
          answer: false,
          feedback: '',
        },
      ]);
    }
  }

  function handleChoiceRemove(index) {
    const newChoices = choices.filter((a, i) => i !== index);
    if (newChoices.length < 1) {
      newChoices.push({
        idx: newChoices.length,
        title: '',
        answer: false,
      });
    }
    setChoices(newChoices);
  }

  function handleFeedbacksToggle() {
    setAddFeedbacks(!addFeedbacks);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('choices')}</p>
      <div className={cx('field')}>
        <InputError name="choices" />
        {[...choices, ''].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <div className={cx('list-element', 'choices')}>
              {index === choices.length ? (
                <Button
                  className={cx('choice-add')}
                  icon="plus"
                  intent="outline"
                  onClick={handleChoiceAdd}
                  size="small"
                  tooltip={t('choice-add')}
                />
              ) : (
                <CheckBoxTrueFalse
                  className={cx('checkboxes')}
                  name={`choices.${index}.answer`}
                />
              )}
              {index === choices.length ? (
                <InputRich
                  className={cx('choice-complete')}
                  name="tmp"
                  onChange={handleChoiceComplete}
                  placeholder={t(
                    'add-choice-placeholder',
                  )}
                />
              ) : (
                <InputRich
                  className={cx('fill')}
                  name={`choices.${index}.title`}
                  placeholder={t(
                    `choice${
                      index + 1
                    }-placeholder`,
                  )}
                  shouldHideError
                />
              )}
              {index !== choices.length && (
                <Button
                  icon="trash-alt"
                  intent="outline"
                  onClick={() => handleChoiceRemove(index)}
                  tooltip={t('delete-choice')}
                  variant="regular"
                />
              )}
            </div>
            {addFeedbacks && (
              <InputRich
                className={cx('choice-feedback')}
                name={`choices.${index}.feedback`}
                placeholder={t('choice-feedback-label')}
              />
            )}
          </div>
        ))}
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('randomize-label')}
          name="randomize"
        />
        <Switch
          className={cx('field')}
          label={t('feedbacks')}
          name="feedbacks"
          onClick={handleFeedbacksToggle}
        />
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/TrueOrFalse/Editor/title-error-required')),
    choices: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          title: yup.string().trim().required(),
          answer: yup.boolean().default(false),
          feedback: yup.string().trim(),
        }),
      )
      .compact()
      .transform((choices) =>
        choices
          .filter((choice) => !!choice.title)
          .map((choice, index) => ({
            ...choice,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/TrueOrFalse/Editor/choices-error-length'),
        (choices) => choices.length >= 1,
      ),
    feedback: yup.string().trim(),
    randomize: yup.boolean().default(true),
  });

export default Editor;
