import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/ModalLegacy';

import styles from './CourseDelete.module.scss';

const cx = classNames.bind(styles);

const CourseDelete = ({
  className,
  handleCourseDelete,
  handleModalClose,
  handleModalOpen,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <>
    <MenuItem
      className={className}
      icon="trash-alt"
      onClick={handleModalOpen}
      variant="regular"
    >
      {t('Course/Teacher/Cover/CourseDelete/delete-course')}
    </MenuItem>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Course/Teacher/Cover/CourseDelete/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>
          {t('Course/Teacher/Cover/CourseDelete/title')}
        </h1>
        <p className={cx('subtitle')}>
          {t('Course/Teacher/Cover/CourseDelete/subtitle')}
        </p>
        {status && (
          <p className={cx('error')} role="alert">
            {t('Course/Teacher/Cover/CourseDelete/course-delete-error')}
          </p>
        )}
        <Button
          className={cx('button')}
          intent="danger"
          isLoading={isLoading}
          onClick={handleCourseDelete}
          size="large"
        >
          {t('Course/Teacher/Cover/CourseDelete/confirm-delete-course')}
        </Button>
      </div>
    </Modal>
  </>
);

CourseDelete.propTypes = {
  className: PropTypes.string,
  handleCourseDelete: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CourseDelete.defaultProps = {
  className: undefined,
  status: undefined,
};

export default CourseDelete;
