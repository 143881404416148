import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/SCT',
  });

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      <div className={cx('subtitle')}>
        <p className={cx('header')}>{t('hypotheses')}</p>
        <FormattedText>{question.content.subtitle}</FormattedText>
      </div>
      <div className={cx('subtitle')}>
        <p className={cx('header')}>
          {t('additional-information')}
        </p>
        <FormattedText>{question.content.additionalInformation}</FormattedText>
      </div>
      {!isDisabled && (
        <div className={cx('instructions')}>
          {t('instructions')}
        </div>
      )}
      <div className={cx('experts')}>
        {[0, 1, 2, 3, 4].map((index) => (
          <button
            aria-pressed={answer === index}
            data-testid={`SCT.${index}.answer`}
            className={cx('expert', {
              isActive: answer === index,
            })}
            disabled={isDisabled}
            key={index}
            onClick={() => setAnswer(index)}
            type="button"
          >
            {question.content.labels && question.content.labels[index]
              ? question.content.labels[index]
              : t(`experts-${index}-label`)}
          </button>
        ))}
      </div>
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => undefined;

Recto.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .number()
      .required(t('Questions/SCT/Recto/answer-error'))
      .min(0, t('Questions/SCT/Recto/answer-error'))
      .max(4, t('Questions/SCT/Recto/answer-error')),
  });

export default Recto;
