import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Editor',
  });

  return (
    <div className={cx('container', className)}>
      <label className={cx('label')} htmlFor="FillInTheBlanks_Text">
        {t('text-label')}
      </label>
      <FormattedText className={cx('description')}>
        {t('text-description')}
      </FormattedText>
      <InputRich
        className={cx('field', 'fitb-text')}
        id="FillInTheBlanks_Text"
        isFillInTheBlanks
        name="text"
        placeholder={t('text-placeholder')}
      />
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('ignore-case-label')}
          name="ignoreCase"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-accents-label')}
          name="ignoreAccents"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-punctuation-label')}
          name="ignorePunctuation"
        />
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform(
        (v) => v || t('Questions/FillInTheBlanks/Editor/default-title'),
      ),
    text: yup
      .string()
      .trim()
      .required(t('Questions/FillInTheBlanks/Editor/text-error-required'))
      .test(
        'blanks',
        t('Questions/FillInTheBlanks/Editor/blanks-error-required'),
        (text) =>
          Array.from(
            text.matchAll(/\[{2}.*?\]*\]{2}|\{{2}.*?\}*\}{2}/g),
            () => 'blank',
          ).length > 0,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default Editor;
