import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import AudioPicker from 'uikit/AudioPicker';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import VideoPicker from 'uikit/VideoPicker';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Interview/Recto',
  });

  const { theme } = useContext(Context);

  const [isRecording, setIsRecording] = useState(false);

  function handleMessageDisplay() {
    setIsRecording(true);
  }

  function handleMessageHide() {
    setIsRecording(false);
  }

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      {isRecording && (
        <div className={cx('message', { active: isRecording })}>
          <Icon className={cx('message-icon')} name="video" />
          <p>{t('recording-message')}</p>
        </div>
      )}
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      {question.content.answerType === 'video' ? (
        <VideoPicker
          disableLink
          name="answer"
          onRecordStart={handleMessageDisplay}
          onRecordStop={handleMessageHide}
        />
      ) : (
        <AudioPicker
          disableLink
          name="answer"
          onRecordStart={handleMessageDisplay}
          onRecordStop={handleMessageHide}
        />
      )}
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => '';

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup.string().trim().required().url(),
  });

export default Recto;
