import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';

import styles from './ActionsMobile.module.scss';

const cx = classNames.bind(styles);

const ActionsMobile = ({
  handleChapterCreate,
  handleFileImport,
  handleQuestionCreate,
  handleQuestionCreateFromIA,
  handleQuestionImport,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Actions',
  });

  const { wantsAI } = useSiteContext();

  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setIsMenuDisplayed(false);
    } else {
      setTimeout(() => setIsMenuDisplayed(true), 50);
    }
  }, [isModalOpen]);

  async function handleMobileMenuToggle(cb) {
    await cb();
    handleModalClose();
  }

  return (
    <div className={cx('mobile-screen')}>
      <Button
        aria-label={t('new')}
        data-testid="create-button-small"
        icon="plus"
        id="create-button-small"
        intent="primary"
        onClick={handleModalOpen}
      />
      <Modal
        closeTimeoutMS={200}
        isOpen={isModalOpen}
        onExit={handleModalClose}
        shouldCloseOnOverlayClick={true}
      >
        <div
          className={cx('modal', {
            isVisible: isMenuDisplayed,
          })}
        >
          <Button
            data-testid="create-question-button-small"
            intent="primary"
            onClick={() =>
              handleMobileMenuToggle(() => handleQuestionCreate(null))
            }
          >
            {t('create-question')}
          </Button>
          {wantsAI && (
            <Button
              className={cx('mobile-action')}
              icon="wand-sparkles"
              intent="ia-light"
              onClick={() => handleMobileMenuToggle(handleQuestionCreateFromIA)}
            >
              {t('create-from-ia')}
            </Button>
          )}
          <Button onClick={() => handleMobileMenuToggle(handleChapterCreate)}>
            {t('create-chapter')}
          </Button>
          <Button onClick={() => handleMobileMenuToggle(handleQuestionImport)}>
            {t('import-question')}
          </Button>
          <Button onClick={() => handleMobileMenuToggle(handleFileImport)}>
            {t('upload-file')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ActionsMobile.propTypes = {
  handleChapterCreate: PropTypes.func.isRequired,
  handleFileImport: PropTypes.func.isRequired,
  handleQuestionCreate: PropTypes.func.isRequired,
  handleQuestionCreateFromIA: PropTypes.func.isRequired,
  handleQuestionImport: PropTypes.func.isRequired,
};

export default ActionsMobile;
