import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { theme } = useContext(Context);

  const destinations =
    question.content.categories.destinations ||
    question.content.categories.flatMap((category) => category.destinations);

  const sources =
    question.content.categories.sources ||
    question.content.categories.map((category) => ({
      idx: category.idx,
      title: category.source,
    }));

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      {sources.map((source, sourceIndex) => {
        const solution = question.solution[sourceIndex];
        const user = destinations.filter((destination) =>
          solution?.user.includes(destination.idx),
        );
        const correct = destinations.filter((destination) =>
          solution?.correct.includes(destination.idx),
        );
        const correctDestinations = user.filter((destination) =>
          solution?.correct.includes(destination.idx),
        );
        const wrongDestinations = user.filter(
          (destination) => !solution?.correct.includes(destination.idx),
        );
        const missingDestinations = correct.filter(
          (destination) => !solution?.user.includes(destination.idx),
        );
        return (
          <div className={cx('category')} key={source.idx}>
            <FormattedText className={cx('category-title')}>
              {source.title}
            </FormattedText>
            <div className={cx('answers')}>
              {correctDestinations.length > 0 && (
                <>
                  {correctDestinations.map((destination) => (
                    <div className={cx('answer', 'correct')}>
                      <Icon className={cx('icon')} name="check" />
                      <FormattedText className={cx('text')}>
                        {destination.title || '___'}
                      </FormattedText>
                    </div>
                  ))}
                </>
              )}
              {wrongDestinations.length > 0 && (
                <>
                  {wrongDestinations.map((destination) => (
                    <div className={cx('answer', 'wrong')}>
                      <Icon className={cx('icon')} name="times" />
                      <FormattedText className={cx('text')}>
                        {destination.title || '___'}
                      </FormattedText>
                    </div>
                  ))}
                </>
              )}
              {missingDestinations.length > 0 && (
                <>
                  {missingDestinations.map((destination) => (
                    <div className={cx('answer', 'wrong')}>
                      <Icon className={cx('icon')} name="empty-set" />
                      <FormattedText className={cx('text')}>
                        {destination.title || '___'}
                      </FormattedText>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        );
      })}
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  destinations: PropTypes.arrayOf(PropTypes.object).isRequired,
  question: PropTypes.object.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultAnswer = (question) => question.content.categories.map(() => []);

Verso.defaultSolution = (question) =>
  question.content.categories.map((category) => ({
    idx: category.idx,
    user: [],
    correct: category.destinations.map((destination) => destination.idx),
    isCorrect: false,
  }));

export default Verso;
