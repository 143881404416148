import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';

import ImageContainer from './ImageContainer/ImageContainer';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FindOnImage/Recto',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <ImageContainer
        className={cx('image')}
        imageSrc={question.content.image}
      />
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => ({
  x: -1,
  y: -1,
});

Recto.validationSchema = (t) =>
  yup.object().shape({
    answer: yup.object().shape({
      x: yup
        .number()
        .required(t('Questions/FindOnImage/Recto/answer-error'))
        .min(0, t('Questions/FindOnImage/Recto/answer-error')),
      y: yup
        .number()
        .required(t('Questions/FindOnImage/Recto/answer-error'))
        .min(0, t('Questions/FindOnImage/Recto/answer-error')),
    }),
  });

export default Recto;
