import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import BACKGROUNDS from 'constants/backgrounds';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, question }) => (
  <div className={cx('container', className)}>
    <div
      className={cx('background', `background-${question.content.background}`)}
      style={{
        backgroundImage: !BACKGROUNDS.includes(question.content.background)
          ? `url(${question.content.background})`
          : '',
      }}
    >
      <FormattedText
        as="h1"
        className={cx(`text-color-${question.content.textColor}`, {
          'basic-styling': !question.title.includes('style='),
        })}
      >
        {question.title}
      </FormattedText>
    </div>
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => undefined;

Recto.validationSchema = () => undefined;

export default Recto;
