import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputImage from 'uikit/InputImage';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import ImageContainer from './ImageContainer/ImageContainer';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FindOnImage/Editor',
  });

  const [{ value: image }, , { setValue: setImage }] = useField('image');

  const [{ value: zones }, , { setValue: setZones }] = useField('zones');

  function handleImageDelete() {
    setImage(null);
  }

  function handleZoneAdd(type) {
    const newZone = {
      h: type === 'rectangle' ? 0.1 : null,
      r: type === 'circle' ? 0.05 : null,
      w: type === 'rectangle' ? 0.1 : null,
      x: type === 'rectangle' ? 0.01 : 0.06,
      y: type === 'rectangle' ? 0.01 : 0.06,
      shapeType: type,
    };
    setZones([...zones, newZone]);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('image')}</p>
      {image ? (
        <>
          <InputError name="zones" />
          <div className={cx('toolbar')}>
            <Button
              className={cx('action')}
              icon={<Icon name="rectangle-landscape" variant="light" />}
              onClick={() => handleZoneAdd('rectangle')}
            >
              {t('new-rectangle')}
            </Button>
            <Button
              className={cx('action')}
              icon={<Icon name="circle" variant="light" />}
              onClick={() => handleZoneAdd('circle')}
            >
              {t('new-circle')}
            </Button>
            <Button
              className={cx('action')}
              icon="trash-alt"
              onClick={handleImageDelete}
              tooltip={t('delete-image')}
              variant="regular"
            />
          </div>
          <ImageContainer
            className={cx('field', 'image-container')}
            imageSrc={image}
          />
        </>
      ) : (
        <InputImage className={cx('field', 'image')} name="image" />
      )}
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/FindOnImage/Editor/title-error-required')),
    image: yup
      .string()
      .trim()
      .required(t('Questions/FindOnImage/Editor/image-error-required'))
      .url(t('Questions/FindOnImage/Editor/image-error-required')),
    zones: yup
      .array()
      .of(
        yup.object().shape({
          shapeType: yup
            .string()
            .trim()
            .required()
            .oneOf(['rectangle', 'circle']),
          x: yup.number().required(),
          y: yup.number().required(),
          w: yup.number().nullable(),
          h: yup.number().nullable(),
          r: yup.number().nullable(),
        }),
      )
      .test(
        'zones',
        t('Questions/FindOnImage/Editor/zones-error-required'),
        (zones) => zones.length >= 1,
      ),
    feedback: yup.string().trim(),
  });

export default Editor;
