import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useQueryLocalStudy } from 'gql/queries/local';
import {
  mutationStudyTogglePartialForceCorrect,
  useMutationStudyQuestionPartialForceCorrect,
} from 'gql/mutations/study';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal/Modal';

import Answer from '../Answer/Answer';
import styles from './PartialForceCorrect.module.scss';

const cx = classNames.bind(styles);

const PartialForceCorrect = ({ question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/LabelOnImage/Verso/PartialForceCorrect',
  });

  const mutationStudyQuestionPartialForceCorrect =
    useMutationStudyQuestionPartialForceCorrect();

  const {
    data: {
      study: { isPartialForceCorrect },
    },
  } = useQueryLocalStudy();

  const answers = question.solution.filter((item) => !item.isCorrect);

  const validationSchema = yup.object().shape({
    forcedAnswers: yup
      .array()
      .of(
        yup.object().shape({
          correct: yup.array(),
          exact: yup.string().nullable(),
          isCorrect: yup.boolean(),
          user: yup.string().nullable(),
        }),
      )
      .required()
      .test(
        'length',
        t('error-no-answer'),
        (forcedAnswers) => forcedAnswers.length >= 1,
      ),
  });

  function handleAnswerSelect({ answer, values, setFieldValue, isSelected }) {
    if (isSelected) {
      const newAnswers = values.forcedAnswers.filter(
        (forcedAnswer) =>
          JSON.stringify(forcedAnswer) !== JSON.stringify(answer),
      );
      setFieldValue('forcedAnswers', newAnswers);
    } else {
      setFieldValue('forcedAnswers', [...values.forcedAnswers, answer]);
    }
  }

  async function handlePartialForceCorrect(values) {
    try {
      const { forcedAnswers } = extract(values, validationSchema);
      await mutationStudyQuestionPartialForceCorrect(forcedAnswers);
      mutationStudyTogglePartialForceCorrect();
      trackEvent('study', 'answer-partial-force-correct');
    } catch (err) {
      reportError('study', 'answer-partial-force-correct', err);
    }
  }

  return (
    <Modal
      isOpen={isPartialForceCorrect}
      onExit={mutationStudyTogglePartialForceCorrect}
    >
      <div className={cx('modal')}>
        <Button
          aria-label={t('aria-close')}
          className={cx('close')}
          icon="times"
          onClick={mutationStudyTogglePartialForceCorrect}
        />
        <h1 className={cx('title')}>{t('title')}</h1>
        <Formik
          initialValues={{
            forcedAnswers: [],
          }}
          onSubmit={handlePartialForceCorrect}
          validationSchema={validationSchema}
        >
          {({ errors, isSubmitting, status, setFieldValue, values }) => (
            <Form className={cx('answers')}>
              {answers.map((answer, index) => {
                const isSelected = values.forcedAnswers.find(
                  (forcedAnswer) =>
                    JSON.stringify(forcedAnswer) === JSON.stringify(answer),
                );
                return (
                  <div className={cx('answer')}>
                    <label htmlFor={`${answer.user}-${index}`}>
                      <input
                        aria-label={sanitize.string(answer.user)}
                        checked={isSelected}
                        className={cx('check')}
                        id={`${answer.user}-${index}`}
                        type="checkbox"
                        onChange={() =>
                          handleAnswerSelect({
                            answer,
                            values,
                            setFieldValue,
                            isSelected,
                          })
                        }
                      />
                      <span
                        className={cx('checkbox', {
                          isChecked: isSelected,
                        })}
                      >
                        <Icon name="check" />
                      </span>
                    </label>
                    <Answer
                      answer={answer}
                      index={index}
                      isForcingCorrect={true}
                      isSelected={isSelected}
                    />
                  </div>
                );
              })}
              {status && (
                <p className={cx('error')} role="alert">
                  {t('error')}
                </p>
              )}
              {errors && (
                <p className={cx('error')} role="alert">
                  {errors.forcedAnswers}
                </p>
              )}
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('confirm')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

PartialForceCorrect.propTypes = {
  question: PropTypes.object.isRequired,
};

export default PartialForceCorrect;
