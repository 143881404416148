import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';
import VideoPreview from 'uikit/VideoPicker/VideoPreview';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Video/Recto',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <VideoPreview video={question.content.video} />
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => undefined;

Recto.validationSchema = () => undefined;

export default Recto;
