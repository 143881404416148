import PropTypes from 'prop-types';
import React from 'react';

import Modal from './Modal';

const ModalContainer = ({ isOpen, ...props }) => {
  return <Modal {...props} isOpen={isOpen} />;
};

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ModalContainer;
