import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import InputNumber from 'uikit/InputNumber';
import InputRich from 'uikit/InputRich';
import InputTolerance from 'uikit/InputTolerance';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/GuessNumber/Editor',
  });

  const [{ value: answer }] = useField('answer');
  const [{ value: toleranceValue }] = useField('toleranceValue');
  const [{ value: toleranceType }] = useField('toleranceType');

  function getMaxCorrect() {
    if (toleranceType === 'ABSOLUTE' && (answer || answer === 0)) {
      const max = answer + toleranceValue;
      return Number(max.toFixed(3));
    }
    if (toleranceType === 'RELATIVE' && answer && answer !== 0) {
      const max = answer + (answer * toleranceValue) / 100;
      return Number(max.toFixed(3));
    }
    return null;
  }

  function getMinCorrect() {
    if (toleranceType === 'ABSOLUTE' && (answer || answer === 0)) {
      const min = answer - toleranceValue;
      return Number(min.toFixed(3));
    }
    if (toleranceType === 'RELATIVE' && answer && answer !== 0) {
      const min = answer - (answer * toleranceValue) / 100;
      return Number(min.toFixed(3));
    }
    return null;
  }

  return (
    <div className={cx('container', className)}>
      <div className={cx('numbers')}>
        <InputNumber
          className={cx('field')}
          data-testid="answer"
          label={t('answer-label')}
          name="answer"
        />
        <InputTolerance
          className={cx('field')}
          label={t('tolerance-label')}
          name="toleranceValue"
        />
      </div>
      {(getMaxCorrect() || getMinCorrect()) && (
        <p className={cx('correct-range')}>
          {t('correct-range', {
            max: getMaxCorrect(),
            min: getMinCorrect(),
          })}
        </p>
      )}
      <InputRich label={t('feedback-label')} name="feedback" />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/GuessNumber/Editor/title-error-required')),
    answer: yup
      .number()
      .required(t('Questions/GuessNumber/Editor/answer-error-required')),
    toleranceValue: yup.number().nullable().min(0),
    toleranceType: yup
      .string()
      .oneOf(['NONE', 'ABSOLUTE', 'RELATIVE'])
      .required(),
    feedback: yup.string().trim(),
  });

export default Editor;
