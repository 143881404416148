import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Flashcard/Recto',
  });

  const { theme } = useContext(Context);

  function handleSpeech() {
    const msg = new SpeechSynthesisUtterance();
    const { speechSynthesis } = window;
    msg.text = sanitize.string(question.title);
    msg.lang = question.content.titleLang || 'fr';
    msg.voice = speechSynthesis
      .getVoices()
      .find((voice) => voice.lang.includes(msg.lang.slice(0, 2)));
    window.speechSynthesis.speak(msg);
  }

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <div className={cx('front-container')}>
        <div className={cx('actions-container')}>
          {'speechSynthesis' in window &&
            (question.content.useSpeech !== undefined
              ? question.content.useSpeech
              : true) && (
              <Button
                aria-label={t('speech')}
                className={cx('speech')}
                icon="volume"
                onClick={() => handleSpeech('feedback')}
              />
            )}
        </div>
        <div
          className={cx('front-content', {
            isBigContent: question.content.inverted
              ? question.content.feedback.length < 150
              : question.title.length < 150,
          })}
        >
          <FormattedText
            as="h1"
            className={cx('front-text', {
              isBigText: question.content.inverted
                ? question.content.feedback.length < 150
                : question.title.length < 150,
            })}
          >
            {question.content.inverted
              ? question.content.feedback
              : question.title}
          </FormattedText>
        </div>
      </div>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => undefined;

Recto.validationSchema = () => undefined;

export default Recto;
