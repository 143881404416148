import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import FeedbackWithIA from 'questions/Shared/FeedbackWithIA/FeedbackWithIA';
import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';
import * as yup from 'yup';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/MCQ/Editor',
  });

  const { data: meData } = useQueryMe();

  const { wantsAI } = useSiteContext();

  const [{ value: choices }, , { setValue: setChoices }] = useField('choices');
  const [{ value: addFeedbacks }, , { setValue: setAddFeedbacks }] =
    useField('feedbacks');

  useEffect(() => {
    const hasFeedback = choices.find((choice) => !!choice.feedback);
    if (hasFeedback) {
      setAddFeedbacks(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChoiceAdd() {
    setChoices([
      ...choices,
      {
        idx: choices.length,
        title: '',
        answer: false,
        feedback: '',
      },
    ]);
  }

  function handleChoiceComplete(model) {
    if (model) {
      setChoices([
        ...choices,
        {
          idx: choices.length,
          title: model,
          answer: false,
          feedback: '',
        },
      ]);
    }
  }

  function handleChoiceRemove(index) {
    const newChoices = choices.filter((a, i) => i !== index);
    if (newChoices.length < 1) {
      newChoices.push({
        idx: newChoices.length,
        title: '',
        answer: false,
      });
    }
    setChoices(newChoices);
  }

  function handleFeedbacksToggle() {
    setAddFeedbacks(!addFeedbacks);
    if (addFeedbacks) {
      setChoices(choices.map((choice) => ({ ...choice, feedback: '' })));
    }
  }

  const validationSchema = Editor.validationSchema(t);

  const hasAIFeedbackAccess =
    meData?.me.flags?.includes('ai-feedback') && wantsAI;

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('choices')}</p>
      <div className={cx('field')}>
        <InputError name="choices" />
        {[...choices, ''].map((choice, index) => (
          <div key={choice.idx}>
            <div className={cx('list-element')}>
              {index === choices.length ? (
                <Button
                  className={cx('choice-add')}
                  icon="plus"
                  intent="outline"
                  onClick={handleChoiceAdd}
                  size="small"
                  tooltip={t('choice-add')}
                />
              ) : (
                <CheckBox
                  data-testid={`choices.${index}.answer`}
                  name={`choices.${index}.answer`}
                />
              )}
              {index === choices.length ? (
                <InputRich
                  className={cx('choice-complete')}
                  name="tmp"
                  onChange={handleChoiceComplete}
                />
              ) : (
                <InputRich
                  className={cx('fill')}
                  data-testid={`choices.${index}.title`}
                  name={`choices.${index}.title`}
                  placeholder={t(`choice${index + 1}-placeholder`)}
                  shouldHideError
                />
              )}
              {index !== choices.length && (
                <Button
                  icon="trash-alt"
                  intent="outline"
                  onClick={() => handleChoiceRemove(index)}
                  tooltip={t('delete-choice')}
                />
              )}
            </div>
            {addFeedbacks && (
              <InputRich
                className={cx('choice-feedback')}
                name={`choices.${index}.feedback`}
                placeholder={t('choice-feedback-label')}
              />
            )}
          </div>
        ))}
      </div>
      {hasAIFeedbackAccess ? (
        <FeedbackWithIA type="MCQ" validationSchema={validationSchema} />
      ) : (
        <InputRich
          helper={
            <Tooltip tooltip={t('feedback-helper')}>
              <Icon name="info-circle" />
            </Tooltip>
          }
          label={t('feedback-label')}
          name="feedback"
        />
      )}
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('randomize-label')}
          name="randomize"
        />
        <Switch
          className={cx('field')}
          label={t('multiple-choices-label')}
          name="multipleChoices"
        />
        <Switch
          className={cx('field')}
          label={t('feedbacks')}
          name="feedbacks"
          onClick={handleFeedbacksToggle}
        />
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/MCQ/Editor/title-error-required')),
    choices: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          title: yup.string().trim().required(),
          answer: yup.boolean().default(false),
          feedback: yup.string().trim(),
        }),
      )
      .compact()
      .transform((choices) =>
        choices
          .filter((choice) => !!choice.title)
          .map((choice, index) => ({
            ...choice,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/MCQ/Editor/choices-error-length'),
        (choices) => choices.length >= 2,
      )
      .test(
        'answer',
        t('Questions/MCQ/Editor/choices-error-answer'),
        (choices) => choices.filter((choice) => !!choice.answer).length >= 1,
      )
      .test(
        'multiple answer',
        t('Questions/MCQ/Editor/multiple-choices-error-length'),
        (choices, context) =>
          context.parent.multipleChoices ||
          choices.filter((choice) => !!choice.answer).length === 1,
      ),
    feedback: yup.string().trim(),
    randomize: yup.boolean().default(true),
    multipleChoices: yup.boolean().default(false),
  });

export default Editor;
