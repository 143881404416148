import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Categorization/Editor',
  });

  const [{ value: categories }, , { setValue: setCategories }] =
    useField('categories');

  function handleCategoryAdd() {
    setCategories([
      ...categories,
      {
        idx: categories.length,
        source: '',
        destinations: [{ idx: 0, title: '' }],
      },
    ]);
  }

  function handleCategoryRemove(index) {
    const newCategories = categories.filter((category, i) => i !== index);
    if (newCategories.length < 1) {
      newCategories.push({
        idx: newCategories.length,
        source: '',
        destinations: [{ idx: 0, title: '' }],
      });
    }
    setCategories(newCategories);
  }

  function handleDestinationAdd(categoryIndex, model) {
    const newCategories = categories.map((category, index) =>
      categoryIndex === index
        ? {
            ...category,
            destinations: [
              ...category.destinations,
              {
                idx: category.destinations.length,
                title: model || '',
              },
            ],
          }
        : category,
    );
    setCategories(newCategories);
  }

  function handleDestinationRemove(categoryIndex, destinationIndex) {
    const newCategories = categories.map((category, index) =>
      categoryIndex === index
        ? {
            ...category,
            destinations: category.destinations.filter(
              (a, i) => i !== destinationIndex,
            ),
          }
        : category,
    );
    if (newCategories[categoryIndex].destinations.length < 1) {
      newCategories[categoryIndex].destinations.push({
        idx: 0,
        title: '',
      });
    }
    setCategories(newCategories);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('categories')}</p>
      <div className={cx('field')}>
        <InputError name="categories" />
        {categories.map((category, categoryIndex) => (
          <div className={cx('category-element')} key={category.idx}>
            <div className={cx('list-element')}>
              <InputRich
                name={`categories.${categoryIndex}.source`}
                placeholder={
                  categoryIndex < 2 &&
                  t(`category${categoryIndex + 1}-placeholder`)
                }
              />
              <Button
                icon="trash-alt"
                onClick={() => handleCategoryRemove(categoryIndex)}
                tooltip={t('delete-category')}
                variant="regular"
              />
            </div>
            <div className={cx('line')} />
            {[...category.destinations, ''].map(
              (destination, destinationIndex) => (
                <div className={cx('list-element')} key={destination.idx}>
                  {destinationIndex === category.destinations.length ? (
                    <Button
                      className={cx('destination-add')}
                      icon="plus"
                      intent="outline"
                      onClick={() => handleDestinationAdd(categoryIndex)}
                      size="small"
                      tooltip={t('destination-add')}
                    />
                  ) : (
                    <Icon
                      className={cx('arrow')}
                      name="arrow-turn-down-right"
                    />
                  )}
                  {destinationIndex === category.destinations.length ? (
                    <InputRich
                      className={cx('destination-complete')}
                      name="tmp"
                      onChange={(model) =>
                        handleDestinationAdd(categoryIndex, model)
                      }
                    />
                  ) : (
                    <InputRich
                      className={cx('fill')}
                      name={`categories.${categoryIndex}.destinations.${destinationIndex}.title`}
                      placeholder={
                        categoryIndex < 2 &&
                        t(
                          `category${categoryIndex + 1}-destination${
                            destinationIndex + 1
                          }-placeholder`,
                        )
                      }
                    />
                  )}
                  {destinationIndex !== category.destinations.length && (
                    <Button
                      icon="trash-alt"
                      onClick={() =>
                        handleDestinationRemove(categoryIndex, destinationIndex)
                      }
                      tooltip={t('delete-destination')}
                      variant="regular"
                    />
                  )}
                </div>
              ),
            )}
          </div>
        ))}
        <Button
          className={cx('category-add')}
          icon="plus"
          intent="outline"
          onClick={handleCategoryAdd}
        >
          {t('category-add')}
        </Button>
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform(
        (v) => v || t('Questions/Categorization/Editor/default-title'),
      ),
    categories: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          source: yup
            .string()
            .trim()
            .required(
              t('Questions/Categorization/Editor/categories-error-required'),
            ),
          destinations: yup
            .array()
            .of(
              yup.object().shape({
                idx: yup.number().required().integer().min(0),
                title: yup.string().trim().required(),
              }),
            )
            .test(
              'destinations length',
              t('Questions/Categorization/Editor/destinations-error-size'),
              (destinations) => destinations.length >= 1,
            ),
        }),
      )
      .compact()
      .transform((categories) =>
        categories
          .filter(
            (category) =>
              !!category.source &&
              category.destinations.length >= 1 &&
              category.destinations.every((destination) => !!destination.title),
          )
          .map((category, categoryIndex) => ({
            ...category,
            idx: categoryIndex,
            destinations: category.destinations.map(
              (destination, destinationIndex) => ({
                ...destination,
                idx:
                  categoryIndex === 0
                    ? destinationIndex
                    : categoryIndex * 100 + destinationIndex,
              }),
            ),
          })),
      )
      .test(
        'length',
        t('Questions/Categorization/Editor/categories-error-size'),
        (categories) => categories.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default Editor;
