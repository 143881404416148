import React from 'react';
import { useTranslation } from 'react-i18next';

import InputTextArray from 'uikit/InputTextArray';

const Tags = (props) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/Tags',
  });

  return (
    <InputTextArray
      label={t('tags-label')}
      name="tags"
      options={[]}
      {...props}
    />
  );
};

export default Tags;
