import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Sorting/Editor',
  });

  const dndRef = useRef(null);

  const [{ value: items }, , { setValue: setItems }] = useField('items');

  function handleItemAdd() {
    setItems([...items, '']);
  }

  function handleItemComplete(model) {
    if (model) {
      setItems([...items, model]);
    }
  }

  function handleItemMove({ source, destination }) {
    if (destination) {
      const newItems = [...items];
      newItems.splice(
        destination.index,
        0,
        newItems.splice(source.index, 1)[0],
      );
      setItems(newItems);
    }
  }

  function handleItemRemove(index) {
    const newItems = items.filter((a, i) => i !== index);
    if (newItems.length < 1) {
      newItems.push('');
    }
    setItems(newItems);
  }

  async function handleMoveDown(index) {
    if (dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveDown();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }

  async function handleMoveUp(index) {
    if (dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveUp();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('items')}</p>
      <div className={cx('field')}>
        <DragDropContext
          onDragEnd={handleItemMove}
          sensors={[
            (api) => {
              // eslint-disable-next-line no-param-reassign
              dndRef.current = api;
            },
          ]}
        >
          <InputError name="items" />
          <Droppable droppableId="items">
            {(providedItems, snapshotItems) => (
              <div
                className={cx('zone', {
                  isDraggingOver: snapshotItems.isDraggingOver,
                })}
                ref={providedItems.innerRef}
                {...providedItems.droppableProps}
              >
                {[...items, ''].map((_, index) => (
                  <Draggable
                    draggableId={index.toString()}
                    index={index}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {(providedItem, snapshotItem) => (
                      <div
                        className={cx('list-element', {
                          isDragging: snapshotItem.isDragging,
                        })}
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                      >
                        {index === items.length ? (
                          <Button
                            className={cx('item-add')}
                            icon="plus"
                            intent="outline"
                            onClick={handleItemAdd}
                            size="small"
                            tooltip={t('item-add')}
                          />
                        ) : (
                          <div
                            aria-label={t('aria-move')}
                            className={cx('drag')}
                            role="button"
                            {...providedItem.dragHandleProps}
                          >
                            <Icon name="bars" />
                          </div>
                        )}
                        {index === items.length ? (
                          <InputRich
                            className={cx('item-complete')}
                            name="tmp"
                            onChange={handleItemComplete}
                          />
                        ) : (
                          <InputRich
                            className={cx('fill')}
                            name={`items.${index}.title`}
                            placeholder={t(`item${index + 1}-placeholder`)}
                            shouldHideError
                          />
                        )}
                        {index !== items.length && (
                          <>
                            <Button
                              className={cx('hidden')}
                              icon="chevron-down"
                              isDisabled={index === items.length - 1}
                              onClick={() => handleMoveDown(index)}
                              title={t('move-down')}
                              tabIndex={-1}
                            />
                            <Button
                              className={cx('hidden')}
                              icon="chevron-up"
                              isDisabled={index === 0}
                              onClick={() => handleMoveUp(index)}
                              title={t('move-up')}
                              tabIndex={-1}
                            />
                            <Button
                              icon="trash-alt"
                              intent="outline"
                              onClick={() => handleItemRemove(index)}
                              tooltip={t('delete-item')}
                              variant="regular"
                            />
                          </>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedItems.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Sorting/Editor/title-error-required')),
    items: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          title: yup.string().trim().required(),
        }),
      )
      .compact()
      .transform((items) =>
        items
          .filter((item) => !!item.title)
          .map((item, index) => ({
            ...item,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/Sorting/Editor/items-error-length'),
        (items) => items.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default Editor;
