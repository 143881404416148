import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, onQuestionReveal, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Flashcard/Verso',
  });

  function handleSpeech(content) {
    const msg = new SpeechSynthesisUtterance();
    const { speechSynthesis } = window;
    msg.text = sanitize.string(
      content === 'title' ? question.title : question.content.feedback,
    );
    msg.lang =
      content === 'title'
        ? question.content.titleLang
        : question.content.feedbackLang;
    msg.voice = speechSynthesis
      .getVoices()
      .find((voice) => voice.lang.includes(msg.lang.slice(0, 2)));
    window.speechSynthesis.speak(msg);
    window.speechSynthesis.speak(msg);
  }

  return (
    <div className={cx('container', className)}>
      <div className={cx('front-container')}>
        <div className={cx('actions-container')}>
          <Button
            aria-label={t('see-recto')}
            className={cx('un-reveal')}
            icon="rotate-right"
            onClick={onQuestionReveal}
          />
          {'speechSynthesis' in window &&
            (question.content.useSpeech !== undefined
              ? question.content.useSpeech
              : true) && (
              <Button
                aria-label={t('speech')}
                className={cx('speech')}
                icon="volume"
                onClick={() => handleSpeech('feedback')}
              />
            )}
        </div>
        <div
          className={cx('front-content', {
            isBigContent: question.content.inverted
              ? question.title.length < 150
              : question.content.feedback.length < 150,
          })}
        >
          <FormattedText
            as="h1"
            className={cx('front-text', {
              isBigText: question.content.inverted
                ? question.title.length < 150
                : question.content.feedback.length < 150,
            })}
          >
            {question.content.inverted
              ? question.title
              : question.content.feedback}
          </FormattedText>
        </div>
      </div>
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  onQuestionReveal: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => undefined;

Verso.defaultSolution = () => null;

export default Verso;
