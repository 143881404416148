import classNames from 'classnames/bind';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';
import Modal from 'uikit/ModalLegacy';

import styles from './QuestionGrade.module.scss';

const cx = classNames.bind(styles);

const QuestionGrade = ({ isOpen, onModalClose, onSubmit }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionGrade',
  });

  const [{ value: grade }, , { setValue: setGrade }] = useField('grade');

  const { isSubmitting, setFieldValue } = useFormikContext();

  const handleEvaluation = (number) => {
    setGrade(number);
  };

  return (
    <Modal isOpen={isOpen} onExit={onModalClose}>
      <div className={cx('modal')}>
        <div className={cx('header')}>
          <h1 className={cx('title')}>{t('title')}</h1>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={onModalClose}
          />
        </div>
        <p className={cx('grade-label')}>{t('grade-label')}</p>
        <div className={cx('stars')}>
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              className={cx('star')}
              key={star}
              onClick={() => handleEvaluation(star)}
              type="button"
            >
              <Icon
                className={cx('icon')}
                name="star"
                variant={grade >= star ? 'solid' : 'regular'}
              />
            </button>
          ))}
        </div>
        <InputText
          className={cx('feedback')}
          label={t('feedback-label')}
          name="studentFeedback"
        />
        <CheckBox
          className={cx('checkbox')}
          label={t('notify-student-label')}
          name="notifyStudent"
        />
        <div className={cx('actions')}>
          <Button
            className={cx('action')}
            onClick={() => {
              setFieldValue('grade', null);
              setFieldValue('studentFeedback', '');
              setFieldValue('notifyStudent', false);
              onSubmit();
            }}
          >
            {t('close')}
          </Button>
          <Button
            className={cx('action')}
            intent="primary"
            isLoading={isSubmitting}
            onClick={() => onSubmit()}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

QuestionGrade.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default QuestionGrade;
