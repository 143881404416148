import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  getDenormalizedPinPosition,
  getDimensions,
  getEventCursorPosition,
  getNormalizedPinPosition,
} from 'questions/Shared/utils/images';

import Pin from './Pin/Pin';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({ className = null, imageSrc }) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  useEffect(() => {
    function handleScreenResize() {
      if (wrapperRef && imageRef) {
        handleImageLoaded();
      }
    }

    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [wrapperRef, imageRef]);

  function handleImageLoaded() {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }

  function handlePinAdd(event) {
    const [clientX, clientY] = getEventCursorPosition(event);
    const { left: offsetLeft, top: offsetTop } =
      wrapperRef.current.getBoundingClientRect();
    const normalizedPinPosition = getNormalizedPinPosition(
      {
        top: clientY - offsetTop,
        left: clientX - offsetLeft,
      },
      image.diagonal,
    );
    setAnswer({
      x: normalizedPinPosition.x,
      y: normalizedPinPosition.y,
    });
  }

  function handlePinDelete() {
    setAnswer({
      x: -1,
      y: -1,
    });
  }

  return (
    <div className={cx('container', className)} ref={wrapperRef}>
      <button className={cx('wrapper')} onClick={handlePinAdd} type="button">
        <img
          alt="img"
          className={cx('image')}
          draggable="false"
          onLoad={handleImageLoaded}
          ref={imageRef}
          src={imageSrc}
          style={{
            width: image.width,
            height: image.height,
          }}
        />
      </button>
      {image.diagonal && (
        <Pin
          handleDelete={handlePinDelete}
          sizeData={getDenormalizedPinPosition(answer, image.diagonal)}
        />
      )}
    </div>
  );
};

ImageContainer.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};

export default ImageContainer;
