import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';
import Modal from 'uikit/ModalLegacy';

import styles from './ImagePicker.module.scss';

const cx = classNames.bind(styles);

const ImagePicker = ({
  current,
  handleDragEnter,
  handleDragLeave,
  handleImport,
  handleImportedImageReset,
  handleLinkChange,
  handleLinkReset,
  handleModalCloseAndReset,
  isModalOpen,
  handleSelect,
  importedImage,
  importedPreview,
  isDroppable,
  t,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleModalCloseAndReset}>
    <Formik
      initialValues={{
        link: '',
      }}
      onSubmit={handleSelect}
    >
      {({ setFieldValue, values: { link }, isSubmitting }) => (
        <Form className={cx('container')}>
          <div className={cx('modal', 'large')}>
            <div className={cx('header')}>
              <h1 className={cx('title')}>{t('uikit/ImagePicker/title')}</h1>
              <Button
                aria-label={t('uikit/ImagePicker/aria-close')}
                icon="times"
                onClick={handleModalCloseAndReset}
              />
            </div>
            <div
              className={cx('dropzone', {
                isDroppable,
                transparent: !!link,
              })}
              onDragEnter={handleDragEnter}
              onDragEnd={handleDragLeave}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragEnter}
              onDrop={handleImport}
            >
              <label
                aria-label={t('uikit/ImagePicker/import-image')}
                htmlFor="file"
              >
                <input
                  accept="image/*"
                  id="file"
                  name="file"
                  onChange={(event) => handleImport(event, setFieldValue)}
                  type="file"
                />
                <div className={cx('input-content')}>
                  {(importedPreview && importedImage) || current ? (
                    <img
                      alt={t('uikit/ImagePicker/preview')}
                      aria-hidden="true"
                      className={cx('preview')}
                      src={importedPreview || current}
                    />
                  ) : (
                    <div className={cx('preview')}>
                      <div className={cx('plus-container')}>
                        <div className={cx('plus-icon')}>+</div>
                      </div>
                      <Icon className={cx('preview-icon')} name="image" />
                    </div>
                  )}
                  {importedPreview && importedImage ? (
                    <div className={cx('file-info')}>
                      <p className={cx('file-name')}>{`${importedImage.name} (${
                        Math.round((importedImage.size / 1e6) * 10) / 10
                      }MB)`}</p>
                      <Button
                        aria-label={t('uikit/ImagePicker/delete')}
                        className={cx('link-reset-button')}
                        icon="times"
                        onClick={handleImportedImageReset}
                        size="small"
                      />
                    </div>
                  ) : (
                    <FormattedText className={cx('preview-text')}>
                      {t('uikit/ImagePicker/import-image')}
                    </FormattedText>
                  )}
                </div>
              </label>
            </div>
            <p className={cx('or')}>
              <span>{t('uikit/ImagePicker/or')}</span>
            </p>
            <h1 className={cx('title')}>{t('uikit/ImagePicker/link')}</h1>
            <div
              className={cx('link-container', {
                transparent: !!importedImage,
              })}
            >
              {link ? (
                <img
                  alt={t('uikit/ImagePicker/preview')}
                  aria-hidden="true"
                  className={cx('link-preview')}
                  src={link}
                />
              ) : (
                <p className={cx('https')}>https://</p>
              )}
              <InputText
                aria-label={t('uikit/ImagePicker/aria-link')}
                name="link"
                onChange={(event) => handleLinkChange(event, setFieldValue)}
              />
              {link && (
                <Button
                  aria-label={t('uikit/ImagePicker/delete')}
                  className={cx('link-reset-button')}
                  icon="times"
                  onClick={() => handleLinkReset(setFieldValue)}
                />
              )}
            </div>
            {(importedImage || link) && (
              <div className={cx('footer')}>
                <Button
                  intent="primary"
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('uikit/ImagePicker/select')}
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  </Modal>
);

ImagePicker.propTypes = {
  current: PropTypes.string,
  handleDragEnter: PropTypes.func.isRequired,
  handleDragLeave: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  handleImportedImageReset: PropTypes.func.isRequired,
  handleLinkChange: PropTypes.func.isRequired,
  handleLinkReset: PropTypes.func.isRequired,
  handleModalCloseAndReset: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  importedImage: PropTypes.object,
  importedPreview: PropTypes.string,
  isDroppable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ImagePicker.defaultProps = {
  current: undefined,
  importedImage: undefined,
  importedPreview: undefined,
};

export default ImagePicker;
