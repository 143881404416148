import classNames from 'classnames/bind';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationQuestionGenerateFeedback } from 'gql/mutations/question';
import extract from 'helpers/extract';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import { notify } from 'uikit/Notifications';
import Tooltip from 'uikit/Tooltip';

import styles from './FeedbackWithIA.module.scss';

const cx = classNames.bind(styles);

const FeedbackWithIA = ({ className = null, type, validationSchema }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/FeedbackWithIA',
  });

  const { values } = useFormikContext();

  const [, , { setValue: setFeedback }] = useField('feedback');

  const [errorType, setErrorType] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const mutationQuestionGenerateFeedback =
    useMutationQuestionGenerateFeedback();

  async function handleFeedbackGenerate() {
    try {
      setErrorType(null);
      setLoading(true);
      const content = extract(values, validationSchema);
      const {
        data: { questionGenerateFeedback: feedback },
      } = await mutationQuestionGenerateFeedback({
        type,
        content,
      });
      setFeedback(feedback);
      notify('ia', t('notification'));
    } catch (err) {
      setErrorType(err.message === '400' ? 'user-input' : 'ai-service');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={cx('container', className)}>
      <div className={cx('header')}>
        <div className={cx('title')}>
          <div className={cx('label')}>{t('feedback-label')}</div>
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        </div>
        <Tooltip tooltip={t('from-ia-tooltip')}>
          <div className={cx('ia-button-container')}>
            <Button
              className={cx('ia-button')}
              isDisabled={isLoading}
              icon="wand-sparkles"
              intent="ia-light"
              onClick={handleFeedbackGenerate}
            >
              <span className={cx('ia-button-text-large')}>
                {t('generate-feedback')}
              </span>
              <span className={cx('ia-button-text-small')}>
                {t('generate-feedback-small')}
              </span>
            </Button>
          </div>
        </Tooltip>
      </div>
      {errorType && (
        <p className={cx('error-message')}>{t(`error-message-${errorType}`)}</p>
      )}
      {isLoading ? (
        <p className={cx('feedback-placeholder')}>{t('feedback-loading')}</p>
      ) : (
        <InputRich name="feedback" />
      )}
    </div>
  );
};

FeedbackWithIA.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default FeedbackWithIA;
