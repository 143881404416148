import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled = false, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Sorting/Recto',
  });

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  const dndRef = useRef(null);

  async function handleMoveDown(index) {
    if (!isDisabled && dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveDown();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }

  async function handleMoveUp(index) {
    if (!isDisabled && dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveUp();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }

  function handleSortEnd({ source, destination }) {
    if (!destination || source.index === destination.index) {
      return;
    }
    const fromIndex = source.index;
    const toIndex = destination.index;
    const newAnswer = [...answer];
    newAnswer.splice(toIndex, 0, newAnswer.splice(fromIndex, 1)[0]);
    setAnswer(newAnswer);
  }

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <DragDropContext
        onDragEnd={handleSortEnd}
        sensors={[
          (api) => {
            // eslint-disable-next-line no-param-reassign
            dndRef.current = api;
          },
        ]}
      >
        <Droppable droppableId="items">
          {(providedItems, snapshotItems) => (
            <div
              className={cx('zone', {
                isDraggingOver: snapshotItems.isDraggingOver,
              })}
              ref={providedItems.innerRef}
              {...providedItems.droppableProps}
            >
              {answer.map((idx, index) => {
                const item = question.content.items.find((i) => i.idx === idx);
                return (
                  <Draggable
                    aria-describedby="aria-drag-description"
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={isDisabled}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {(providedItem, snapshotItem) => (
                      <div
                        className={cx('item', {
                          isDisabled,
                          isDragging: snapshotItem.isDragging,
                        })}
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                        {...providedItem.dragHandleProps}
                      >
                        <p
                          className={cx('aria-label')}
                          id="aria-drag-description"
                        >
                          {t('aria-drag-description')}
                        </p>
                        <div className={cx('drag')} role="button">
                          <Icon name="arrows-v" />
                        </div>
                        <FormattedText className={cx('content')}>
                          {item?.title}
                        </FormattedText>
                        <Button
                          aria-label={t('move-down', {
                            name: sanitize.string(item?.title),
                          })}
                          className={cx('action')}
                          icon="arrow-down"
                          isDisabled={
                            isDisabled ||
                            index === question.content.items.length - 1
                          }
                          onClick={() => handleMoveDown(index)}
                        />
                        <Button
                          aria-label={t('move-up', {
                            name: sanitize.string(item?.title),
                          })}
                          className={cx('action')}
                          icon="arrow-up"
                          isDisabled={isDisabled || index === 0}
                          onClick={() => handleMoveUp(index)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedItems.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  question.content.items.map((item) => item.idx);

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup.array().of(yup.number().required().integer().min(0)),
  });

export default Recto;
