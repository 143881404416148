import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DirectLink from 'components/Course/Shared/DirectLink/DirectLink';
import Email from 'components/Course/Shared/Email/Email';
import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import Modal from 'uikit/Modal/Modal';

import styles from './GroupShare.module.scss';

const cx = classNames.bind(styles);

const GroupShare = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/GroupShare',
  });

  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();

  const {
    data: { me },
  } = useQueryMe();

  const [isDisplayed, setIsDisplayed] = useState(true);

  function canvasRef(element) {
    if (element && groupEditing) {
      QRCode.toCanvas(
        element,
        `${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}/${groupEditing.id}`,
        (err) => {
          if (err) {
            setIsDisplayed(false);
          }
        },
      );
    }
  }

  const isCourseInvitationRestricted =
    me?.organization?.settings?.isCourseInvitationRestricted;

  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_SHARE;

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear} title={t('title')}>
      <div className={cx('warning-container')}>
        {course.isExam && course.examStartTime === course.examStopTime && (
          <p className={cx('warning')}>{t('warning-settings')}</p>
        )}
        {isCourseInvitationRestricted && (
          <p className={cx('warning')}>{t('warning-invitations-restricted')}</p>
        )}
      </div>
      <DirectLink course={course} group={groupEditing} />
      <Email className={cx('email')} course={course} group={groupEditing} />
      {isDisplayed && <canvas className={cx('qrcode')} ref={canvasRef} />}
    </Modal>
  );
};

GroupShare.propTypes = {
  course: PropTypes.object.isRequired,
};

export default GroupShare;
