import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGroupDelete } from 'gql/mutations/group';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Modal from 'uikit/Modal/Modal';

const GroupDelete = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/GroupDelete',
  });

  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();

  const mutationGroupDelete = useMutationGroupDelete();

  const [status, setStatus] = useState(undefined);

  const [isLoading, setLoading] = useState(false);

  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_DELETE;

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);

  async function handleGroupDelete() {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationGroupDelete(groupEditing);
      mutationEditorClear();
      trackEvent('group', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('group', 'delete', err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      action={
        <Button
          intent="danger"
          isLoading={isLoading}
          onClick={handleGroupDelete}
          size="large"
        >
          {t('confirm-delete-student')}
        </Button>
      }
      error={status && t('student-delete-error')}
      isOpen={isModalOpen}
      isTitleCentered={true}
      onExit={mutationEditorClear}
      title={t('title')}
      subtitle={t('subtitle')}
    />
  );
};

export default GroupDelete;
