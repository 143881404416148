import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';

import ImageContainer from '../Shared/ImageContainer/ImageContainer';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <ImageContainer
        className={cx('image')}
        imageSrc={question.content.image}
        solution={question.solution}
        result={question.result}
      />
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => ({
  x: -1,
  y: -1,
});

Verso.defaultSolution = (question) => ({
  userX: null,
  userY: null,
  zones: question.content.zones,
});

export default Verso;
