import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import styles from './Expert.module.scss';

const cx = classNames.bind(styles);

const Expert = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/JCT/Expert',
  });
  const { t: tExpertsLabel } = useTranslation();

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  return (
    <div className={cx('container', className)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('subtitle')}>
        <FormattedText>{question.content.subtitle}</FormattedText>
      </div>
      <div className={cx('instructions')}>{t('instructions')}</div>
      <div className={cx('experts')}>
        {[0, 1, 2, 3].map((index) => (
          <button
            aria-pressed={answer === index}
            className={cx('expert', {
              isActive: answer === index,
            })}
            key={index}
            onClick={() => setAnswer(index)}
            type="button"
          >
            {question.content.labels && question.content.labels[index]
              ? question.content.labels[index]
              : tExpertsLabel(`Questions/JCT/experts-${index}-label`)}
          </button>
        ))}
      </div>
      <InputText
        className={cx('expert-feedback')}
        label={t('feedback-label')}
        name="feedback"
      />
    </div>
  );
};

Expert.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Expert.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .number()
      .required(t('Questions/JCT/Expert/answer-error'))
      .min(0, t('Questions/JCT/Expert/answer-error'))
      .max(3, t('Questions/JCT/Expert/answer-error')),
    feedback: yup.string().trim().max(1024),
  });

export default Expert;
