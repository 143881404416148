import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import styles from './Recto.module.scss';
import {
  useQueryLocalApp,
  useQueryLocalEditor,
} from '../../../gql/queries/local';
import EDITOR_STATUSES from '../../../constants/editorStatuses';
import getRectoNewCanvasWidth from '../helpers/getRectoNewCanvasWidth';
import DEFAULT_CANVAS_PROPERTIES
  from '../../Shared/SlideBuilder/data/defaultCanvasProperties';
import getAvailableHeight from '../helpers/getAvailableHeight';

const cx = classNames.bind(styles);

const Recto = ({  className = null, question }) => {
  const fabricRef = useRef(null);
  const canvasRef = useRef(null);

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const resizeCanvas = useCallback(async () => {
    if (fabricRef?.current) {
      const isPreview = editorStatus === EDITOR_STATUSES.QUESTION_PREVIEW;
      const menuState = isMenuExpanded ? 'expandedMenu' : 'reducedMenu';
      // Resize the canvas regarding the window width
      let newCanvasWidth = getRectoNewCanvasWidth(isPreview, menuState);
      let scale = DEFAULT_CANVAS_PROPERTIES.width / newCanvasWidth;
      let zoom = DEFAULT_CANVAS_PROPERTIES.zoom / scale;
      let newCanvasHeight =
        newCanvasWidth / DEFAULT_CANVAS_PROPERTIES.widthHeightRatio;
      // Resize the canvas regarding the window height if needed
      if (!isPreview && newCanvasHeight > getAvailableHeight()) {
        newCanvasHeight = getAvailableHeight();
        newCanvasWidth =
          newCanvasHeight * DEFAULT_CANVAS_PROPERTIES.widthHeightRatio;
        scale = DEFAULT_CANVAS_PROPERTIES.height / newCanvasHeight;
        zoom = DEFAULT_CANVAS_PROPERTIES.zoom / scale;
      }
      fabricRef.current.setDimensions({
        width: newCanvasWidth,
        height: newCanvasHeight,
      });
      fabricRef.current.setZoom(zoom);
    }
  }, [editorStatus, fabricRef, isMenuExpanded]);

  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.StaticCanvas(canvasRef.current);
    };
    const disposeFabric = () => {
      fabricRef.current.dispose();
    };
    initFabric();
    fabricRef.current.loadFromJSON(question.content.slideContent);
    resizeCanvas();
    return () => {
      disposeFabric();
    };
  }, [canvasRef, fabricRef, question, resizeCanvas]);

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, [resizeCanvas]);

  return (
    <div className={cx('container', className)}>
      <canvas className={cx('canvas')} ref={canvasRef} />
    </div>
  );
}

Recto.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = () => undefined;

Recto.validationSchema = () => undefined;

export default Recto;
