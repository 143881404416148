import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioPreview from 'uikit/AudioPicker/AudioPreview/';
import CleanedText from 'uikit/CleanedText';
import VideoPreview from 'uikit/VideoPicker/VideoPreview';

import Footer from './Footer/Footer';
import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Interview/Aggregate',
  });

  const [currentAnswer, setCurrentAnswer] = useState(0);
  const [mediaDuration, setMediaDuration] = useState(' ');

  async function handleAnswerNext() {
    setCurrentAnswer(
      Math.min(currentAnswer + 1, aggregation.aggregatedAnswers.length - 1),
    );
  }

  function handleAnswerPrevious() {
    setCurrentAnswer(Math.max(currentAnswer - 1, 0));
  }

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('total-answers', {
                count: aggregation.nTotalAnswers,
              })}
            </span>
          </p>
        </div>
        <div className={cx('answer-wrapper')}>
          {question.content.answerType === 'video' ? (
            <VideoPreview
              className={cx('preview')}
              footer={
                <Footer
                  aggregation={aggregation}
                  currentAnswer={currentAnswer}
                  handleAnswerNext={handleAnswerNext}
                  handleAnswerPrevious={handleAnswerPrevious}
                  isVideo
                  mediaDuration={mediaDuration}
                />
              }
              setVideoDuration={setMediaDuration}
              video={aggregation.aggregatedAnswers[currentAnswer].answer}
            />
          ) : (
            <AudioPreview
              audio={aggregation.aggregatedAnswers[currentAnswer].answer}
              className={cx('preview')}
              footer={
                <Footer
                  aggregation={aggregation}
                  currentAnswer={currentAnswer}
                  handleAnswerNext={handleAnswerNext}
                  handleAnswerPrevious={handleAnswerPrevious}
                  mediaDuration={mediaDuration}
                />
              }
              setAudioDuration={setMediaDuration}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
