import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Math/Verso',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('answer')}>
        {question.result !== 'correct' && (
          <>
            <p className={cx('label')}>{t('wrong-answer')}</p>
            <CleanedText as="div" className={cx('wrong')}>
              {`\`${question.solution.user || '...'}\``}
            </CleanedText>
          </>
        )}
        <p className={cx('label')}>{t('correct-answer')}</p>
        <CleanedText as="div" className={cx('correct')}>
          {`\` ${question.solution.correct} \``}
        </CleanedText>
      </div>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => '';

Verso.defaultSolution = (question) => ({
  user: null,
  correct: question.content.answer,
});

export default Verso;
