import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useMutationCourseCreate } from 'gql/mutations/course';
import { useMutationMeTag } from 'gql/mutations/me';
import useSiteContext from 'hooks/useSiteContext';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import ILLUSTRATION_CODE from './images/code.svg';
import ILLUSTRATION_IA from './images/ia.svg';
import ILLUSTRATION_MANUAL from './images/manual.svg';
import ILLUSTRATION_MOBILE from './images/mobile.svg';
import styles from './NewTour.module.scss';

const cx = classNames.bind(styles);

const NewTour = ({ me }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/NewTour',
  });

  const location = useLocation();

  const { isEditionDisabled, wantsAI } = useSiteContext();

  const mutationMeTag = useMutationMeTag();

  const [isCourseCreating, setCourseCreating] = useState(false);

  const [isCourseFromIACreating, setCourseFromIACreating] = useState(false);

  const isJoiningCourse = location.pathname.includes('/course/');

  const handleTourClose = async () => {
    try {
      await mutationMeTag(['tour:1']);
      trackEvent('tag', 'tour:1');
    } catch (err) {
      reportError('tag', 'tour:1', err);
    }
  };

  const handleTriggerTourSteps = async () => {
    try {
      await mutationMeTag(['first-course-created']);
      trackEvent('tag', 'first-course-created');
    } catch (err) {
      reportError('tag', 'first-course-created', err);
    }
  };

  const handleAvoidFirstTourStep = async () => {
    try {
      await mutationMeTag(['first-course-created', 'tour:2']);
      trackEvent('tag', 'first-course-created, tour:2');
    } catch (err) {
      reportError('tag', 'first-course-created, tour:2', err);
    }
  };

  const mutationCourseCreate = useMutationCourseCreate();

  const handleCourseCreate = async (useIA) => {
    try {
      if (useIA) {
        setCourseFromIACreating(true);
        await handleAvoidFirstTourStep();
      } else {
        setCourseCreating(true);
        await handleTriggerTourSteps();
      }
      const {
        data: { courseCreate },
      } = await mutationCourseCreate({
        title: t('new-course-title'),
      });
      await handleTourClose();
      history.push(`/course/${courseCreate.id}?useIA=${useIA}`);
      trackEvent('course', 'create');
    } catch (err) {
      reportError('course', 'create', err);
    } finally {
      setCourseCreating(false);
      setCourseFromIACreating(false);
    }
  };

  const handleCourseJoin = async () => {
    await handleTourClose();
    document.getElementById('course-join-button')?.click();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('title-container')}>
        <FormattedText as="h1" className={cx('title')}>
          {t(`title`, { name: me.firstName })}
        </FormattedText>
        <FormattedText as="h2" className={cx('subtitle')}>
          {t(`subtitle-${me.role}`)}
        </FormattedText>
      </div>

      <img
        alt=""
        aria-hidden="true"
        className={cx('picture', {
          'access-course': isJoiningCourse || isEditionDisabled,
        })}
        src={ILLUSTRATION_MOBILE}
      />
      {isJoiningCourse || isEditionDisabled ? (
        <button
          className={cx('button', 'button-access-course')}
          onClick={handleTourClose}
        >
          <img alt="" aria-hidden="true" src={ILLUSTRATION_MANUAL} />
          {t(isEditionDisabled ? 'access-dashboard' : 'access-course')}
        </button>
      ) : (
        <div className={cx('actions-container')}>
          <p className={cx('title', 'actions-title')}>
            {t(`question-${me.role}`)}
          </p>
          <div className={cx('actions')}>
            {wantsAI && (
              <div className={cx('ia-border')}>
                <button
                  className={cx('button', 'button-ia')}
                  onClick={() => handleCourseCreate(true)}
                >
                  {isCourseFromIACreating ? (
                    <Icon
                      className={cx('button-image', 'loading')}
                      name="spinner-third"
                    />
                  ) : (
                    <img
                      alt=""
                      aria-hidden="true"
                      className={cx('button-image')}
                      src={ILLUSTRATION_IA}
                    />
                  )}
                  {t('course-from-ia')}
                </button>
              </div>
            )}
            <button
              className={cx('button')}
              data-testid="tour-course-create-button"
              onClick={() => handleCourseCreate(false)}
            >
              {isCourseCreating ? (
                <Icon
                  className={cx('button-image', 'loading')}
                  name="spinner-third"
                />
              ) : (
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('button-image')}
                  src={ILLUSTRATION_MANUAL}
                />
              )}
              {t('course-from-start')}
            </button>
            <button
              className={cx('button')}
              data-testid="tour-course-join-button"
              onClick={handleCourseJoin}
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-image')}
                src={ILLUSTRATION_CODE}
              />
              {t('course-join')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

NewTour.propTypes = {
  me: PropTypes.object.isRequired,
};

export default NewTour;
