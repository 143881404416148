import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = ({
  aggregation,
  currentAnswer,
  handleAnswerNext,
  handleAnswerPrevious,
  isVideo = false,
  mediaDuration,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Interview/Aggregate',
  });

  return (
    <>
      <div className={cx('info-left')}>
        <p className={cx('author')}>
          {t('author', {
            name: `${aggregation.aggregatedAnswers[currentAnswer].student.firstName} ${aggregation.aggregatedAnswers[currentAnswer].student.lastName}`,
          })}
        </p>
        {mediaDuration !== ' ' && isVideo && (
          <div className={cx('duration-container')}>
            <Icon className={cx('duration-icon')} name="clock" />
            <p className={cx('duration')}>{mediaDuration}</p>
          </div>
        )}
      </div>
      <div className={cx('info-right')}>
        <Button
          className={cx('button')}
          icon="arrow-left"
          isDisabled={currentAnswer <= 0}
          onClick={handleAnswerPrevious}
          size="large"
          tooltip={t('previous-answer')}
        />
        <p className={cx('number')}>{`${currentAnswer + 1}/${
          aggregation.aggregatedAnswers.length
        }`}</p>

        <Button
          className={cx('button')}
          icon="arrow-right"
          isDisabled={currentAnswer >= aggregation.nTotalAnswers - 1}
          onClick={handleAnswerNext}
          size="large"
          tooltip={t('next-answer')}
        />
      </div>
    </>
  );
};

Footer.propTypes = {
  aggregation: PropTypes.object.isRequired,
  currentAnswer: PropTypes.number.isRequired,
  handleAnswerNext: PropTypes.func.isRequired,
  handleAnswerPrevious: PropTypes.func.isRequired,
  isVideo: PropTypes.bool,
  mediaDuration: PropTypes.string.isRequired,
};

export default Footer;
