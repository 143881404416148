import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip';

import ExpertInstructions from '../../Shared/ExpertInstructions/ExpertInstructions';
import ExpertOpinionEditor from '../../Shared/ExpertOpinionEditor/ExpertOpinionEditor';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/JCT/Editor',
  });

  return (
    <div className={cx('container', className)}>
      <InputRich
        className={cx('field')}
        data-testid="subtitle"
        label={t('question-label')}
        name="subtitle"
        placeholder={t('subtitle-placeholder')}
      />
      <div className={cx('label-container')}>
        <p className={cx('subtitle')}>{t('experts-title')}</p>
        <ExpertInstructions questionType="JCT" />
      </div>
      <InputError name="experts" />
      <div className={cx('field', 'experts')}>
        {[0, 1, 2, 3].map((index) => (
          <ExpertOpinionEditor
            columnIndex={index}
            key={`expert-${index}`}
            questionType="JCT"
          />
        ))}
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/JCT/Editor/title-error-required')),
    subtitle: yup
      .string()
      .trim()
      .required(t('Questions/JCT/Editor/hypothesis-error-required')),
    labels: yup.array().of(yup.string().nullable().trim()),
    experts: yup.array().of(yup.number().integer().min(0)),
    expertFeedbacks: yup
      .array()
      .of(
        yup.array().of(
          yup.object().shape({
            id: yup.string().trim().nullable().uuid(),
            firstName: yup.string().trim().nullable(),
            lastName: yup.string().trim().nullable(),
            feedback: yup.string().trim(),
          }),
        ),
      )
      .required(),
    feedback: yup.string().trim(),
  });

export default Editor;
