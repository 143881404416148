import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGroupUpdate } from 'gql/mutations/group';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal/Modal';

import styles from './GroupEdit.module.scss';

const cx = classNames.bind(styles);

const GroupEdit = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Students/GroupEdit',
  });

  const mutationGroupUpdate = useMutationGroupUpdate();

  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(t('title-error-required'))
      .max(256, t('title-error-required')),
  });

  const initialValues = groupEditing
    ? {
        title: '',
        ...groupEditing,
      }
    : {};

  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_UPDATE;

  async function handleSave(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationGroupUpdate(groupEditing, variables);
      mutationEditorClear();
      trackEvent('group', 'update');
    } catch (err) {
      form.setStatus('500');
      reportError('group', 'update', err);
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      isTitleCentered={true}
      onExit={mutationEditorClear}
      title={t('title')}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('group-edit-error')}
              </p>
            )}
            <InputText
              autoFocus
              className={cx('field')}
              label={t('title-label')}
              name="title"
              maxLength={256}
            />
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('save-group')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default GroupEdit;
