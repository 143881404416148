import t from 'helpers/t';

import Aggregate from './Aggregate/Aggregate';
import Editor from './Editor/Editor';
import Recto from './Recto/Recto';
import Verso from './Verso/Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const LabelOnImage = {
  info: {
    type: 'LabelOnImage',
    name: t('Questions/LabelOnImage/name'),
    tooltip: t('Questions/LabelOnImage/tooltip'),
    icon: svg,
    picture,
    color: 'red',
    isCorrectable: true,
    isOneSided: false,
    isInScore: true,
    isReportable: true,
    study: true,
    play: true,
    test: true,
    isNew: false,
    canFullForceCorrect: false,
    canPartialForceCorrect: true,
  },
  Aggregate,
  Editor,
  Recto,
  Verso,
};

export default LabelOnImage;
