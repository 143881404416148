import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryPublicCourse } from 'gql/queries/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import {
  mutationEditorQuestionImportPreview,
  mutationEditorReset,
} from 'gql/mutations/editor';
import { useMutationQuestionImportPublicCourse } from 'gql/mutations/question';
import extract from 'helpers/extract';
import useModal from 'hooks/useModal';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';
import Button from 'uikit/Button';
import InputError from 'uikit/InputError';
import InputQuestionTree from 'uikit/InputQuestionTree';
import Modal from 'uikit/ModalLegacy';
import Select from 'uikit/Select';

import QuestionImporterPreview from './QuestionImporterPreview/QuestionImporterPreview';
import styles from './QuestionImporter.module.scss';

const cx = classNames.bind(styles);

const QuestionImporter = ({ course, selectedCourse }) => {
  const { t } = useTranslation('', {
    keyPrefix:
      'Course/Teacher/Editor/QuestionImport/QuestionImportPublicCourses/QuestionImporter',
  });

  const mutationQuestionImportPublicCourse =
    useMutationQuestionImportPublicCourse();

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const { isLoading, data } = useQueryPublicCourse({
    variables: {
      courseId: selectedCourse.id,
    },
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  function handleClose() {
    handleModalClose();
    mutationEditorQuestionImportPreview(null);
  }

  async function handleQuestionsImport(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationQuestionImportPublicCourse({
        ...variables,
        questionIds: questionEditing
          ? [questionEditing.id]
          : variables.questionIds,
        courseId: course.id,
        selectedCourseId: selectedCourse.id,
      });
      if (!questionEditing) {
        mutationEditorReset();
      } else {
        mutationEditorQuestionImportPreview(null);
      }
      notify('success', t('questions-import-success'));
      trackEvent('questions', 'import-public-course');
    } catch (err) {
      form.setStatus('500');
      reportError('questions', 'import-public-course', err);
    }
  }

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    questionIds: yup
      .array()
      .of(yup.string().required().trim().uuid())
      .compact()
      .test('length', t('questions-error-required'), (questions) => {
        if (questionEditing) {
          return true;
        }
        return questions.length > 0;
      }),
  });

  const question = questionEditing;

  const selectedPublicCourse = data?.publicCourse || selectedCourse;

  return (
    <>
      <Button
        aria-label={t('aria-import-questions', {
          name: selectedPublicCourse.title,
        })}
        onClick={handleModalOpen}
        variant="regular"
      >
        {t('import-questions')}
      </Button>
      <Modal isOpen={isModalOpen} onExit={handleClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleClose}
          />
          <h1 className={cx('title')}>{selectedPublicCourse.title}</h1>
          <Formik
            initialValues={{
              chapterId: '',
              questionIds: [],
            }}
            onSubmit={handleQuestionsImport}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status }) => (
              <Form className={cx('subpage')}>
                {status === '500' && (
                  <p className={cx('error')} role="alert">
                    {t('question-import-error')}
                  </p>
                )}
                <Select
                  className={cx('field')}
                  icon="book-open"
                  label={t('chapter-label')}
                  name="chapterId"
                >
                  <option value="">{t('no-chapter')}</option>
                  {course.chapters.map((chapter) => (
                    <option key={chapter.id} value={chapter.id}>
                      {chapter.title}
                    </option>
                  ))}
                </Select>
                {question ? (
                  <QuestionImporterPreview />
                ) : (
                  <>
                    {isLoading ? (
                      <LoadingScreen />
                    ) : (
                      <InputQuestionTree
                        allowPreview
                        course={selectedPublicCourse}
                        shouldSelectAll
                      />
                    )}
                  </>
                )}
                <InputError name="questionIds" />
                <div className={cx('actions')}>
                  <div />
                  <Button
                    className={cx('action')}
                    intent="primary"
                    isLoading={isSubmitting}
                    size="large"
                    type="submit"
                  >
                    {question
                      ? t('confirm-import-question')
                      : t('confirm-import-questions')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

QuestionImporter.propTypes = {
  course: PropTypes.object.isRequired,
  selectedCourse: PropTypes.object.isRequired,
};

export default QuestionImporter;
