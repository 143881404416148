import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingScreen from 'components/Shared/LoadingScreen';
import CATEGORIES from 'constants/categories';
import DESTINATIONS from 'constants/destinations';
import LANGUAGES from 'constants/languages';
import { useQueryPublicCourses } from 'gql/queries/course';
import { useQueryTags } from 'gql/queries/tag';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import InputTextArray from 'uikit/InputTextArray';
import Select from 'uikit/Select';

import Category from './Category/Category';
import styles from './QuestionImportPublicCourses.module.scss';

const cx = classNames.bind(styles);

const QuestionImportPublicCourses = ({ course = null }) => {
  const { t } = useTranslation('', {
    keyPrefix:
      'Course/Teacher/Editor/QuestionImport/QuestionImportPublicCourses',
  });

  const { t: tFilter } = useTranslation();

  const { data: tagsData } = useQueryTags();

  const { isLoading, data } = useQueryPublicCourses();

  const [showFilters, setShowFilters] = useState(false);

  const handleResetFilters = (setFieldValue) => {
    setFieldValue('category', '');
    setFieldValue('language', '');
    setFieldValue('destination', '');
    setFieldValue('tags', []);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const tags = tagsData ? tagsData.tags.map((tag) => tag.title) : [];

  const courses = isLoading ? [] : data.publicCourses;

  const orderedCategories = CATEGORIES.map((category) => ({
    key: category.key,
    translation: tFilter(category.translation),
  })).sort((a, b) =>
    a.key === 'other' ? 1 : a.translation.localeCompare(b.translation),
  );

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Formik
          initialValues={{
            category: '',
            language: '',
            destination: '',
            tags: [],
            search: '',
          }}
          onSubmit={() => null}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className={cx('header')}>
                <h1 className={cx('title')}>
                  {values.search
                    ? t('search-in', {
                        search: values.search,
                      })
                    : t('title')}
                </h1>
                <div className={cx('options')}>
                  {(values.category !== '' ||
                    values.language !== '' ||
                    values.destination !== '' ||
                    values.tags.length > 0) && (
                    <Button
                      aria-label={t('aria-reset-filters')}
                      className={cx('reset')}
                      onClick={() => handleResetFilters(setFieldValue)}
                    >
                      {t('reset-filters')}
                    </Button>
                  )}
                  <InputText
                    aria-label={t('aria-search')}
                    className={cx('search')}
                    icon="search"
                    name="search"
                    placeholder={t('search-placeholder')}
                  />
                  <Button
                    className={cx('filter-toggle')}
                    icon="sliders-h"
                    intent={showFilters ? 'primary' : 'white'}
                    onClick={toggleFilters}
                  >
                    {t('filter')}
                  </Button>
                </div>
              </div>
              {showFilters && (
                <div className={cx('filters')}>
                  <Select
                    className={cx('filter')}
                    label={t('category-label')}
                    name="category"
                  >
                    <option value="">{t('category-empty')}</option>
                    {orderedCategories.map((category) => (
                      <option key={category.key} value={category.key}>
                        {tFilter(category.translation)}
                      </option>
                    ))}
                  </Select>
                  <Select
                    className={cx('filter')}
                    label={t('language-label')}
                    name="language"
                  >
                    <option value="">{t('language-empty')}</option>
                    {LANGUAGES.map((language) => (
                      <option key={language.key} value={language.key}>
                        {tFilter(language.translation)}
                      </option>
                    ))}
                  </Select>
                  <Select
                    className={cx('filter')}
                    label={t('destination-label')}
                    name="destination"
                  >
                    <option value="">{t('destination-empty')}</option>
                    {DESTINATIONS.map((destination) => (
                      <option key={destination.key} value={destination.key}>
                        {tFilter(destination.translation)}
                      </option>
                    ))}
                  </Select>
                  <InputTextArray
                    className={cx('filter')}
                    disableCreation
                    intent="square"
                    label={t('tags-label')}
                    name="tags"
                    options={tags}
                  />
                </div>
              )}
              {orderedCategories.map(
                (category) =>
                  (values.category === category.key ||
                    values.category === '') && (
                    <Category
                      category={category}
                      course={course}
                      courses={courses}
                      destination={values.destination}
                      handleCategoryFocus={() =>
                        setFieldValue('category', category.key)
                      }
                      key={category.key}
                      language={values.language}
                      search={values.search}
                      tags={values.tags}
                    />
                  ),
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

QuestionImportPublicCourses.propTypes = {
  course: PropTypes.object,
};

export default QuestionImportPublicCourses;
