import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Interview/Editor',
  });

  const [{ value: answerType }, , { setValue: setAnswerType }] =
    useField('answerType');

  function handleAnswerType(type) {
    setAnswerType(type);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('answer-type-label')}</p>
      <div className={cx('answer-type-container')}>
        <button
          className={cx('answer-type-button', {
            isChecked: answerType === 'video',
          })}
          onClick={() => handleAnswerType('video')}
          type="button"
        >
          <span
            className={cx('checkbox', {
              isChecked: answerType === 'video',
            })}
          >
            <Icon name="check" />
          </span>
          <span className={cx('answer-type-icon-container')}>
            <Icon className={cx('answer-type-icon')} name="video" />
          </span>
          <span className={cx('answer-type-text')}>{t('video')}</span>
        </button>
        <p className={cx('or')}>{t('or')}</p>
        <button
          className={cx('answer-type-button', {
            isChecked: answerType === 'audio',
          })}
          onClick={() => handleAnswerType('audio')}
          type="button"
        >
          <span className={cx('checkbox')}>
            <Icon name="check" />
          </span>
          <span className={cx('answer-type-icon-container')}>
            <Icon className={cx('answer-type-icon')} name="microphone-lines" />
          </span>
          <span className={cx('answer-type-text')}>{t('audio')}</span>
        </button>
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Essay/Editor/title-error-required')),
    answerType: yup.string().trim().required().oneOf(['video', 'audio']),
    feedback: yup.string().trim(),
  });

export default Editor;
