import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import BACKGROUNDS from 'constants/backgrounds';
import TEXTS from 'constants/texts';
import useModal from 'hooks/useModal';
import Context from 'lib/context';
import Options from 'questions/Shared/Options/Options';
import Icon from 'uikit/Icon';
import Tags from 'questions/Shared/Tags/Tags';
import ImagePicker from 'uikit/ImagePicker';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Slide/Editor',
  });

  const { theme } = useContext(Context);

  const [{ value: background }, , { setValue: setBackground }] =
    useField('background');
  const [{ value: textColor }, , { setValue: setTextColor }] =
    useField('textColor');

  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);

  function handleImageSelect(url) {
    setBackground(url);
    handleModalClose();
  }

  return (
    <>
      <div className={cx('container', className)}>
        <p className={cx('label')}>{t('background-label')}</p>
        {theme && background !== theme && (
          <p className={cx('error')}>{t('background-alert')}</p>
        )}
        <div className={cx('background-options')}>
          {BACKGROUNDS.map((bg) => (
            <label htmlFor={`background-${bg}`} key={bg}>
              <input
                aria-labelledby={`checkbox-background-${bg}-select`}
                checked={
                  background === bg ||
                  (bg === 'image-picker' &&
                    !BACKGROUNDS.includes(background) &&
                    background !== null)
                }
                className={cx('check')}
                id={`background-${bg}`}
                type="checkbox"
                onChange={
                  bg === 'image-picker'
                    ? handleModalOpen
                    : () => setBackground(bg)
                }
              />
              <span
                className={cx('checkbox', `background-${bg}`, {
                  isChecked:
                    background === bg ||
                    (bg === 'image-picker' &&
                      !BACKGROUNDS.includes(background) &&
                      background !== ''),
                })}
              >
                {bg === 'image-picker' && <Icon name="image" />}
              </span>
            </label>
          ))}
        </div>
        <p className={cx('label')}>{t('text-color-label')}</p>
        <div className={cx('background-options')}>
          {TEXTS.map((color) => (
            <label htmlFor={`color-${color}`} key={color}>
              <input
                aria-labelledby={`checkbox-color-${color}-select`}
                checked={textColor === color}
                className={cx('check')}
                id={`color-${color}`}
                type="checkbox"
                onChange={() => setTextColor(color)}
              />
              <span
                className={cx('checkbox', `background-${color}`, {
                  isChecked: textColor === color,
                })}
              />
            </label>
          ))}
        </div>
        <Options className={cx('options')}>
          <Tags />
        </Options>
      </div>
      <ImagePicker
        current={BACKGROUNDS.includes(background) ? null : background}
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        onSelect={handleImageSelect}
      />
    </>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/title-error-required')),
    background: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/background-error-required')),
    textColor: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/textColor-error-required')),
  });

export default Editor;
