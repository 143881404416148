import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import InputTextArray from 'uikit/InputTextArray';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/ShortAnswer/Editor',
  });

  return (
    <div className={cx('container', className)}>
      <InputTextArray
        className={cx('field')}
        data-testid="answers"
        label={t('answers-label')}
        name="answers"
        options={[]}
        placeholder={t('answers-placeholder')}
      />
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
        placeholder={t('feedback-placeholder')}
      />
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('ignore-case-label')}
          name="ignoreCase"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-accents-label')}
          name="ignoreAccents"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-punctuation-label')}
          name="ignorePunctuation"
        />
        <Tags />
      </Options>
    </div>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
};


Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/ShortAnswer/Editor/title-error-required')),
    answers: yup
      .array()
      .of(yup.string().trim())
      .compact()
      .test(
        'length',
        t('Questions/ShortAnswer/Editor/answers-error-length'),
        (answers) => answers.length >= 1,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default Editor;
