import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Combination.module.scss';

const cx = classNames.bind(styles);

const Combination = ({ answer, className = null, question }) => (
  <div className={cx('container', className)}>
    <div className={cx('header')}>
      <div className={cx('occurrence')}>
        <div className={cx('number')}>{answer.occurrence}</div>
        <Icon name="user" />
      </div>
      <Icon
        className={cx('header-icon', {
          wrong: answer.combination.some((element) => !element.isCorrect),
        })}
        name={
          answer.combination.some((element) => !element.isCorrect)
            ? 'times'
            : 'check'
        }
      />
    </div>
    {answer.combination.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className={cx('item', { pair: index % 2 === 0 })} key={index}>
        <div className={cx('position')}>{item.user + 1}</div>
        <FormattedText className={cx('item-text')}>
          {question.content.items[item.user].title}
        </FormattedText>
        <Icon
          className={cx('item-icon', { 'item-icon-wrong': !item.isCorrect })}
          name={item.isCorrect ? 'check' : 'times'}
        />
      </div>
    ))}
  </div>
);

Combination.propTypes = {
  answer: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

export default Combination;
