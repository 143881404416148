import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CleanedText from 'uikit/CleanedText';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Dictation/Aggregate',
  });

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('correct-answers', {
                count: aggregation.nCorrectAnswers,
              })}
            </span>
            <span
              className={cx('total-answers', {
                'total-answers-wrong': aggregation.nCorrectAnswers === 0,
              })}
            >
              {` / ${aggregation.nTotalAnswers}`}
            </span>
          </p>
        </div>
        {aggregation.aggregatedAnswers.map((answer) => (
          <div className={cx('answer')} key={answer.id}>
            <pre>
              {answer.map((change, changeIndex) => (
                <span
                  className={cx({
                    isAdded: change.added,
                    isRemoved: change.removed,
                  })}
                  key={`${answer.id}-change${changeIndex}`}
                >
                  {change.value}
                </span>
              ))}
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
