import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import PERMISSIONS from 'constants/permissions';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationQuestionMove } from 'gql/mutations/question';
import { useQueryCoursesMeChapters } from 'gql/queries/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';
import Select from 'uikit/Select';

import styles from './QuestionMove.module.scss';

const cx = classNames.bind(styles);

const QuestionMove = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionMove',
  });

  const mutationQuestionMove = useMutationQuestionMove();

  const {
    data: {
      editor: { status: editorStatus, questionEditing },
    },
  } = useQueryLocalEditor();

  const isModalOpen = editorStatus === EDITOR_STATUSES.QUESTION_MOVE;

  const { isLoading, data } = useQueryCoursesMeChapters({
    skip: !isModalOpen,
  });

  async function handleQuestionMove(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationQuestionMove(questionEditing, variables);
      mutationEditorClear();
      trackEvent('question', 'move');
    } catch (err) {
      form.setStatus('500');
      reportError('question', 'move', err);
    }
  }

  const validationSchema = yup.object().shape({
    courseId: yup.string().required(t('course-error-required')).trim().uuid(),
    chapterId: yup
      .string()
      .nullable()
      .transform((v) => v || null)
      .trim()
      .uuid(),
  });

  const courses = isLoading
    ? []
    : data.coursesMe.filter(
        (course) => course.level >= PERMISSIONS.COLLABORATOR,
      );

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear}>
      <Formik
        initialValues={{
          courseId: '',
          chapterId: '',
        }}
        onSubmit={handleQuestionMove}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={cx('modal')}>
            <Button
              aria-label={t('aria-close')}
              className={cx('close')}
              icon="times"
              onClick={mutationEditorClear}
            />
            <h1 className={cx('title')}>{t('title')}</h1>
            <p className={cx('subtitle')}>{t('subtitle')}</p>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('question-move-error')}
              </p>
            )}
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('course-label')}
              name="courseId"
            >
              <option disabled value="">
                {t('select-course')}
              </option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {`${course.title} - ${course.accessCode}`}
                </option>
              ))}
            </Select>
            {values.courseId && (
              <Select
                className={cx('field')}
                icon="book-open"
                label={t('chapter-label')}
                name="chapterId"
              >
                <option value="">{t('no-chapter')}</option>
                {courses
                  .find((c) => c.id === values.courseId)
                  ?.chapters.map((chapter) => (
                    <option key={chapter.id} value={chapter.id}>
                      {chapter.title}
                    </option>
                  ))}
              </Select>
            )}
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('confirm-move-question')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default QuestionMove;
