import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
  getDimensions,
} from 'questions/Shared/utils/images';

import Pin from './Pin/Pin';
import Zone from './Zone/Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  className = null,
  imageSrc,
  isReport = false,
  result = null,
  solution,
  userPins = [],
}) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });

  useEffect(() => {
    function handleScreenResize() {
      if (wrapperRef && imageRef) {
        handleImageLoaded();
      }
    }

    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [wrapperRef, imageRef]);

  function handleImageLoaded() {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }

  return (
    <div className={cx('container', className)} ref={wrapperRef}>
      <img
        alt="img"
        className={cx('image')}
        draggable="false"
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageSrc}
        style={{
          width: image.width,
          height: image.height,
        }}
      />
      {image.diagonal && isReport
        ? userPins.map((pin, index) => (
            <Pin
              key={`pin-${index}`}
              result={pin.result}
              sizeData={getDenormalizedPinPosition(
                {
                  x: pin.coordination.x,
                  y: pin.coordination.y,
                },
                image.diagonal,
              )}
            />
          ))
        : image.diagonal &&
          solution.userX &&
          solution.userY && (
            <Pin
              result={result}
              sizeData={getDenormalizedPinPosition(
                {
                  x: solution.userX,
                  y: solution.userY,
                },
                image.diagonal,
              )}
            />
          )}
      {image.diagonal &&
        solution.zones.map((zone, index) => (
          <Zone
            key={`zone-${index}`}
            shapeType={zone.shapeType}
            sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
          />
        ))}
    </div>
  );
};

ImageContainer.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  result: PropTypes.string,
  solution: PropTypes.object.isRequired,
  userPins: PropTypes.arrayOf(PropTypes.object),
};

export default ImageContainer;
