import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  getDenormalizedZonePosition,
  getDimensions,
  getEventCursorPosition,
  getNormalizedPinPosition,
  getNormalizedZonePosition,
} from 'questions/Shared/utils/images';

import Zone from './Zone/Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ZONE_MIN_SIZE = 25;

const ImageContainer = ({ className = null, imageSrc }) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const [activeZoneIndex, setActiveZoneIndex] = useState(null);
  const [data, setData] = useState({
    mouse: {
      positionX: null,
      positionY: null,
      fingerId: null,
    },
    draggingMode: null,
    updatedZoneIndex: null,
    updatedZone: null,
    basePosition: {
      baseLeft: null,
      baseTop: null,
      baseHeight: null,
      baseWidth: null,
    },
  });

  const [{ value: zones }, , { setValue: setZones }] = useField('zones');

  useEffect(() => {
    function handleScreenResize() {
      if (wrapperRef && imageRef) {
        handleImageLoaded();
      }
    }

    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  useEffect(() => {
    function handleMouseUp() {
      setData({
        mouse: {
          positionX: null,
          positionY: null,
          fingerId: null,
        },
        draggingMode: null,
        updatedZoneIndex: null,
        updatedZone: null,
        basePosition: {
          baseLeft: null,
          baseTop: null,
          baseHeight: null,
          baseWidth: null,
        },
      });
    }

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [setData]);

  function handleImageLoaded() {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }

  function handleMouseDown(event, index, dragMode) {
    event.stopPropagation();
    const [positionX, positionY, fingerId] = getEventCursorPosition(event);
    const zonePosition =
      dragMode === 'create'
        ? imageRef.current.getBoundingClientRect()
        : getDenormalizedZonePosition(zones[index], image.diagonal);
    setActiveZoneIndex(index);
    setData({
      mouse: {
        positionX,
        positionY,
        fingerId,
      },
      draggingMode: dragMode,
      updatedZoneIndex: index,
      updatedZone: dragMode === 'create' ? null : zones[index],
      basePosition: {
        baseLeft: zonePosition.left,
        baseTop: zonePosition.top,
        baseHeight: zonePosition.height,
        baseWidth: zonePosition.width,
      },
    });
  }

  function handleMouseMove(event) {
    const {
      mouse: { positionX, positionY, fingerId },
      draggingMode,
      updatedZoneIndex,
      updatedZone,
      basePosition,
    } = data;
    const [clientX, clientY] = getEventCursorPosition(event, fingerId);
    if (draggingMode === 'create') {
      const distance = Math.sqrt(
        (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
      );
      if (distance < 10) {
        return;
      }
      const startPosition = getNormalizedPinPosition(
        {
          left: positionX - basePosition.baseLeft,
          top: positionY - basePosition.baseTop,
        },
        image.diagonal,
      );
      const endPosition = getNormalizedPinPosition(
        {
          left: clientX - basePosition.baseLeft,
          top: clientY - basePosition.baseTop,
        },
        image.diagonal,
      );
      const newZone = {
        h: Math.abs(endPosition.y - startPosition.y),
        r: null,
        w: Math.abs(endPosition.x - startPosition.x),
        x: Math.min(startPosition.x, endPosition.x),
        y: Math.min(startPosition.y, endPosition.y),
        shapeType: 'rectangle',
      };
      if (newZone.h < 0.01 || newZone.w < 0.01) {
        return;
      }
      const zonePosition = getDenormalizedZonePosition(newZone, image.diagonal);
      setActiveZoneIndex(zones.length);
      setData((d) => ({
        ...d,
        draggingMode: 'resize',
        updatedZoneIndex: zones.length,
        updatedZone: newZone,
        basePosition: {
          baseLeft: zonePosition.left,
          baseTop: zonePosition.top,
          baseHeight: zonePosition.height,
          baseWidth: zonePosition.width,
        },
      }));
      setZones([...zones, newZone]);
      return;
    }
    if (!draggingMode || !updatedZone) {
      return;
    }
    const zonePosition = getDenormalizedZonePosition(
      updatedZone,
      image.diagonal,
    );
    const offsetX = clientX - positionX;
    const offsetY = positionY - clientY;
    const zoneWidth = basePosition.baseWidth || 0;
    const zoneHeight = basePosition.baseHeight || 0;
    const maxWidth = wrapperRef.current.offsetWidth;
    const maxHeight = wrapperRef.current.offsetHeight;
    if (draggingMode === 'position') {
      let newLeftPosition = basePosition.baseLeft + offsetX;
      let newTopPosition = basePosition.baseTop - offsetY;
      if (newLeftPosition <= 0 - zoneWidth / 2) {
        newLeftPosition = 0 - zoneWidth / 2;
      } else if (newLeftPosition >= maxWidth - zoneWidth / 2) {
        newLeftPosition = maxWidth - zoneWidth / 2;
      }
      if (newTopPosition <= 0 - zoneHeight / 2) {
        newTopPosition = 0 - zoneHeight / 2;
      } else if (newTopPosition >= maxHeight - zoneHeight / 2) {
        newTopPosition = maxHeight - zoneHeight / 2;
      }
      handleZoneUpdate(updatedZoneIndex, {
        width: zonePosition.width,
        height: zonePosition.height,
        left: newLeftPosition,
        top: newTopPosition,
      });
    } else if (draggingMode === 'resize') {
      const dimensions = {
        left: zonePosition.left,
        top: zonePosition.top,
      };
      let newWidth;
      let newHeight;
      if (updatedZone.shapeType === 'circle') {
        newWidth = basePosition.baseWidth - offsetY * 2;
        newHeight = newWidth;
        if (newWidth < ZONE_MIN_SIZE) {
          newWidth = ZONE_MIN_SIZE;
          newHeight = ZONE_MIN_SIZE;
          dimensions.left = zonePosition.left + (zoneWidth - ZONE_MIN_SIZE) / 2;
          dimensions.top = zonePosition.top + (zoneWidth - ZONE_MIN_SIZE) / 2;
        } else {
          dimensions.left = zonePosition.left + offsetY;
          dimensions.top = zonePosition.top + offsetY;
        }
      } else {
        newWidth = basePosition.baseWidth + offsetX;
        newHeight = basePosition.baseHeight - offsetY;
        if (newWidth >= maxWidth - zonePosition.left) {
          newWidth = maxWidth - zonePosition.left;
        } else if (newWidth < ZONE_MIN_SIZE) {
          newWidth = ZONE_MIN_SIZE;
        }
        if (newHeight >= maxHeight - zonePosition.top) {
          newHeight = maxHeight - zonePosition.top;
        } else if (newHeight < ZONE_MIN_SIZE) {
          newHeight = ZONE_MIN_SIZE;
        }
      }
      dimensions.width = newWidth;
      dimensions.height = newHeight;
      handleZoneUpdate(updatedZoneIndex, dimensions);
    }
  }

  function handleZoneDelete(index) {
    const filteredZones = zones.filter(
      (item, itemIndex) => itemIndex !== index,
    );
    setZones(filteredZones);
    setActiveZoneIndex(null);
    setData({
      mouse: {
        positionX: null,
        positionY: null,
        fingerId: null,
      },
      draggingMode: null,
      updatedZoneIndex: null,
      updatedZone: null,
      basePosition: {
        baseLeft: null,
        baseTop: null,
        baseHeight: null,
        baseWidth: null,
      },
    });
  }

  function handleZoneUpdate(index, updatedData) {
    const normalizedData = getNormalizedZonePosition(
      updatedData,
      image.diagonal,
      zones[index].shapeType,
    );
    const updatedZones = [...zones];
    updatedZones[index] = {
      ...updatedZones[index],
      ...normalizedData,
    };
    setZones(updatedZones);
  }

  return (
    <div className={cx('container', className)} ref={wrapperRef}>
      <button
        className={cx('wrapper')}
        onMouseDown={(event) => handleMouseDown(event, -1, 'create')}
        onTouchStart={(event) => handleMouseDown(event, -1, 'create')}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
        type="button"
      >
        <img
          alt="img"
          className={cx('image')}
          draggable="false"
          onLoad={handleImageLoaded}
          ref={imageRef}
          src={imageSrc}
          style={{
            width: image.width,
            height: image.height,
          }}
        />
      </button>
      {zones.map((zone, index) => (
        <Zone
          handleDelete={() => handleZoneDelete(index)}
          handleMouseMove={handleMouseMove}
          handleStartGrab={(event) => handleMouseDown(event, index, 'position')}
          handleStartResize={(event) => handleMouseDown(event, index, 'resize')}
          isActive={activeZoneIndex === index}
          key={`zone-${index}`}
          shapeType={zone.shapeType}
          sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
        />
      ))}
    </div>
  );
};

ImageContainer.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};

export default ImageContainer;
