import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from 'uikit/Collapse';

import styles from './Options.module.scss';

const cx = classNames.bind(styles);

const Options = ({ children, className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/Options',
  });

  return (
    <Collapse className={className}>
      <Collapse.Item className={cx('options')} title={t('more')}>
        {children}
      </Collapse.Item>
    </Collapse>
  );
};

Options.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Options;
