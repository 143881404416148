import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import CourseDelete from 'components/Shared/CourseDelete';
import CourseLeave from 'components/Shared/CourseLeave';
import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseStudy from 'components/Course/Shared/CourseStudy/CourseStudy';
import ExamStart from 'components/Course/Shared/ExamStart/ExamStart';
import PERMISSIONS from 'constants/permissions';
import { useQueryCourseContent } from 'gql/queries/course';
import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import Menu, { MenuItem } from 'uikit/Menu';

import CourseDuplicate from './CourseDuplicate/CourseDuplicate';
import CourseExport from './CourseExport/CourseExport';
import CourseIntroduction from './CourseIntroduction/CourseIntroduction';
import CourseShare from './CourseShare/CourseShare';
import CourseTheme from './CourseTheme/CourseTheme';
import CourseTitle from './CourseTitle/CourseTitle';
import imageMoodle from './images/moodle.png';
import Participants from './Participants/Participants';
import styles from './Cover.module.scss';

const cx = classNames.bind(styles);

const Cover = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover',
  });

  const { pathname } = useLocation();

  const { isGAR, isTNECanope, isInTeams, isRoleTeacher } = useSiteContext();

  const { courseId } = useParams();

  const { isLoading, data } = useQueryCourseContent({
    variables: {
      courseId,
    },
  });

  const activeTab = pathname.split('/')[3];

  const course = data?.course;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={cx('container')}>
      <CourseTheme course={course} />
      <div className={cx('main')}>
        <div className={cx('top')}>
          <div className={cx('header')}>
            <CourseTitle className={cx('title')} course={course} />
            <CourseIntroduction course={course} />
          </div>
          {course.isExam ? (
            <>
              <ExamStart
                className={cx('top-action')}
                course={course}
                isTeacher
              />
              {course.isExamReviewEnabled && (
                <Button
                  as={Link}
                  className={cx('top-action')}
                  size="large"
                  to={`/test-review/${course.id}`}
                >
                  {t('test-review')}
                </Button>
              )}
            </>
          ) : (
            <CourseStudy
              className={cx('top-action')}
              course={course}
              isEditionView={true}
            />
          )}
          {course.moodle.isMoodle && (
            <Button
              as="a"
              className={cx('top-action')}
              href={course.moodle.courseUrl}
              icon={
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('moodle')}
                  src={imageMoodle}
                />
              }
              intent="white"
              size="large"
              target="_top"
            >
              {t('moodle')}
            </Button>
          )}
          {!isTNECanope && (
            <CourseShare
              className={cx('top-action', 'share')}
              course={course}
            />
          )}
          <Menu
            className={cx('menu')}
            placement="bottom-end"
            toggle={
              <Button
                aria-label={t('options')}
                className={cx('options')}
                icon="ellipsis-v"
              />
            }
          >
            {!course.isExam && !isInTeams && !isTNECanope && (
              <MenuItem
                as={Link}
                icon="gamepad"
                to={`/course/${course.id}/games`}
              >
                {t('game')}
              </MenuItem>
            )}
            <CourseExport course={course} />
            {!isInTeams && <CourseDuplicate course={course} />}
            {course.level >= PERMISSIONS.OWNER ? (
              <>{!isInTeams && <CourseDelete course={course} />}</>
            ) : (
              <>{!isInTeams && <CourseLeave course={course} />}</>
            )}
          </Menu>
        </div>
        <div className={cx('scroll')}>
          <div className={cx('tabs')} role="tablist">
            <Link
              aria-selected={activeTab === ''}
              className={cx('tab', {
                isActive: activeTab === '',
              })}
              data-testid="content-tab"
              role="tab"
              to={`/course/${course.id}/`}
            >
              {t('content')}
            </Link>
            {course.isExam ? (
              <Link
                aria-selected={activeTab === 'test-results'}
                className={cx('tab', {
                  isActive: activeTab === 'test-results',
                })}
                role="tab"
                to={`/course/${course.id}/test-results`}
              >
                {t('test-results')}
              </Link>
            ) : (
              <Link
                aria-selected={activeTab === 'statistics'}
                className={cx('tab', {
                  isActive: activeTab === 'statistics',
                })}
                role="tab"
                to={`/course/${course.id}/statistics`}
              >
                {t('statistics')}
              </Link>
            )}
            <Link
              aria-selected={[
                'participants',
                'collaborators',
                'students',
              ].includes(activeTab)}
              className={cx('tab', {
                isActive: [
                  'participants',
                  'collaborators',
                  'students',
                ].includes(activeTab),
              })}
              role="tab"
              to={`/course/${course.id}/participants`}
            >
              {t(isGAR && !isRoleTeacher ? 'collaborators' : 'participants')}
            </Link>
            <Link
              aria-selected={activeTab === 'settings'}
              className={cx('tab', {
                isActive: activeTab === 'settings',
              })}
              data-testid="settings-tab"
              role="tab"
              to={`/course/${course.id}/settings`}
            >
              {t('settings')}
            </Link>
          </div>
          <div className={cx('filler')} />
          <Participants courseId={course?.id} />
        </div>
      </div>
    </div>
  );
};

export default Cover;
