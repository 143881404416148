import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import sanitize from 'lib/sanitize';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Recto.module.scss';
import * as yup from 'yup';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/MCQ/Recto',
  });

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  function handleChoiceToggle(idx) {
    if (!isDisabled) {
      setAnswer(
        answer.map((a) => ({
          idx: a.idx,
          answer:
            a.idx === idx
              ? !a.answer
              : question.content.multipleChoices
              ? a.answer
              : false,
        })),
      );
    }
  }

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>
          {t(
            question.content.multipleChoices
              ? 'mcq-instructions'
              : 'scq-instructions',
          )}
        </div>
      )}
      {question.content.choices.map((choice) => {
        const selection = answer.find((a) => a.idx === choice.idx);
        const icon = question.content.multipleChoices ? 'square' : 'circle';
        const choiceTitle = sanitize.string(choice.title);
        return (
          <button
            aria-pressed={selection?.answer}
            className={cx('choice', {
              isDisabled,
              isSelected: selection?.answer,
            })}
            data-testid={`mcq-choice-${choiceTitle}`}
            key={choice.idx}
            onClick={() => handleChoiceToggle(choice.idx)}
            type="button"
          >
            <Icon
              className={cx('icon')}
              name={selection?.answer ? `check-${icon}` : icon}
              variant="solid"
            />
            <FormattedText className={cx('choice-title')}>
              {choice.title}
            </FormattedText>
          </button>
        );
      })}
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: false,
  }));

Recto.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          answer: yup.boolean().required(),
        }),
      )
      .compact()
      .test(
        'answer',
        t('Questions/MCQ/Recto/choices-error-answer'),
        (choices) => choices.filter((choice) => choice.answer).length >= 1,
      ),
  });

export default Recto;
