import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import ImageContainer from './ImageContainer/ImageContainer';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/LabelOnImage/Recto',
  });

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  // Setup answer with already correct answers
  useEffect(() => {
    const updatedAnswer = answer.map((answerItem, index) => {
      const itemSolution = question.solution?.[index] ?? null;
      if (itemSolution?.isCorrect) {
        if (itemSolution.isForced) {
          return itemSolution.correct[0] || '';
        }
        return itemSolution.user;
      }
      return answerItem;
    });
    setAnswer(updatedAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const legends = question.content.legends.map((legend, index) => {
    const isCorrect = question.solution?.[index].isCorrect ?? false;
    return {
      ...legend,
      isCorrect,
    };
  });

  const isExistingAnsweredLegend = legends.some((legend) => legend.isCorrect);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <div className={cx('image-legends-container')}>
        <ImageContainer
          className={cx('image')}
          imageSrc={question.content.image}
          legends={legends}
          zones={question.content.zones}
        />
        <div
          className={cx('legends', {
            scroll: question.content.legends.length > 8,
          })}
        >
          {/* unanswered legends */}
          {legends.map((legend, index) => {
            if (!legend.isCorrect) {
              return (
                <div
                  className={cx('legend')}
                  key={`unAnsweredLegends-${index}`}
                >
                  <p className={cx('legend-index')}>{index + 1}</p>
                  <InputText
                    aria-label={t('legend', {
                      number: index + 1,
                    })}
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    className={cx('legend-input')}
                    name={`answer.${index}`}
                  />
                </div>
              );
            }
            return null;
          })}
          {/* already answered legends */}
          {isExistingAnsweredLegend && (
            <div className={cx('answeredLegends')}>
              {legends.map((legend, index) => {
                const legendAnswer = question.solution[index];
                if (legend.isCorrect) {
                  return (
                    <div
                      className={cx('legend')}
                      key={`answeredLegends-${index}`}
                    >
                      <div
                        className={cx('legend-index', 'answeredLegend-index')}
                      >
                        {index + 1}
                      </div>
                      <div className={cx('answeredLegend-answer')}>
                        {legendAnswer.isForced
                          ? legendAnswer.correct.join(',') || '___'
                          : legendAnswer.user}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  (question.content.legends || []).map(() => '');

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup.array().of(yup.string().trim()),
  });

export default Recto;
