import React from 'react';

import Recto from '../Recto/Recto'

const Verso = (props) => <Recto {...props} />

Verso.defaultAnswer = () => undefined;

Verso.defaultSolution = () => null;

export default Verso;
