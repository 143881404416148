import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';
import Icon from 'uikit/Icon';

import styles from './ExpertInstructions.module.scss';

const cx = classNames.bind(styles);

const ExpertInstructions = ({ className = null, questionType }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared',
  });
  const {t: tQuestions} = useTranslation();

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  return (
    <>
      <Button
        className={cx(className, 'instructions-icon')}
        icon="info-circle"
        onClick={handleModalOpen}
      />
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <div className={cx('header')}>
            <h1 className={cx('title')}>
              {tQuestions(`Questions/${questionType}/name`)}
            </h1>
            <Button
              aria-label={t('aria-close')}
              className={cx('close')}
              icon="times"
              onClick={handleModalClose}
            />
          </div>
          <div className={cx('information-container')}>
            <Icon className={cx('information-icon')} name="info-circle" />
            <p className={cx('information')}>
              {t('experts-instructions')}
            </p>
          </div>
          <p className={cx('explanation')}>
            {t('experts-helper')}
          </p>
        </div>
      </Modal>
    </>
  );
};

ExpertInstructions.propTypes = {
  className: PropTypes.string,
  questionType: PropTypes.string.isRequired,
};

export default ExpertInstructions;
