import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      {question.content.choices.map((choice) => {
        const solution = question.solution.find((c) => c.idx === choice.idx);
        return (
          <div key={choice.idx}>
            <div
              className={cx('choice', {
                isCorrect: solution?.isCorrect,
                isWrong: !solution?.isCorrect && solution?.user !== null,
              })}
            >
              <FormattedText className={cx('choice-title')}>
                {choice.title}
              </FormattedText>
              <div className={cx('checkbox')}>
                <div className={cx('buttons')}>
                  <div
                    className={cx('button', 'left', {
                      isCorrect: solution.correct === true,
                      isWrong:
                        solution.correct === false && solution.user === true,
                      isChecked: solution.user === true,
                    })}
                  >
                    <Icon name="check" />
                  </div>
                  <div
                    className={cx('button', 'right', {
                      isCorrect: solution.correct === false,
                      isWrong:
                        solution.correct === true && solution.user === false,
                      isChecked: solution.user === false,
                    })}
                  >
                    <Icon name="times" />
                  </div>
                </div>
              </div>
            </div>
            {(solution.feedback || choice.feedback) && (
              <div className={cx('feedback', 'choice-feedback')}>
                <div className={cx('feedback-icon')}>
                  <Icon name="info-circle" variant="duotone" />
                </div>
                <FormattedText>
                  {solution.feedback || choice.feedback}
                </FormattedText>
              </div>
            )}
          </div>
        );
      })}
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: false,
  }));

Verso.defaultSolution = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    user: null,
    correct: choice.answer,
    isCorrect: false,
    feedback: '',
  }));

export default Verso;
