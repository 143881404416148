/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric';
import { useField } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import useHotkeys from 'hooks/useHotkeys';

import './config/controllers';
import { clearHistory, saveHistory, undo } from './config/history';
import {
  copy,
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
  paste,
} from './config/hotkeys';
import resizeCanvas from './config/resizeCanvas';
import snapToGrid from './config/snapToGrid';
import PROPERTIES_TO_SAVE from './data/propertiesToSave';
import SlideBuilder from './SlideBuilder';

const SlideBuilderContainer = (props) => {
  const { t } = useTranslation();

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const [historyStep, setHistoryStep] = useState();
  const [activeMenu, setActiveMenu] = useState('template');

  const [, , { setValue: setTitle }] = useField('title');

  const canvasRef = useRef();

  const fabricRef = useRef();

  const [, , { setValue: setSlideContent }] =
    useField('slideContent');

  const updateQuestionContent = useCallback(() => {
    setSlideContent(
      JSON.stringify(fabricRef.current.toJSON(PROPERTIES_TO_SAVE)),
    );
    const firstTitle = fabricRef.current._objects.find(
      (object) => object.fontWeight === 900,
    );
    const firstSubtitle = fabricRef.current._objects.find(
      (object) => object.fontWeight === 700,
    );
    const firstText = fabricRef.current._objects.find(
      (object) => object.fontWeight === 400,
    );
    setTitle(
      firstTitle
        ? firstTitle.text
        : firstSubtitle
        ? firstSubtitle.text
        : firstText
        ? firstText.text
        : t('Questions/Slide2/name'),
    );
  }, [fabricRef, setSlideContent, setTitle, t]);

  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
    };
    const disposeFabric = () => {
      fabricRef.current.dispose();
    };
    initFabric();
    // needed to render properly latex svg
    fabric.Object.NUM_FRACTION_DIGITS = 5;
    // Default background color
    fabricRef.current.setBackgroundColor('#f5f6fa');
    // load existing slide
    fabricRef.current.loadFromJSON(questionEditing.content.slideContent);
    resizeCanvas(fabricRef, isMenuExpanded);
    saveHistory(fabricRef);
    return () => {
      disposeFabric();
      clearHistory();
    };
  }, [canvasRef, fabricRef, isMenuExpanded, questionEditing]);

  const handleToolbarChange = useCallback((menu) => {
    setActiveMenu(menu);
  }, []);
  const [selectedObject, setSelectedObject] = useState();
  const selectObjects = useCallback((event) => {
    setSelectedObject(event.selected[0]);
    if (event.selected[0].type === 'i-text') {
      setActiveMenu('text');
    }
  }, []);

  const deselectObjects = useCallback(() => {
    setSelectedObject();
  }, []);

  // Link actions to canvas events
  useEffect(() => {
    fabricRef?.current?.on({
      'after:render': updateQuestionContent,
      'selection:created': selectObjects,
      'mouse:down': () =>
        setHistoryStep(
          JSON.stringify(fabricRef.current.toJSON(PROPERTIES_TO_SAVE)),
        ),
      'selection:updated': selectObjects,
      'selection:cleared': deselectObjects,
      'object:moving': snapToGrid,
      'object:modified': () => saveHistory(fabricRef, historyStep),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fabricRef, historyStep, questionEditing]);

  useEffect(() => {
    window.addEventListener('resize', () =>
      resizeCanvas(fabricRef, isMenuExpanded),
    );
    return () =>
      window.removeEventListener('resize', () =>
        resizeCanvas(fabricRef, isMenuExpanded),
      );
  }, [isMenuExpanded]);

  useHotkeys('ctrl+c', () => copy(fabricRef));
  useHotkeys('cmd+c', () => copy(fabricRef));
  useHotkeys('ctrl+v', () => paste(fabricRef));
  useHotkeys('cmd+v', () => paste(fabricRef));
  useHotkeys('ctrl+z', () => undo(fabricRef));
  useHotkeys('cmd+z', () => undo(fabricRef));
  useHotkeys('backspace', () => {
    fabricRef.current.remove(fabricRef.current.getActiveObject());
    fabricRef.current.requestRenderAll();
  });
  useHotkeys('delete', () => {
    fabricRef.current.remove(fabricRef.current.getActiveObject());
    fabricRef.current.requestRenderAll();
  });
  useHotkeys('up', () => moveUp(fabricRef));
  useHotkeys('down', () => moveDown(fabricRef));
  useHotkeys('left', () => moveLeft(fabricRef));
  useHotkeys('right', () => moveRight(fabricRef));
  return (
    <SlideBuilder
      activeMenu={activeMenu}
      canvasRef={canvasRef}
      fabricRef={fabricRef}
      handleToolbarChange={handleToolbarChange}
      isDisplayedInColumn={isMenuExpanded}
      selectedObject={selectedObject}
      {...props}
    />
  );
};

export default SlideBuilderContainer;
