import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Button from 'uikit/Button';

import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const Modal = ({
  action = null,
  children,
  className = null,
  error = '',
  image = null,
  isOpen = false,
  isTitleCentered = false,
  onExit = null,
  size = 'md',
  subtitle = '',
  title = '',
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'uikit/Modal',
  });

  const dialogRef = useRef(null);

  const modalId = `modal-${Number(new Date())}`;

  useEffect(() => {
    const dialog = dialogRef.current;
    dialog?.showModal();
    return () => {
      dialog?.close();
    };
  }, [dialogRef, isOpen]);

  // Handle keydown event
  useEffect(() => {
    function handleKey(event) {
      if (isOpen && event.key === 'Escape') {
        onExit?.(event);
      }
    }

    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [isOpen, modalId, onExit]);

  function onOverlayClickHandler(clickEvent) {
    clickEvent?.stopPropagation();

    // we check if the click happened on the dialog itself, not on one of its children, which means that the user clicked on the backdrop.
    if (clickEvent.target === dialogRef.current) {
      return onExit?.(clickEvent);
    }
  }

  return createPortal(
    <CSSTransition
      classNames={{
        enter: cx('enter'),
        enterActive: cx('enter-active'),
        exit: cx('exit'),
        exitActive: cx('exit-active'),
      }}
      in={isOpen}
      mountOnEnter
      nodeRef={dialogRef}
      timeout={250}
      unmountOnExit
    >
      <dialog
        aria-labelledby="modal-title"
        aria-modal="true"
        className={cx(className, 'modal', `size-${size}`)}
        id={modalId}
        onClick={onOverlayClickHandler}
        ref={dialogRef}
      >
        <div className={cx('header')}>
          {title && (
            <h1
              className={cx('title', { centered: isTitleCentered })}
              id="modal-title"
            >
              {title}
            </h1>
          )}
          <Button aria-label={t('aria-close')} icon="times" onClick={onExit} />
        </div>
        {image && (
          <img alt="" aria-hidden="true" className={cx('image')} src={image} />
        )}
        {subtitle && <h2 className={cx('subtitle')}>{subtitle}</h2>}
        {error && (
          <p className={cx('error')} role="alert">
            {error}
          </p>
        )}
        {children}
        {action && <div className={cx('action')}>{action}</div>}
      </dialog>
    </CSSTransition>,
    document.body,
  );
};

Modal.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeTimeoutMS: PropTypes.number,
  error: PropTypes.string,
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  isTitleCentered: PropTypes.bool,
  onExit: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Modal;
