import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryResultsStudyWrongAnswers } from 'gql/queries/results';
import { reportError } from 'lib/tracking';
import { getQuestionVerso } from 'questions';
import Button from 'uikit/Button';
import SubPage from 'uikit/SubPageLegacy';

import styles from './StudentErrors.module.scss';

const cx = classNames.bind(styles);

const LIMIT = 5;

const StudentErrors = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/StudentErrors',
  });

  const {
    data: {
      editor: { status: editorStatus, questionEditing },
    },
  } = useQueryLocalEditor();

  const isVisible = editorStatus === EDITOR_STATUSES.STUDENT_ERRORS;

  const { isLoading, data, fetchMore } = useQueryResultsStudyWrongAnswers({
    variables: {
      questionId: questionEditing?.id,
      offset: 0,
      limit: LIMIT,
    },
    skip: !isVisible,
  });

  const [currentAnswer, setCurrentAnswer] = useState(0);

  useEffect(() => {
    setCurrentAnswer(0);
    return () => {
      setCurrentAnswer(0);
    };
  }, [questionEditing]);

  function handleAnswerPrevious() {
    setCurrentAnswer(Math.max(currentAnswer - 1, 0));
  }

  async function handleAnswerNext() {
    try {
      if (currentAnswer === answers.length - 2) {
        await fetchMore({
          variables: {
            offset: answers.length,
          },
        });
      }
    } catch (err) {
      reportError('studyAnswers', 'load-more', err);
    } finally {
      setCurrentAnswer(Math.min(currentAnswer + 1, answers.length - 1));
    }
  }

  const answers = isLoading ? [] : data.resultsStudyWrongAnswers;

  const question = !answers[currentAnswer]
    ? questionEditing
    : {
        ...questionEditing,
        ...answers[currentAnswer],
      };

  const VersoQuestion = getQuestionVerso(questionEditing?.type);

  return (
    <SubPage
      isOpen={isVisible}
      onExit={mutationEditorClear}
      subtitle={course.title}
      title={t('title')}
    >
      <div className={cx('subpage')}>
        <div className={cx('buttons')}>
          <Button
            icon="chevron-left"
            intent="white"
            isDisabled={currentAnswer <= 0}
            onClick={handleAnswerPrevious}
            size="large"
            tooltip={t('previous-answer')}
          />
          <Button
            icon="chevron-right"
            intent="white"
            isDisabled={currentAnswer >= answers.length - 1}
            onClick={handleAnswerNext}
            size="large"
            tooltip={t('next-answer')}
          />
        </div>
        {question && question.result ? (
          <>
            <p className={cx('explanation-preview')}>
              {t('explanation-preview')}
            </p>
            <VersoQuestion isDisabled question={question} />
          </>
        ) : (
          <p className={cx('no-wrong-answer')}>{t('no-wrong-answer')}</p>
        )}
      </div>
    </SubPage>
  );
};

StudentErrors.propTypes = {
  course: PropTypes.object.isRequired,
};

export default StudentErrors;
