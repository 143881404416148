import t from 'helpers/t';

import Editor from './Editor/Editor';
import Recto from './Recto/Recto';
import Verso from './Verso/Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const Slide = {
  info: {
    type: 'Slide',
    name: t('Questions/Slide/name'),
    tooltip: t('Questions/Slide/tooltip'),
    icon: svg,
    picture,
    color: 'pink',
    isCorrectable: false,
    isOneSided: true,
    isInScore: false,
    isReportable: false,
    study: true,
    play: false,
    test: false,
    isNew: false,
    canFullForceCorrect: false,
    canPartialForceCorrect: false,
  },
  Editor,
  Recto,
  Verso,
};

export default Slide;
