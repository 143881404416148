import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { useQueryLocalStudy } from 'gql/queries/local';
import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';

import ImageContainer from '../Shared/ImageContainer/ImageContainer';
import Answer from './Answer/Answer';
import PartialForceCorrect from './PartialForceCorrect/PartialForceCorrect';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { theme } = useContext(Context);

  const {
    data: {
      study: { questions },
    },
  } = useQueryLocalStudy();

  const previousAnswers = question.isRepeated
    ? questions.find((q) => q.id === question.id).solution
    : [];

  const answers = question.solution.map((answer, index) => ({
    ...answer,
    isForced: previousAnswers[index]?.isForced ?? false,
  }));

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('image-legends-container')}>
        <ImageContainer
          className={cx('image')}
          imageSrc={question.content.image}
          legends={question.content.legends}
          solution={answers}
          zones={question.content.zones}
        />
        <div className={cx('answers')}>
          {answers.map((answer, index) => (
            <Answer answer={answer} index={index} isForcingCorrect={false} />
          ))}
        </div>
      </div>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
      <PartialForceCorrect question={question} />
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = (question) =>
  (question.content.legends || []).map(() => '');

Verso.defaultSolution = (question) =>
  question.content.legends.map((blank) => ({
    user: null,
    correct: blank.content,
    isCorrect: false,
  }));

export default Verso;
