import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/ModalLegacy';

import { formatMatches } from '../utils';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled = false, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Matching/Recto',
  });

  const { theme } = useContext(Context);

  const [selectedSourceId, setSelectedSourceId] = useState(-1);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  function handleSourceRemove(idx) {
    if (!isDisabled) {
      setAnswer(
        answer.map((destinationIdx, sourceId) =>
          sourceId === idx ? -1 : destinationIdx,
        ),
      );
    }
  }

  function handleSourceSelect(idx) {
    if (!isDisabled) {
      setSelectedSourceId(idx);
    }
  }

  function handleDestinationSelect(idx) {
    setAnswer(
      answer.map((destinationIdx, sourceId) =>
        sourceId === selectedSourceId
          ? idx
          : destinationIdx === idx
          ? -1
          : destinationIdx,
      ),
    );
    setSelectedSourceId(-1);
  }

  const matches = formatMatches(question.content.matches);

  const selectedSource =
    selectedSourceId === -1 ? undefined : matches[selectedSourceId];

  return (
    <>
      <div className={cx('container', className, `theme-${theme}`)}>
        <FormattedText as="h1" className={cx('title')}>
          {question.title}
        </FormattedText>
        {!isDisabled && (
          <div className={cx('instructions')}>{t('instructions')}</div>
        )}
        {matches.map((match, sourceId) => {
          const matched = matches.find((m) => m.idx === answer[sourceId]);
          return (
            <div
              className={cx('match', {
                isDisabled,
              })}
              key={match.idx}
            >
              <button
                className={cx('inner')}
                onClick={() => handleSourceSelect(sourceId)}
                type="button"
              >
                <FormattedText className={cx('match-title')}>
                  {match.source}
                </FormattedText>
                {matched ? (
                  <FormattedText className={cx('match-destination')}>
                    {matched.destination}
                  </FormattedText>
                ) : (
                  <p>{t('match')}</p>
                )}
              </button>
              {matched && (
                <button
                  aria-label={t('aria-remove-matching')}
                  className={cx('remove')}
                  onClick={() => handleSourceRemove(sourceId)}
                  type="button"
                >
                  <Icon name="times" />
                </button>
              )}
            </div>
          );
        })}
      </div>
      <Modal isOpen={!!selectedSource} onExit={() => handleSourceSelect(-1)}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={() => handleSourceSelect(-1)}
          />
          <FormattedText className={cx('modal-title')}>
            {selectedSource?.source}
          </FormattedText>
          {matches.map((match) => (
            <button
              className={cx('match', 'inner', {
                isSelected: answer.includes(match.idx),
              })}
              key={match.idx}
              onClick={() => handleDestinationSelect(match.idx)}
            >
              <FormattedText tabIndex={-1}>{match.destination}</FormattedText>
            </button>
          ))}
        </div>
      </Modal>
    </>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) => question.content.matches.map(() => -1);

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(yup.number().required().integer())
      .test('atLeastOne', (arr) => arr.some((v) => v !== -1)),
  });

export default Recto;
