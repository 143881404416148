import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Dictation/Verso',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('answer')}>
        <p className={cx('label')}>{t('correct-answer')}</p>
        <pre className={cx('correct')}>{question.solution.correct}</pre>
        {question.result === 'wrong' && (
          <>
            <p className={cx('label')}>{t('correction')}</p>
            <pre>
              {question.solution.correction.map((change, changeIndex) => (
                <span
                  className={cx({
                    isAdded: change.added,
                    isRemoved: change.removed,
                  })}
                  key={`${question.id}-change${changeIndex}`}
                >
                  {change.value}
                </span>
              ))}
            </pre>
          </>
        )}
      </div>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => '';

Verso.defaultSolution = (question) => ({
  user: null,
  correct: question.content.answer,
  correction: [
    {
      count: 1,
      added: true,
      removed: undefined,
      value: question.content.answer,
    },
  ],
});

export default Verso;
