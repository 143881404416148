import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/Modal/Modal';

const CourseExport = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseExport',
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  
  return (
    <>
      <MenuItem icon="file-export" onClick={handleModalOpen}>
        {t('export-course')}
      </MenuItem>
         
      <Modal
        action={
          <Button
            as="a"
            href={`${process.env.REACT_APP_API_URL}/rest/courses/${
              course.id
            }/export-excel?authToken=${storeJS.get('token')}`}
            icon="file-excel"
            target="blank"
            size="large"
          >
            {t('export-excel')}
          </Button>
        }
        isOpen={isModalOpen}
        isTitleCentered={true}
        onExit={handleModalClose}
        title={t('title')}
      />
    </>
  );
};

CourseExport.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseExport;
