import * as dayjs from 'dayjs';
import classNames from 'classnames/bind';
import PERMISSIONS from 'constants/permissions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCourseParticipants } from 'gql/queries/course';
import Avatar from 'uikit/Avatar';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu from 'uikit/Menu';

import styles from './Participants.module.scss';

const cx = classNames.bind(styles);

const Participants = ({ courseId }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Participants',
  });

  const { data } = useQueryCourseParticipants({
    variables: {
      courseId,
    },
  });

  const [search, setSearch] = useState('');

  const handleSearchChange = (evt) => {
    setSearch(evt.target.value);
  };

  const filteredParticipants = data
    ? data.course.participants.filter(
        (participant) =>
          !search ||
          `${participant.firstName.toLowerCase()} ${participant.lastName.toLowerCase()}`.includes(
            search.trim().toLowerCase(),
          ),
      )
    : [];

  const participants = data?.course.participants || [];

  return (
    participants.length > 0 && (
      <div className={cx('participants')}>
        <div className={cx('participants-highlight')}>
          {participants.map(
            (participant, index) =>
              index < 3 && (
                <div className={cx('avatar-container')} key={`avatar-${index}`}>
                  <Avatar
                    isTeacher={participant.level >= PERMISSIONS.COLLABORATOR}
                    profile={participant}
                    useInitial
                    size={36}
                  />
                </div>
              ),
          )}
        </div>
        <Menu
          isHideOnClickDeactivated
          toggle={
            <Button
              className={cx('participants-number')}
              tooltip={t('tooltip')}
            >
              {participants.length > 3 ? (
                `+${participants.length - 3}`
              ) : (
                <Icon name="eye" />
              )}
            </Button>
          }
        >
          <div className={cx('list-container')}>
            <h1 className={cx('list-title')}>{t('title')}</h1>
            <div className={cx('search')}>
              <Icon name="search" />
              <input
                aria-label={t('aria-search')}
                className={cx('search-input')}
                onChange={handleSearchChange}
                placeholder={t('search-placeholder')}
                size="15"
                type="text"
                value={search}
              />
            </div>
            <div className={cx('participants-list')}>
              {filteredParticipants.map((participant, index) => {
                const lastConnection = dayjs(participant.lastConnection);
                const now = dayjs(new Date());
                const years = now.diff(lastConnection, 'year');
                const months = now.diff(lastConnection, 'month');
                const days = now.diff(lastConnection, 'day');
                const hours = now.diff(lastConnection, 'hour');
                const minutes = now.diff(lastConnection, 'minute');
                return (
                  <div
                    className={cx('participant')}
                    key={`participant-${index}`}
                  >
                    <div
                      className={cx('status', {
                        isActive: participant.isActive,
                      })}
                    />
                    <Avatar
                      isTeacher={participant.level >= PERMISSIONS.COLLABORATOR}
                      profile={participant}
                      useInitial
                    />
                    <div className={cx('information')}>
                      <p className={cx('name')}>
                        {`${participant.firstName} ${participant.lastName[0]}.`}
                      </p>
                      {participant.lastConnection && (
                        <p className={cx('last-connection')}>
                          {years > 0
                            ? t('years', {
                                count: years,
                              })
                            : months > 0
                            ? t('months', {
                                count: months,
                              })
                            : days > 0
                            ? t('days', {
                                count: days,
                              })
                            : hours > 0
                            ? t('hours', {
                                count: hours,
                              })
                            : t('minutes', {
                                count: minutes,
                              })}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Menu>
      </div>
    )
  );
};

Participants.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default Participants;
