import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import Button from 'uikit/Button';

import styles from './SubPage.module.scss';

const cx = classNames.bind(styles);

const SubPage = ({
  className,
  children,
  header,
  headerSize,
  isHeaderHidden,
  isInTeams,
  isMenuExpanded,
  isOnboarding,
  isOpen,
  isSidePanel,
  onExit,
  subtitle,
  title,
  t,
}) => (
  <ReactModal
    ariaHideApp={false}
    className={cx('modal', {
      'teams-view': isInTeams,
      'collapsed-menu': !isMenuExpanded,
      'side-panel': isSidePanel,
      'large-side-panel': !isMenuExpanded,
    })}
    closeTimeoutMS={500}
    isOpen={isOpen}
    onRequestClose={onExit}
    overlayClassName={cx('overlay', className, {
      'teams-view': isInTeams,
      'collapsed-menu': !isMenuExpanded,
      'side-panel-overlay': isSidePanel,
    })}
  >
    {!isSidePanel && (
      <div
        className={cx('header', `header-size-${headerSize}`, {
          'no-header': isHeaderHidden,
        })}
      >
        {!isOnboarding && (
          <>
            <Button
              className={cx('back')}
              intent="white"
              icon="arrow-left"
              onClick={onExit}
              size={headerSize}
              tooltip={t('uikit/SubPage/back')}
            />
            <div>
              {!title && !subtitle && header}
              {title && <h3 className={cx('title')}>{title}</h3>}
              {subtitle && <h4 className={cx('subtitle')}>{subtitle}</h4>}
            </div>
          </>
        )}
      </div>
    )}
    {children}
  </ReactModal>
);

SubPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  headerSize: PropTypes.string,
  isHeaderHidden: PropTypes.bool,
  isInTeams: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  isOnboarding: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSidePanel: PropTypes.bool,
  onExit: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
};

SubPage.defaultProps = {
  className: undefined,
  children: undefined,
  header: undefined,
  headerSize: 'large',
  isHeaderHidden: false,
  isOnboarding: false,
  isOpen: false,
  isSidePanel: false,
  onExit: undefined,
  subtitle: undefined,
  title: undefined,
};

export default SubPage;
