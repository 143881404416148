import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputImage from 'uikit/InputImage';
import InputRich from 'uikit/InputRich';
import InputTextArray from 'uikit/InputTextArray';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import ImageContainer from './ImageContainer/ImageContainer';
import styles from './Editor.module.scss';
import * as yup from 'yup';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/LabelOnImage/Editor',
  });

  const [{ value: image }, , { setValue: setImage }] = useField('image');
  const [{ value: zones }, , { setValue: setZones }] = useField('zones');
  const [{ value: legends }, , { setValue: setLegends }] = useField('legends');

  function handleImageDelete() {
    setImage(null);
  }

  function handleLegendAdd() {
    const newLegend = {
      x: 0.06,
      y: 0.06,
      content: [],
    };
    setLegends([...legends, newLegend]);
  }

  function handleLegendDelete(index) {
    const filteredlegends = legends.filter(
      (item, itemIndex) => itemIndex !== index,
    );
    setLegends(filteredlegends);
  }

  function handleZoneAdd() {
    const newZone = {
      h: 0.1,
      r: null,
      w: 0.1,
      x: 0.01,
      y: 0.01,
      shapeType: 'rectangle',
    };
    setZones([...zones, newZone]);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('image')}</p>
      {image ? (
        <>
          <InputError name="legends" />
          <div className={cx('toolbar')}>
            <Button
              className={cx('action')}
              icon="plus"
              onClick={handleLegendAdd}
            >
              {t('add-legend')}
            </Button>
            <Button
              className={cx('action')}
              icon="plus"
              onClick={handleZoneAdd}
            >
              {t('add-zone')}
            </Button>
            <Button
              className={cx('action')}
              icon="trash-alt"
              onClick={handleImageDelete}
              tooltip={t('delete-image')}
              variant="regular"
            />
          </div>
          <div className={cx('image-legends-container')}>
            <ImageContainer
              className={cx('image')}
              imageSrc={image}
              legends={legends}
              onLegendsSet={setLegends}
              onZonesSet={setZones}
              zones={zones}
            />
            <div className={cx('legends')}>
              {legends.map((legend, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={cx('legend')} key={index}>
                  <p className={cx('legend-index')}>{index + 1}</p>
                  <InputTextArray
                    autoFocus
                    className={cx('legend-input')}
                    name={`legends.${index}.content`}
                    options={[]}
                  />
                  <Button
                    icon="trash-alt"
                    onClick={() => handleLegendDelete(index)}
                    tooltip={t('delete-label')}
                    variant="regular"
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <InputImage className={cx('field', 'image-input')} name="image" />
      )}
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('ignore-case-label')}
          name="ignoreCase"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-accents-label')}
          name="ignoreAccents"
        />
        <Switch
          className={cx('field')}
          label={t('ignore-punctuation-label')}
          name="ignorePunctuation"
        />
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};


Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/LabelOnImage/Editor/default-title')),
    image: yup
      .string()
      .trim()
      .required(t('Questions/LabelOnImage/Editor/image-error-required'))
      .url(t('Questions/LabelOnImage/Editor/image-error-required')),
    zones: yup.array().of(
      yup.object().shape({
        shapeType: yup.string().trim().required().oneOf(['rectangle']),
        x: yup.number().required(),
        y: yup.number().required(),
        w: yup.number().nullable(),
        h: yup.number().nullable(),
        r: yup.number().nullable(),
      }),
    ),
    legends: yup
      .array()
      .of(
        yup.object().shape({
          x: yup.number().required(),
          y: yup.number().required(),
          content: yup
            .array()
            .of(yup.string().trim().required())
            .test(
              'length',
              t('Questions/LabelOnImage/Editor/answer-error-required'),
              (content) => content.length >= 1,
            ),
        }),
      )
      .test(
        'length',
        t('Questions/LabelOnImage/Editor/legends-error-required'),
        (legends) => legends.length >= 1,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default Editor;
