import t from 'helpers/t';

import Aggregate from './Aggregate/Aggregate';
import Editor from './Editor/Editor';
import Recto from './Recto/Recto';
import Verso from './Verso/Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const ShortAnswer = {
  info: {
    type: 'ShortAnswer',
    name: t('Questions/ShortAnswer/name'),
    tooltip: t('Questions/ShortAnswer/tooltip'),
    icon: svg,
    picture,
    color: 'orange',
    isCorrectable: true,
    isOneSided: false,
    isInScore: true,
    isReportable: true,
    study: true,
    play: true,
    test: true,
    isNew: false,
    canFullForceCorrect: true,
    canPartialForceCorrect: false,
  },
  Aggregate,
  Editor,
  Recto,
  Verso,
};

export default ShortAnswer;
