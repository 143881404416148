import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';
import Modal from 'uikit/ModalLegacy';

import styles from './ExpertOpinionEditor.module.scss';

const cx = classNames.bind(styles);

const ExpertOpinionEditor = ({
  className = null,
  columnIndex,
  questionType,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/ExpertOpinionEditor',
  });
  const { t: tQuestions } = useTranslation();

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  const [{ value: labels }] = useField('labels');
  const [{ value: expertFeedbacks }, , { setValue: setExpertFeedbacks }] =
    useField('expertFeedbacks');

  const [editingLabel, setEditingLabel] = useState(undefined);

  function handleFeedbackAdd() {
    setExpertFeedbacks(
      expertFeedbacks.map((columnFeedbacks, index) =>
        index === columnIndex
          ? [
              ...columnFeedbacks,
              {
                id: undefined,
                firstName: undefined,
                lastName: undefined,
                feedback: '',
              },
            ]
          : columnFeedbacks,
      ),
    );
  }

  function handleFeedbackComplete(model) {
    if (model) {
      setExpertFeedbacks(
        expertFeedbacks.map((columnFeedbacks, index) =>
          index === columnIndex
            ? [
                ...columnFeedbacks,
                {
                  id: undefined,
                  firstName: undefined,
                  lastName: undefined,
                  feedback: model.nativeEvent.data,
                },
              ]
            : columnFeedbacks,
        ),
      );
    }
  }

  function handleFeedbackRemove(feedbackIndex) {
    const newFeedbacks = expertFeedbacks.map((columnFeedbacks, index) =>
      index === columnIndex
        ? columnFeedbacks
            .map((fb, i) =>
              i === feedbackIndex ? { ...fb, feedback: '' } : fb,
            )
            .filter((fb) => fb.id || fb.feedback)
        : columnFeedbacks,
    );
    setExpertFeedbacks(newFeedbacks);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      setEditingLabel(null);
    }
  }

  function handleLabelDone() {
    setEditingLabel(null);
  }

  const feedbacks = expertFeedbacks[columnIndex];

  return (
    <div className={cx('expert-container')}>
      {editingLabel === columnIndex ? (
        <InputText
          autoFocus
          className={cx('label')}
          key={columnIndex}
          name={`labels.${columnIndex}`}
          onBlur={handleLabelDone}
          onKeyDown={handleKeyDown}
          placeholder={tQuestions(
            `Questions/${questionType}/experts-${columnIndex}-label`,
          )}
        />
      ) : (
        <button
          className={cx('label')}
          key={columnIndex}
          onClick={() => setEditingLabel(columnIndex)}
          type="button"
        >
          <label htmlFor={`field-${columnIndex}`}>
            {labels[columnIndex] ||
              tQuestions(
                `Questions/${questionType}/experts-${columnIndex}-label`,
              )}
            <Icon className={cx('edit')} name="pencil" />
          </label>
        </button>
      )}
      <InputText
        className={cx('field', 'expert')}
        data-testid={`experts.${columnIndex}`}
        id={`field-${columnIndex}`}
        key={columnIndex}
        min="0"
        name={`experts.${columnIndex}`}
        shouldHideError
        type="number"
        variant="large-number"
      />
      <Button
        className={cx(className, 'button', { primary: feedbacks.length > 0 })}
        onClick={handleModalOpen}
      >
        <span>
          {t('add-feedbacks', {
            count: feedbacks.length,
          })}
        </span>
      </Button>
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal', 'container')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title')}>
            <span> {t('title')}</span>
            {labels[columnIndex] ||
              tQuestions(
                `Questions/${questionType}/experts-${columnIndex}-label`,
              )}
          </h1>
          {feedbacks.map((feedback, index) => (
            //  eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <div className={cx('list-element')}>
                {index === feedbacks.length ? (
                  <InputText
                    className={cx('Feedback-complete')}
                    name="tmp"
                    onChange={handleFeedbackComplete}
                  />
                ) : (
                  <InputText
                    className={cx('fill')}
                    label={
                      feedback.id
                        ? `${feedback.firstName} ${feedback.lastName}`
                        : t('you')
                    }
                    name={`expertFeedbacks.${columnIndex}.${index}.feedback`}
                    shouldHideError
                  />
                )}
                {index !== feedbacks.length && (
                  <Button
                    className={cx('delete')}
                    icon="trash-alt"
                    onClick={() => handleFeedbackRemove(index)}
                    tooltip={t('delete-feedback')}
                    variant="regular"
                  />
                )}
              </div>
            </div>
          ))}
          <Button
            className={cx('add', { large: feedbacks.length })}
            icon="plus"
            intent="primary"
            onClick={handleFeedbackAdd}
          >
            {!feedbacks.length && t('add-comment')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ExpertOpinionEditor.propTypes = {
  className: PropTypes.string,
  columnIndex: PropTypes.number.isRequired,
  questionType: PropTypes.string.isRequired,
};

export default ExpertOpinionEditor;
