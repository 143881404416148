import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseDuplicate } from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/Modal/Modal';
import { notify } from 'uikit/Notifications';

const CourseDuplicate = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseDuplicate',
  });

  const mutationCourseDuplicate = useMutationCourseDuplicate();

  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  
  async function handleCourseDuplicate() {
    try {
      setStatus(undefined);
      setLoading(true);
      const {
        data: { courseDuplicate },
      } = await mutationCourseDuplicate({
        course,
        prefix: t('copy-of'),
      });
      handleModalClose();
      notify('success', t('course-duplicate-success'));
      history.push(`/course/${courseDuplicate.id}/`);
      trackEvent('course', 'duplicate');
    } catch (err) {
      setStatus('500');
      reportError('course', 'duplicate', err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <MenuItem icon="copy" onClick={handleModalOpen}>
        {t('duplicate-course')}
      </MenuItem>
         
      <Modal
        action={
          <Button
            intent="primary"
            isLoading={isLoading}
            onClick={handleCourseDuplicate}
            size="large"
          >
            {t('confirm-duplicate-course')}
          </Button>
        }
        error={status && t('course-duplicate-error')}
        isOpen={isModalOpen}
        isTitleCentered={true}
        onExit={handleModalClose}
        subtitle={t('subtitle')}
        title={t('title')}
      />
    </>
  );
};

CourseDuplicate.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseDuplicate;
