import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GamePodium from 'components/Shared/GamePodium';
import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';

import STAT_ICON from './images/stat_icon.svg';
import styles from './GameLeaderboard.module.scss';

const cx = classNames.bind(styles);

const GameLeaderboard = ({ className = null, game }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games/GameLeaderboard',
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  const hasBeenPlayed = game.playStats.of > 0;

  return (
    <>
      <Button
        className={cx(className, { isDisabled: !hasBeenPlayed })}
        icon={<img alt="" src={STAT_ICON} />}
        onClick={hasBeenPlayed ? handleModalOpen : () => null}
        tooltip={t(`${!hasBeenPlayed ? 'no-' : ''}leaderboard`)}
      />
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title')}>{t('title')}</h1>
          <GamePodium className={cx('podium')} game={game} />
        </div>
      </Modal>
    </>
  );
};

GameLeaderboard.propTypes = {
  className: PropTypes.string,
  game: PropTypes.object.isRequired,
};

export default GameLeaderboard;
