import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Essay/Recto',
  });

  return (
    <div className={cx('container', className)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('answer')}>
        <p className={cx('label')}>{t('student-answer')}</p>
        <p className={cx('space')}>
          <FormattedText>{question.solution.user || '___'}</FormattedText>
        </p>
        {question.content.feedback && (
          <Feedback feedback={question.content.feedback} />
        )}
      </div>
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => '';

Verso.defaultSolution = () => ({
  user: null,
});

export default Verso;
