import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';
import * as yup from 'yup';

import CATEGORIES from 'constants/categories';
import { HIGHER_CLASSES, LOWER_CLASSES } from 'constants/classes';
import DESTINATIONS from 'constants/destinations';
import LANGUAGES from 'constants/languages';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import { useMutationTagsCreate } from 'gql/mutations/tag';
import { useQueryMe } from 'gql/queries/me';
import { useQueryTags } from 'gql/queries/tag';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import InputText from 'uikit/InputText';
import InputTextArray from 'uikit/InputTextArray';
import { notify } from 'uikit/Notifications';
import Select from 'uikit/Select';
import Switch from 'uikit/Switch';

import styles from './SharePublic.module.scss';

const cx = classNames.bind(styles);

const SharePublic = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare/SharePublic',
  });

  const { t: tSelect } = useTranslation();

  const mutationCourseUpdate = useMutationCourseUpdate();

  const mutationTagsCreate = useMutationTagsCreate();

  const { data } = useQueryTags();

  const { data: dataMe } = useQueryMe();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        isPublic: yup.boolean(),
        category: yup
          .string()
          .required()
          .oneOf(CATEGORIES.map((c) => c.key))
          .trim(),
        language: yup
          .string()
          .required()
          .oneOf(LANGUAGES.map((c) => c.key))
          .trim(),
        institution: yup.string().nullable().trim(),
        destination: yup
          .string()
          .required()
          .oneOf(DESTINATIONS.map((c) => c.key))
          .trim(),
        classes: yup
          .array()
          .of(
            yup
              .string()
              .nullable()
              .oneOf([
                ...HIGHER_CLASSES.map((c) => c.key),
                ...LOWER_CLASSES.map((c) => c.key),
              ])
              .trim(),
          )
          .required(),
        tags: yup
          .array()
          .of(yup.string().trim().lowercase().max(256))
          .compact(),
      }),
    [],
  );

  const initialValues = useMemo(
    () => ({
      isPublic: course.isPublic,
      category:
        course.category ||
        storeJS.get('publicCourseShare')?.category ||
        CATEGORIES[0].key,
      language:
        course.language ||
        storeJS.get('publicCourseShare')?.language ||
        LANGUAGES[0].key,
      institution:
        course.institution ||
        dataMe?.me.organization?.name ||
        dataMe?.me.institution ||
        storeJS.get('publicCourseShare')?.institution ||
        '',
      destination:
        course.destination ||
        storeJS.get('publicCourseShare')?.destination ||
        DESTINATIONS[0].key,
      classes: Object.fromEntries([
        ...HIGHER_CLASSES.map((higherClass) => [
          higherClass.key,
          course.classes.length
            ? course.classes.includes(higherClass.key)
            : storeJS.get('publicCourseShare')
            ? storeJS.get('publicCourseShare').classes.includes(higherClass.key)
            : false,
        ]),
        ...LOWER_CLASSES.map((lowerClass) => [
          lowerClass.key,
          course.classes.length
            ? course.classes.includes(lowerClass.key)
            : storeJS.get('publicCourseShare')
            ? storeJS.get('publicCourseShare').classes.includes(lowerClass.key)
            : false,
        ]),
      ]),
      tags: course.tags.map((tag) => tag.title) || [],
    }),
    [course, dataMe],
  );

  const tags = data ? data.tags.map((tag) => tag.title) : [];

  const categories = CATEGORIES.map((category) => ({
    key: category.key,
    translation: tSelect(category.translation),
  })).sort((a, b) =>
    a.key === 'other' ? 1 : a.translation.localeCompare(b.translation),
  );

  const handleSubmit = async (values) => {
    try {
      const isPublicCoursePageUpdated = course.isPublic || values.isPublic;
      const classes = Object.entries(values.classes)
        .filter(([key]) =>
          values.destination === 'lower-education'
            ? LOWER_CLASSES.some((lowerClass) => lowerClass.key === key)
            : values.destination === 'higher-education'
            ? HIGHER_CLASSES.some((higherClass) => higherClass.key === key)
            : false,
        )
        .filter(([, v]) => v)
        .map(([key]) => key);
      const variables = extract({ ...values, classes }, validationSchema);
      const {
        data: { tagsCreate },
      } = await mutationTagsCreate({ tags: variables.tags });
      const courseTagIds = tagsCreate.map((tag) => tag.id);
      await mutationCourseUpdate(course, {
        ...variables,
        tagIds: courseTagIds,
      });
      storeJS.set('publicCourseShare', {
        category: variables.category,
        language: variables.language,
        institution: variables.institution,
        destination: variables.destination,
        classes: variables.classes,
      });
      if (isPublicCoursePageUpdated) {
        notify('success', t('course-updated-successfully'));
      }
      handleModalClose();
      trackEvent('course', 'update');
    } catch (err) {
      reportError('course', 'update', err);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <Switch
            className={cx('switch')}
            label={t('make-course-public')}
            name="isPublic"
          />
          <Select
            className={cx('property')}
            label={t('select-category')}
            name="category"
          >
            {categories.map((category) => (
              <option key={category.key} value={category.key}>
                {tSelect(category.translation)}
              </option>
            ))}
          </Select>
          <Select
            className={cx('property')}
            label={t('select-language')}
            name="language"
          >
            {LANGUAGES.map((language) => (
              <option key={language.key} value={language.key}>
                {tSelect(language.translation)}
              </option>
            ))}
          </Select>
          <InputText
            className={cx('property')}
            label={t('institution')}
            name="institution"
          />
          <Select
            className={cx('property')}
            label={t('select-destination')}
            name="destination"
          >
            {DESTINATIONS.map((destination) => (
              <option key={destination.key} value={destination.key}>
                {tSelect(destination.translation)}
              </option>
            ))}
          </Select>
          {(values.destination === 'lower-education' ||
            values.destination === 'higher-education') && (
            <>
              <p className={cx('label')}>{t('select-classes')}</p>
              <div
                className={cx('classes', {
                  higher: values.destination === 'higher-education',
                })}
              >
                {values.destination === 'lower-education' &&
                  LOWER_CLASSES.map((lowerClass) => (
                    <CheckBox
                      key={lowerClass.key}
                      label={tSelect(`${lowerClass.translation}`)}
                      name={`classes.${lowerClass.key}`}
                    />
                  ))}
                {values.destination === 'higher-education' &&
                  HIGHER_CLASSES.map((higherClass) => (
                    <CheckBox
                      key={higherClass.key}
                      label={tSelect(`${higherClass.translation}`)}
                      name={`classes.${higherClass.key}`}
                    />
                  ))}
              </div>
            </>
          )}
          <InputTextArray
            className={cx('property')}
            intent="square"
            label={t('add-tags')}
            name="tags"
            options={tags}
          />
          <Button intent="primary" type="submit">
            {t('save')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

SharePublic.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default SharePublic;
