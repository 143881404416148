import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
  getDimensions,
} from 'questions/Shared/utils/images';

import LegendPin from './LegendPin/LegendPin';
import Zone from './Zone/Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({ className = null, imageSrc, legends, zones }) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });

  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, [imageRef, wrapperRef]);

  useEffect(() => {
    function handleScreenResize() {
      if (wrapperRef && imageRef) {
        handleImageLoaded();
      }
    }

    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleImageLoaded, imageRef, wrapperRef]);

  return (
    <>
      <div className={cx('container', className)} ref={wrapperRef}>
        <img
          alt="img"
          className={cx('image')}
          draggable="false"
          onLoad={handleImageLoaded}
          ref={imageRef}
          src={imageSrc}
          style={{
            width: image.width,
            height: image.height,
          }}
        />
        {image.diagonal &&
          zones &&
          zones.map((zone, index) => (
            <Zone
              image={image}
              imageSrc={imageSrc}
              key={`zone-${index}`}
              sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
            />
          ))}
        {image.diagonal &&
          legends &&
          legends.map((legend, index) => (
            <LegendPin
              index={index}
              isCorrect={legend.isCorrect}
              key={`legend-${index}`}
              positionData={getDenormalizedPinPosition(legend, image.diagonal)}
            />
          ))}
      </div>
    </>
  );
};

ImageContainer.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageContainer;
