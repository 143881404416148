import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
} from 'recharts';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip/Tooltip';

import ExpertFeedbacksVerso from '../../Shared/ExpertFeedbacksVerso/ExpertFeedbacksVerso';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/SCT',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      <div className={cx('subtitle')}>
        <p className={cx('header')}>{t('hypotheses')}</p>
        <FormattedText>{question.content.subtitle}</FormattedText>
      </div>
      <div className={cx('subtitle')}>
        <p className={cx('header')}>{t('additional-information')}</p>
        <FormattedText>{question.content.additionalInformation}</FormattedText>
      </div>
      <div
        aria-hidden={true}
        className={cx('chart', {
          biggerChart: question.content.expertFeedbacks.some(
            (column) => column.length,
          ),
        })}
      >
        <div className={cx('user-answer-container')}>
          {[0, 1, 2, 3, 4].map((i) => (
            <Tooltip tooltip={t('user-answer')}>
              <Icon
                className={cx('user-answer', {
                  visible: i === question.answer,
                })}
                name="user-check"
              />
            </Tooltip>
          ))}
        </div>
        <ResponsiveContainer>
          <BarChart
            data={[0, 1, 2, 3, 4].map((i) => ({
              name:
                question.content.labels && question.content.labels[i]
                  ? question.content.labels[i]
                  : t(`experts-${i}-label`),
              experts: question.content.experts[i],
            }))}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid opacity=".2" strokeDasharray="0" vertical={false} />
            <XAxis
              dataKey="name"
              tick={({ payload, ...props }) => (
                <text className={cx('chart-axis')} dy="15" {...props}>
                  {payload.value}
                </text>
              )}
            />
            <Bar dataKey="experts">
              <LabelList
                className={cx('chart-label')}
                dataKey="experts"
                position="top"
              />
              {[0, 1, 2, 3, 4].map((i) => (
                <Cell
                  key={i}
                  fill={
                    question.solution.correct === i
                      ? theme === 'purple'
                        ? '#8258f3'
                        : theme === 'blue'
                        ? '#146aff'
                        : theme === 'turquoise'
                        ? '#32cdfb'
                        : theme === 'red'
                        ? '#d80e1a'
                        : theme === 'green'
                        ? '#6bdd6a'
                        : theme === 'orange'
                        ? '#eb9500'
                        : theme === 'yellow'
                        ? '#ffb500'
                        : theme === 'pink'
                        ? '#f5438c'
                        : theme === 'black'
                        ? '#1d254f'
                        : '#31e000'
                      : question.content.experts[i] === 0
                      ? '#e7ebf0'
                      : theme === 'purple'
                      ? '#c0a8ff'
                      : theme === 'blue'
                      ? '#2da3ff'
                      : theme === 'turquoise'
                      ? '#a6f2ff'
                      : theme === 'red'
                      ? '#fb838c'
                      : theme === 'green'
                      ? '#a5f1a5'
                      : theme === 'orange'
                      ? '#f7c700'
                      : theme === 'yellow'
                      ? '#ffd392'
                      : theme === 'pink'
                      ? '#f67aac'
                      : theme === 'black'
                      ? '#3e4c87'
                      : '#adf399'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className={cx('expert-feedbacks')}>
          {[0, 1, 2, 3, 4].map((i) => (
            <ExpertFeedbacksVerso
              index={i}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              label={
                question.content.labels && question.content.labels[i]
                  ? question.content.labels[i]
                  : t(`experts-${i}-label`)
              }
              question={question}
            />
          ))}
        </div>
      </div>
      <p className={cx('aria-label')}>
        {t('aria-experts-description')}
        {[0, 1, 2, 3].map((i) => (
          <span key={i}>
            {`${question.content.labels?.[i] ?? t(`experts-${i}-label`)}: ${
              question.content.experts[i]
            }`}
          </span>
        ))}
      </p>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => undefined;

Verso.defaultSolution = (question) => {
  const maxIndex = question.content.experts.indexOf(
    Math.max(...question.content.experts),
  );
  return {
    user: null,
    correct: maxIndex,
  };
};

export default Verso;
