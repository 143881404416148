import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useMutationCourseDuplicate,
  useMutationCourseResetParticipants,
} from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal/Modal';
import { notify } from 'uikit/Notifications';

import styles from './CourseResetParticipants.module.scss';

const cx = classNames.bind(styles);

const CourseResetParticipants = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Participants/Students/CourseResetParticipants',
  });

  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const mutationCourseDuplicate = useMutationCourseDuplicate();

  const mutationCourseResetParticipants = useMutationCourseResetParticipants();

  const [status, setStatus] = useState(undefined);

  const [isResetLoading, setIsResetLoading] = useState(false);

  const [isDuplicationLoading, setIsDuplicationLoading] = useState(false);

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);

  async function handleCourseResetParticipants() {
    try {
      setStatus(undefined);
      setIsResetLoading(true);
      await mutationCourseResetParticipants(course.id);
      handleModalClose();
      trackEvent('course', 'reset-participants');
    } catch (err) {
      setStatus(err.message);
      reportError('course', 'reset-participants', err);
    } finally {
      setIsResetLoading(false);
    }
  }

  async function handleCourseDuplicate() {
    try {
      setStatus(undefined);
      setIsDuplicationLoading(true);
      const {
        data: { courseDuplicate },
      } = await mutationCourseDuplicate({
        course,
        prefix: t('copy-of'),
      });
      handleModalClose();
      notify('success', t('course-duplicate-success'));
      history.push(`/course/${courseDuplicate.id}/`);
      trackEvent('course', 'duplicate');
    } catch (err) {
      setStatus('500');
      reportError('course', 'duplicate', err);
    } finally {
      setIsDuplicationLoading(false);
    }
  }

  return (
    <>
      <Button
        icon="trash-alt"
        intent="white"
        onClick={handleModalOpen}
        tooltip={t('tooltip')}
      />
      <Modal
        action={
          <>
            <Button className={cx('cancel-button')} onClick={handleModalClose}>
              {t('cancel')}
            </Button>
            <div className={cx('actions-right')}>
              <Button
                isLoading={isDuplicationLoading}
                onClick={handleCourseDuplicate}
              >
                {t('course-duplicate')}
              </Button>
              <Button
                intent="danger"
                isLoading={isResetLoading}
                onClick={handleCourseResetParticipants}
              >
                {t('confirm-course-reset')}
              </Button>
            </div>
          </>
        }
        error={status && t('student-delete-error')}
        isOpen={isModalOpen}
        isTitleCentered={true}
        onExit={handleModalClose}
        title={t('title')}
      >
        <FormattedText className={cx('explanation')}>
          {t('subtitle')}
        </FormattedText>
        <div className={cx('information-container')}>
          <Icon
            className={cx('text-gradient-blue', 'information-icon')}
            name="info-circle"
          />
          <FormattedText className={cx('information')}>
            {t('information')}
          </FormattedText>
        </div>
      </Modal>
    </>
  );
};

CourseResetParticipants.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseResetParticipants;
