import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import Combination from './Combination/Combination';
import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Sorting/Aggregate',
  });

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('correct-answers', {
                count: aggregation.nCorrectAnswers,
              })}
            </span>
            <span
              className={cx('total-answers', {
                'total-answers-wrong': aggregation.nCorrectAnswers === 0,
              })}
            >
              {` / ${aggregation.nTotalAnswers}`}
            </span>
          </p>
        </div>
        <p className={cx('combinations-title')}>{t('frequent-combinations')}</p>
        <div className={cx('combinations')}>
          {aggregation.aggregatedAnswers.map((answer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Combination answer={answer} key={index} question={question} />
          ))}
        </div>
        <div className={cx('good-answer')}>
          <p className={cx('good-answer-title')}>{t('good-answer')}</p>
          <div className={cx('items')}>
            {aggregation.goodAnswerOccurrences.map((item, index) => (
              <div
                className={cx('item', { pair: item.idx % 2 === 0 })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <div className={cx('position')}>{item.idx + 1}</div>
                <FormattedText>{item.title}</FormattedText>
                <div className={cx('occurrence')}>{item.occurrence}</div>
                <Icon className={cx('occurrence-icon')} name="user-check" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
