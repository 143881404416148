import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Feedback from 'questions/Shared/Feedback/Feedback';
import AudioPreview from 'uikit/AudioPicker/AudioPreview';
import FormattedText from 'uikit/FormattedText';
import VideoPreview from 'uikit/VideoPicker/VideoPreview';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Interview/Verso',
  });

  const { t: tInstructions } = useTranslation('', {
    keyPrefix: 'Questions/Instruction/Verso',
  });

  const [mediaDuration, setMediaDuration] = useState(' ');

  //  question.solution.user is the user's answer in study sessions
  //  question.answer is the user's answer in test sessions
  const userAnswer = question.solution.user || question.answer;

  return (
    <div className={cx('container', className)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('answer')}>
        <p className={cx('label')}>{tInstructions('answer')}</p>
        <div className={cx('video-wrapper')}>
          {userAnswer ? (
            <>
              {question.content.answerType === 'video' ? (
                <VideoPreview
                  footer={<div className={cx('duration')}>{mediaDuration}</div>}
                  setVideoDuration={setMediaDuration}
                  video={userAnswer}
                />
              ) : (
                <AudioPreview
                  audio={userAnswer}
                  className={cx('audio-preview')}
                />
              )}
            </>
          ) : (
            <p>{t('no-answer')}</p>
          )}
        </div>
        {question.content.feedback && (
          <Feedback feedback={question.content.feedback} />
        )}
      </div>
    </div>
  );
};

Verso.defaultAnswer = () => '';

Verso.defaultSolution = () => ({
  user: null,
});

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

export default Verso;
