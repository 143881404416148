import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationChapterUpdate } from 'gql/mutations/chapter';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputDateTime from 'uikit/InputDateTime';
import InputText from 'uikit/InputText';
import { notify } from 'uikit/Notifications';
import SubPage from 'uikit/SubPage/SubPage';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './ChapterEdit.module.scss';

const cx = classNames.bind(styles);

const ChapterEdit = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/ChapterEdit',
  });

  const mutationChapterUpdate = useMutationChapterUpdate();

  const {
    data: {
      editor: { status: editorStatus, chapterEditing },
    },
  } = useQueryLocalEditor();

  const [isEditingTitle, setEditingTitle] = useState(false);

  const isModalOpen =
    editorStatus === EDITOR_STATUSES.CHAPTER_CREATE ||
    editorStatus === EDITOR_STATUSES.CHAPTER_UPDATE;

  useEffect(() => {
    if (!isModalOpen) {
      setEditingTitle(false);
    }
  }, [isModalOpen]);

  function handleChooseDateChange({ setFieldValue, values }) {
    if (!values.chooseDate && values.isOffline) {
      setFieldValue('isOffline', false);
    }
    setFieldValue('chooseDate', !values.chooseDate);
  }

  function handleIsOfflineChange({ setFieldValue, values }) {
    if (values.chooseDate && !values.isOffline) {
      setFieldValue('chooseDate', false);
    }
    setFieldValue('isOffline', !values.isOffline);
  }

  async function handleSave(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      const { data } = await mutationChapterUpdate(chapterEditing, variables);
      notify(
        'success',
        t(
          data.chapterUpdate.isOffline
            ? 'chapter-offline'
            : data.chapterUpdate.isHidden
            ? 'chapter-hidden'
            : 'chapter-online',
        ),
      );
      mutationEditorClear();
      trackEvent('chapter', 'update');
    } catch (err) {
      form.setStatus('500');
      reportError('chapter', 'update', err);
    }
  }

  function handleTitleEditToggle() {
    setEditingTitle((v) => !v);
  }

  const initialValues = chapterEditing
    ? {
        title: '',
        isLinked: false,
        studentChoice: !chapterEditing.isLinked,
        ...chapterEditing,
        chooseDate:
          (!!chapterEditing.onlineTimestamp ||
            !!chapterEditing.offlineTimestamp) &&
          !chapterEditing.isOffline,
      }
    : {};

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(t('title-error-required'))
      .max(256, t('title-error-required')),
    isOffline: yup.boolean(),
    chooseDate: yup.boolean(),
    onlineTimestamp: yup.date().nullable(),
    offlineTimestamp: yup.date().when('onlineTimestamp', {
      is: (val) => val === null,
      then: yup.date().nullable(),
      otherwise: yup
        .date()
        .nullable()
        .min(
          yup.ref('onlineTimestamp'),
          t('offline-timestamp-error-after-online-timestamp'),
        ),
    }),
    isLinked: yup.boolean(),
  });

  return (
    <SubPage
      headerSize="default"
      isOpen={isModalOpen}
      onExit={mutationEditorClear}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue, status, values }) => (
          <Form>
            <div className={cx('header')}>
              <div className={cx('icon')}>
                <Icon name="folder-open" />
              </div>
              {isEditingTitle ? (
                <div className={cx('title-header')}>
                  <InputText
                    autoFocus
                    className={cx('title-edit')}
                    onBlur={handleTitleEditToggle}
                    name="title"
                    maxLength={256}
                  />
                  <Button icon="check" intent="white" />
                </div>
              ) : (
                <div className={cx('title-header')}>
                  <p className={cx('title-view')}>{values.title}</p>
                  <Button
                    aria-label={t('aria-title-edit', { name: values.title })}
                    icon="pencil"
                    intent="white"
                    onClick={handleTitleEditToggle}
                    size="small"
                  />
                </div>
              )}
              <div className={cx('fill')} />
              <Button intent="primary" isLoading={isSubmitting} type="submit">
                {t('save-chapter')}
              </Button>
            </div>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('chapter-edit-error')}
              </p>
            )}
            <div className={cx('tiles')}>
              {!course.isLinear && (
                <div className={cx('tile')}>
                  <p className={cx('tile-title')}>{t('method-title')}</p>
                  <Switch
                    className={cx('field')}
                    helper={
                      <Tooltip tooltip={t('student-choice-helper')}>
                        <Icon name="info-circle" />
                      </Tooltip>
                    }
                    label={t('student-choice-label')}
                    name="studentChoice"
                    onChange={() =>
                      values.isLinked
                        ? setFieldValue('studentChoice', true) &&
                          setFieldValue('isLinked', false)
                        : setFieldValue('studentChoice', false) &&
                          setFieldValue('isLinked', true)
                    }
                  />
                  <Switch
                    className={cx('field')}
                    helper={
                      <Tooltip tooltip={t('progressive-chapter-helper')}>
                        <Icon name="info-circle" />
                      </Tooltip>
                    }
                    label={t('linked-label')}
                    name="isLinked"
                    onChange={() =>
                      values.isLinked
                        ? setFieldValue('studentChoice', true) &&
                          setFieldValue('isLinked', false)
                        : setFieldValue('studentChoice', false) &&
                          setFieldValue('isLinked', true)
                    }
                  />
                </div>
              )}
              <div className={cx('tile')}>
                <p className={cx('tile-title')}>{t('online-dates')}</p>
                <Switch
                  className={cx('field')}
                  label={t('isOffline-label')}
                  name="isOffline"
                  onChange={() =>
                    handleIsOfflineChange({ setFieldValue, values })
                  }
                />
                <Switch
                  className={cx('field')}
                  label={t('choose-date-label')}
                  name="chooseDate"
                  onChange={() =>
                    handleChooseDateChange({
                      setFieldValue,
                      values,
                    })
                  }
                />
                {values.chooseDate && (
                  <>
                    <InputDateTime
                      className={cx('field')}
                      label={t('online-timestamp-label')}
                      name="onlineTimestamp"
                    />
                    <InputDateTime
                      className={cx('field')}
                      label={t('offline-timestamp-label')}
                      name="offlineTimestamp"
                    />
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </SubPage>
  );
};

ChapterEdit.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ChapterEdit;
