import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Sorting/Verso',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      {question.content.items.map((item, index) => {
        const solution = question.solution[index];
        const user = question.content.items.find(
          (i) => i.idx === solution?.user,
        );
        const correct = question.content.items.find(
          (i) => i.idx === solution?.correct,
        );
        return (
          <div className={cx('item')} key={item.idx}>
            {!solution?.isCorrect && (
              <div
                aria-describedby="wrong-description"
                className={cx('answer', 'wrong')}
              >
                <Icon className={cx('icon')} name="times" />
                <FormattedText className={cx('text')}>
                  {user?.title || '___'}
                </FormattedText>
                <div id="wrong-description" className={cx('aria-label')}>
                  {t('aria-wrong-answer')}
                </div>
              </div>
            )}
            <div
              aria-describedby={'correct-description'}
              className={cx('answer', 'correct')}
            >
              <Icon className={cx('icon')} name="check" />
              <FormattedText className={cx('text')}>
                {correct?.title || '___'}
              </FormattedText>
              <div id="correct-description" className={cx('aria-label')}>
                {t(
                  `aria-${
                    solution?.isCorrect ? 'correct-answer' : 'correction'
                  }`,
                )}
              </div>
            </div>
          </div>
        );
      })}
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = (question) =>
  question.content.items.map((item) => item.idx);

Verso.defaultSolution = (question) =>
  question.content.items.map((item) => ({
    idx: item.idx,
    user: null,
    correct: item.idx,
    isCorrect: false,
  }));

export default Verso;
