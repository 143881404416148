import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import SlideBuilder from 'questions/Shared/SlideBuilder';
import Tags from 'questions/Shared/Tags/Tags';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => (
  <div className={cx('container', className)}>
    <SlideBuilder />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Slide2/Editor/title-error-required')),
    slideContent: yup
      .string()
      .trim()
      .required(t('Questions/Slide2/Editor/slide-content-error-required')),
  });

export default Editor;
