import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';
import * as yup from 'yup';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Matching/Editor',
  });

  const [{ value: matches }, , { setValue: setMatches }] = useField('matches');

  function handleMatchAdd() {
    setMatches([
      ...matches,
      {
        idx: matches.length,
        source: '',
        destination: '',
      },
    ]);
  }

  function handleMatchCompleteDestination(model) {
    if (model) {
      setMatches([
        ...matches,
        {
          idx: matches.length,
          source: '',
          destination: model,
        },
      ]);
    }
  }

  function handleMatchCompleteSource(model) {
    if (model) {
      setMatches([
        ...matches,
        {
          idx: matches.length,
          source: model,
          destination: '',
        },
      ]);
    }
  }

  function handleMatchRemove(index) {
    const newMatches = matches.filter((a, i) => i !== index);
    if (newMatches.length < 1) {
      newMatches.push({
        idx: newMatches.length,
        source: '',
        destination: '',
      });
    }
    setMatches(newMatches);
  }

  return (
    <div className={cx('container', className)}>
      <p className={cx('label')}>{t('matches')}</p>
      <div className={cx('field')}>
        <InputError name="matches" />
        {[...matches, ''].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={cx('list-element')} key={index}>
            {index === matches.length && (
              <Button
                className={cx('match-add')}
                icon="plus"
                intent="outline"
                onClick={handleMatchAdd}
                size="small"
                tooltip={t('match-add')}
              />
            )}
            {index === matches.length ? (
              <InputRich
                className={cx('match-complete-left')}
                name="tmp"
                onChange={handleMatchCompleteSource}
              />
            ) : (
              <InputRich
                className={cx('left')}
                name={`matches.${index}.source`}
                placeholder={t(`match${index + 1}-placeholder`)}
              />
            )}
            <Icon className={cx('arrow')} name="arrows-h" />
            {index === matches.length ? (
              <InputRich
                className={cx('match-complete-right')}
                name="tmp"
                onChange={handleMatchCompleteDestination}
              />
            ) : (
              <InputRich
                className={cx('right')}
                name={`matches.${index}.destination`}
                placeholder={t(`destination${index + 1}-placeholder`)}
              />
            )}
            {index !== matches.length && (
              <Button
                icon="trash-alt"
                intent="outline"
                onClick={() => handleMatchRemove(index)}
                tooltip={t('delete-matching')}
                variant="regular"
              />
            )}
          </div>
        ))}
      </div>
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Matching/Editor/default-title')),
    matches: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          source: yup
            .string()
            .trim()
            .required(t('Questions/Matching/Editor/matches-error-required')),
          destination: yup
            .string()
            .trim()
            .required(t('Questions/Matching/Editor/matches-error-required')),
        }),
      )
      .compact()
      .transform((matches) =>
        matches
          .filter((match) => !!match.source && !!match.destination)
          .map((match, index) => ({
            ...match,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/Matching/Editor/matches-error-size'),
        (matches) => matches.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default Editor;
