import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';
import text2React from '../text2React';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Verso',
  });

  const { theme } = useContext(Context);

  const questionText = useMemo(() => {
    let i = 0;
    return text2React(question.content.text, {
      replace: ({ name }) => {
        if (name === 'input' || name === 'select') {
          i += 1;
          const solution = question.solution[i - 1];
          return (
            <span key={i}>
              {!solution?.isCorrect && (
                <>
                  <strong
                    aria-describedby="wrong-description"
                    className={cx('answer', 'wrong')}
                  >
                    {solution?.user || '___'}
                  </strong>
                  <Icon className={cx('chevron')} name="chevron-right" />
                </>
              )}
              <strong
                aria-describedby="correct-description"
                className={cx('answer', 'correct')}
              >
                <CleanedText>
                  {solution?.exact || solution?.correct.join(', ') || '___'}
                </CleanedText>
              </strong>
            </span>
          );
        }
      },
    });
  }, [question]);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <FormattedText className={cx('text')} isAlreadyFormatted>
        {questionText}
      </FormattedText>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
      <p id="wrong-description" className={cx('aria-label')}>
        {t('aria-wrong-answer')}
      </p>
      <p id="correct-description" className={cx('aria-label')}>
        {t('aria-correct-answer')}
      </p>
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = (question) =>
  (question.content.text.match(/\[{2}.*?\]*\]{2}|\{{2}.*?\}*\}{2}/g) || []).map(
    () => '',
  );

Verso.defaultSolution = (question) => {
  const blanks = Array.from(
    question.content.text.matchAll(/(\[{2}.*?\]*\]{2})|(\{{2}.*?\}*\}{2})/g),
    (m) => m[1] || m[2],
  );
  const solution = blanks.map((blank) => {
    const isSelect = blank[0] === '{';
    const inner = blank.substring(2, blank.length - 2);
    const decomposedInner = inner
      .replace(/\\,/g, '#_FAKE_COMMA_#')
      .split(',')
      .map((ele) => ele.replace(/#_FAKE_COMMA_#/g, ','));
    if (isSelect) {
      const [acceptedAnswer] = decomposedInner;
      return {
        user: null,
        exact: null,
        correct: [acceptedAnswer],
        isCorrect: false,
      };
    }
    return {
      user: null,
      exact: null,
      correct: decomposedInner,
      isCorrect: false,
    };
  });
  return solution;
};

export default Verso;
