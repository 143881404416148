import { PropTypes } from 'prop-types';
import classNames from 'classnames/bind';
import React, { useContext } from 'react';

import { useQueryLocalStudy } from 'gql/queries/local';
import Context from 'lib/context';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import ELEPHANT_IMAGE from './images/elephant.svg';
import styles from './Feedback.module.scss';

const cx = classNames.bind(styles);

const Feedback = ({ feedback }) => {
  const { theme } = useContext(Context);

  const {
    data: {
      study: { hasMascotFlag },
    },
  } = useQueryLocalStudy();

  return (
    <div className={cx(`theme-${theme}`)}>
      {hasMascotFlag ? (
        <div className={cx('mascot-feedback')}>
          <img src={ELEPHANT_IMAGE} alt="" />
          <div className={cx('mascot-feedback-content')}>
            <FormattedText>{feedback}</FormattedText>
          </div>
        </div>
      ) : (
        <div className={cx('feedback')}>
          <div className={cx('feedback-icon')}>
            <Icon name="info-circle" variant="duotone" />
          </div>
          <FormattedText>{feedback}</FormattedText>
        </div>
      )}
    </div>
  );
};

Feedback.propTypes = {
  feedback: PropTypes.string.isRequired,
};

export default Feedback;
