import * as dayjs from 'dayjs';
import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  useMutationAuthResendVerification,
  useMutationAuthSetEmail,
} from 'gql/mutations/auth';
import { useMutationMeUpdate } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal/Modal';

import imageVerify from './images/verify.svg';
import styles from './AccountVerification.module.scss';

const cx = classNames.bind(styles);

const AccountVerification = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/AccountVerification',
  });

  const { isLoading, data } = useQueryMe();

  const mutationMeUpdate = useMutationMeUpdate();

  const mutationAuthSetEmail = useMutationAuthSetEmail();

  const mutationAuthResendVerification = useMutationAuthResendVerification();

  const [hasSetEmail, setSetEmail] = useState(false);

  const [hasResentEmail, setResentEmail] = useState(false);

  const metadataMe = data?.me?.metadata ?? {};

  const isOpen = isLoading
    ? false
    : data.me.tags.includes('onboarded') &&
      !data.me.isVerified &&
      !dayjs().isSame(data.me.registeredAt, 'day') &&
      !dayjs().isSame(
        metadataMe?.accountVerification || new Date(2014, 1, 1, 1, 0, 0, 0),
        'day',
      );

  const email = isLoading ? undefined : data.me.email;

  const hasEmail = !!email;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .required(t('email-error-required'))
      .email(t('email-error-required'))
      .max(256, t('email-error-required')),
  });

  async function handleModalClose() {
    try {
      await mutationMeUpdate({
        metadata: {
          ...metadataMe,
          accountVerification: new Date(),
        },
      });
      trackEvent('me:update', 'metadata:accountVerification');
    } catch (err) {
      reportError('me:update', 'metadata:accountVerification', err);
    }
  }

  async function handleResendVerification() {
    try {
      await mutationAuthResendVerification({
        email,
      });
      setResentEmail(true);
      trackEvent('auth', 'resend-verification');
    } catch (err) {
      reportError('auth', 'resend-verification', err);
    }
  }

  async function handleSetEmail(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationAuthSetEmail(variables);
      await mutationAuthResendVerification(values);
      setSetEmail(true);
      trackEvent('auth', 'set-email');
    } catch (err) {
      form.setStatus(err.message);
      reportError('auth', 'set-email', err);
    }
  }

  return (
    <Modal
      action={
        <Button intent="primary" onClick={handleModalClose} size="large">
          {t('continue')}
        </Button>
      }
      image={imageVerify}
      isOpen={isOpen}
      isTitleCentered={true}
      onExit={handleModalClose}
      subtitle={
        !hasSetEmail &&
        !hasResentEmail &&
        t(hasEmail ? 'subtitle-verify' : 'subtitle-set')
      }
      title={t(
        hasSetEmail
          ? 'set-email'
          : hasResentEmail
          ? 'resent'
          : hasEmail
          ? 'title-verify'
          : 'title-set',
      )}
    >
      {!hasSetEmail &&
        !hasResentEmail &&
        (hasEmail ? (
          <Button
            className={cx('action')}
            onClick={handleResendVerification}
            size="large"
          >
            {t('resend')}
          </Button>
        ) : (
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={handleSetEmail}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status }) => (
              <Form>
                {status === '409' ? (
                  <p className={cx('error')} role="alert">
                    {t('email-in-use-error')}
                  </p>
                ) : status === '403' ? (
                  <p className={cx('error')} role="alert">
                    {t('restricted-email-error')}
                  </p>
                ) : (
                  status && (
                    <p className={cx('error')} role="alert">
                      {t('set-email-error')}
                    </p>
                  )
                )}
                <InputText
                  className={cx('field')}
                  icon="at"
                  label={t('email-label')}
                  name="email"
                />
                <Button
                  className={cx('action')}
                  isDisabled={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('set')}
                </Button>
              </Form>
            )}
          </Formik>
        ))}
    </Modal>
  );
};

export default AccountVerification;
