import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import text2React from '../text2React';
import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className = null,
  question,
  questionInfo,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Aggregate',
  });

  const questionText = useMemo(() => {
    let i = 0;
    return text2React(question.content.text, {
      // eslint-disable-next-line consistent-return
      replace: ({ name }) => {
        if (name === 'input' || name === 'select') {
          i += 1;
          const { nRight, nWrong, solution } =
            aggregation.aggregatedAnswers[i - 1];
          return (
            <>
              <span className={cx('right')} key={i}>
                <strong className={cx('right-occurrence')}>
                  {solution.join(', ')}
                </strong>
                <span>{` (${nRight} `}</span>
                <Icon name="user-check" />
                {nWrong > 0 && (
                  <span className={cx('wrong-occurrence')}>
                    <span>{` ${nWrong} `}</span>
                    <Icon name="user-times" />
                  </span>
                )}
                <span>{` ) `}</span>
              </span>
            </>
          );
        }
      },
    });
  }, [aggregation, question]);

  return (
    <div className={cx('container', className)}>
      <div className={cx('background')}>
        <div className={cx('header')}>
          <div className={cx('title-container')}>
            <p className={cx('icon', `background-${questionInfo.color}`)}>
              <img alt="" src={questionInfo.icon} />
            </p>
            <CleanedText as="p" className={cx('title')}>
              {question?.title}
            </CleanedText>
          </div>
          <p
            className={cx('answers-numbers', {
              'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            <span
              className={cx('correct-answers', {
                isEmpty: aggregation.nCorrectAnswers === 0,
              })}
            >
              {t('correct-answers', {
                count: aggregation.nCorrectAnswers,
              })}
            </span>
            <span
              className={cx('total-answers', {
                'total-answers-wrong': aggregation.nCorrectAnswers === 0,
              })}
            >
              {` / ${aggregation.nTotalAnswers}`}
            </span>
          </p>
        </div>
        <div>
          <FormattedText className={cx('text')} isAlreadyFormatted>
            {questionText}
          </FormattedText>
        </div>
        <p className={cx('answers-title')}>{t('recurring-wrong-answers')}</p>
        {aggregation.aggregatedAnswers.map((blank, index) => (
          <p className={cx('errors')} key={`blank-${index}`}>
            <span className={cx('right-occurrence')}>
              {blank.solution.join(', ')}
            </span>
            <Icon className={cx('arrow')} name="arrow-right" />
            {blank.frequentWrongAnswers.map((wrongAnswer, i) => (
              <span className={cx('error')} key={wrongAnswer.title}>
                <span className={cx('error-title')}>{wrongAnswer.title}</span>
                <span className={cx('error-occurrence')}>
                  {wrongAnswer.occurrence}
                </span>
                {i + 1 !== blank.frequentWrongAnswers.length && (
                  <span>{' ,'}</span>
                )}
              </span>
            ))}
          </p>
        ))}
      </div>
    </div>
  );
};

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
};

export default Aggregate;
