import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import VideoPicker from 'uikit/VideoPicker';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => (
  <div className={cx('container', className)}>
    <VideoPicker data-testid="video" name="video" />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Video/Editor/default-title')),
    video: yup
      .string()
      .trim()
      .required(t('Questions/Video/Editor/video-error-required'))
      .url(t('Questions/Video/Editor/video-error-required')),
  });

export default Editor;
