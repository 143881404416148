import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import Feedback from 'questions/Shared/Feedback/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className = null, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/ShortAnswer/Verso',
  });

  const { theme } = useContext(Context);

  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <div className={cx('answer-container')}>
        {question.result !== 'correct' && (
          <p className={cx('answer', 'wrong')}>
            {question.solution.user || '___'}
          </p>
        )}
        {question.solution.exact ? (
          <>
            {question.result !== 'correct' && (
              <p className={cx('label')}>{t('exact-answer')}</p>
            )}
            <p
              className={cx('answer', {
                correct: question.result === 'correct',
              })}
            >
              {question.solution.exact}
            </p>
          </>
        ) : (
          <>
            {question.result !== 'correct' && (
              <p className={cx('label')}>{t('correct-answer')}</p>
            )}
            {question.solution.correct.map((text, index) => (
              <p
                className={cx('answer', {
                  correct: question.result === 'correct',
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {text}
              </p>
            ))}
          </>
        )}
      </div>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultAnswer = () => '';

Verso.defaultSolution = (question) => ({
  user: null,
  exact: null,
  correct: question.content.answers,
});


export default Verso;
