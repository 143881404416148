import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryTestStatus } from 'gql/queries/test';
import useModal from 'hooks/useModal';
import ArtImage from 'images/categories/art.svg';
import ComputerScienceImage from 'images/categories/computer-science.svg';
import HealthImage from 'images/categories/health.svg';
import LanguageImage from 'images/categories/language.svg';
import ManagementImage from 'images/categories/management.svg';
import MathematicsImage from 'images/categories/mathematics.svg';
import OtherImage from 'images/categories/other.svg';
import ScienceImage from 'images/categories/science.svg';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/ModalLegacy';

import styles from './ExamStart.module.scss';

const cx = classNames.bind(styles);

const IMAGES = {
  art: ArtImage,
  'computer-science': ComputerScienceImage,
  health: HealthImage,
  language: LanguageImage,
  management: ManagementImage,
  mathematics: MathematicsImage,
  other: OtherImage,
  science: ScienceImage,
};

const ExamStart = ({
  className = null,
  course,
  isTeacher = false,
  ...props
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/ExamStart',
  });

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const { isLoading, data } = useQueryTestStatus({
    variables: {
      courseId: course.id,
    },
    skip: !isModalOpen,
  });

  const testStatus = data?.testStatus;

  return (
    <>
      <button
        {...props}
        className={cx('button', className, {
          isTeacher,
        })}
        onClick={handleModalOpen}
        type="button"
      >
        {t(isTeacher ? 'exam-try' : 'exam-start')}
        {!isTeacher && <Icon className={cx('chevron')} name="chevron-right" />}
      </button>
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title', 'centered-title')}>{course.title}</h1>
          <div
            className={cx('image-container', `theme-${course.themeBackground}`)}
          >
            <div
              className={cx('illustration')}
              style={{
                backgroundImage: `url(${
                  IMAGES[course.category] || IMAGES.other
                })`,
              }}
            />
          </div>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {testStatus.status === 408 ? (
                <p className={cx('subtitle')}>{t('session-expired')}</p>
              ) : testStatus.status === 202 ? (
                <>
                  <p className={cx('subtitle')}>{t('session-resume')}</p>
                  <Button
                    as={Link}
                    className={cx('action')}
                    intent="primary"
                    size="large"
                    to={`/test/${course.id}`}
                  >
                    {t('resume')}
                  </Button>
                </>
              ) : testStatus.status === 403 ? (
                <>
                  <p className={cx('subtitle')}>{t('session-not-available')}</p>
                  <p className={cx('info')}>{t('start-time')}</p>
                  <p>{dayjs(course.examStartTime).format('LLLL')}</p>
                  <p className={cx('info')}>{t('stop-time')}</p>
                  <p>{dayjs(course.examStopTime).format('LLLL')}</p>
                  {course.examDurationMinutes > 0 && (
                    <>
                      <p className={cx('info')}>{t('duration')}</p>
                      <p>{`${course.examDurationMinutes} minutes`}</p>
                    </>
                  )}
                  {course.examInstructions && (
                    <>
                      <p className={cx('info')}>{t('instructions')}</p>
                      <FormattedText>{course.examInstructions}</FormattedText>
                    </>
                  )}
                </>
              ) : testStatus.status === 201 ? (
                <>
                  <p className={cx('subtitle')}>{t('session-start')}</p>
                  <p className={cx('info')}>{t('start-time')}</p>
                  <p>{dayjs(course.examStartTime).format('LLLL')}</p>
                  <p className={cx('info')}>{t('stop-time')}</p>
                  <p>{dayjs(course.examStopTime).format('LLLL')}</p>
                  {course.examDurationMinutes > 0 && (
                    <>
                      <p className={cx('info')}>{t('duration')}</p>
                      <p>{`${course.examDurationMinutes} minutes`}</p>
                    </>
                  )}
                  {course.examInstructions && (
                    <>
                      <p className={cx('info')}>{t('instructions')}</p>
                      <FormattedText>{course.examInstructions}</FormattedText>
                    </>
                  )}
                  <Button
                    as={Link}
                    className={cx('action')}
                    intent="primary"
                    size="large"
                    to={`/test/${course.id}`}
                  >
                    {t('start')}
                  </Button>
                </>
              ) : testStatus.status === 203 ? (
                <>
                  <p className={cx('subtitle')}>{t('special-access')}</p>
                  <div className={cx('actions')}>
                    <Button onClick={handleModalClose} size="large">
                      {t('back')}
                    </Button>
                    <Button
                      as={Link}
                      intent="primary"
                      size="large"
                      to={`/test/${course.id}`}
                    >
                      {t('start')}
                    </Button>
                  </div>
                </>
              ) : (
                testStatus.status && (
                  <p className={cx('subtitle')}>{t('error')}</p>
                )
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

ExamStart.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool,
};

export default ExamStart;
