import React, { useEffect } from 'react';
import { Router } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import storeJS from 'store';

import AuthChangeEmailConfirm from 'components/Auth/ChangeEmailConfirm/ChangeEmailConfirm';
import AuthLogin from 'components/Auth/Login/Login';
import AuthLogout from 'components/Auth/Logout/Logout';
import AuthRecoverPassword from 'components/Auth/RecoverPassword/RecoverPassword';
import AuthRegister from 'components/Auth/Register/Register';
import AuthResetPassword from 'components/Auth/ResetPassword/ResetPassword';
import AuthVerify from 'components/Auth/Verify/Verify';
import Course from 'components/Course/Course';
import Dashboard from 'components/Dashboard/Dashboard';
import SplashScreen from 'components/Shared/SplashScreen';
import { useMutationMeUpdate } from 'gql/mutations/me';
import { mutationAppLogOut } from 'gql/mutations/app';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import history from 'lib/history';
import { reportError } from 'lib/tracking';
import { NotificationsContainer } from 'uikit/Notifications';

import AccountVerification from './AccountVerification/AccountVerification';
import DownloadApp from './DownloadApp/DownloadApp';
import CookieConsent from './CookieConsent/CookieConsent';
import I18N from './I18N';
import LightBox from './LightBox/LightBox';
// import NPS from './NPS/NPS';
import Onboarding from './Onboarding/Onboarding';
import OpenDyslexic from './OpenDyslexic';

const ActionsAuthenticate = React.lazy(() =>
  import('components/Actions/Authenticate/Authenticate'),
);
const ActionsExportWooclap = React.lazy(() =>
  import('components/Actions/ExportWooclap/ExportWooclap'),
);
const ActionsImportQuizWizard = React.lazy(() =>
  import('components/Actions/ImportQuizWizard/ImportQuizWizard'),
);
const ActionsCoupon = React.lazy(() =>
  import('components/Actions/Coupon/Coupon'),
);
const ActionsGAR = React.lazy(() => import('components/Actions/GAR/GAR'));
const ActionsJoinByCode = React.lazy(() =>
  import('components/Actions/JoinByCode/JoinByCode'),
);
const ActionsJoinByInvitation = React.lazy(() =>
  import('components/Actions/JoinByInvitation/JoinByInvitation'),
);
const ActionsMoodle = React.lazy(() =>
  import('components/Actions/Moodle/Moodle'),
);
const PublicError = React.lazy(() => import('components/Public/Error/Error'));
const Referral = React.lazy(() => import('components/Actions/Referral/Referral'));
const Settings = React.lazy(() => import('components/Settings/Settings'));
const Support = React.lazy(() => import('components/Support/Support'));
const Subscription = React.lazy(() => import('components/Subscription/Subscription'));
const AgendaPage = React.lazy(() => import('components/AgendaPage/AgendaPage'));
const Notifications = React.lazy(() =>
  import('components/Notifications/Notifications'),
);
const Study = React.lazy(() => import('components/Study/Study'));
const Expert = React.lazy(() => import('components/Expert/Expert'));
const Play = React.lazy(() => import('components/Play/Play'));
const Test = React.lazy(() => import('components/Test/Test'));
const TestReview = React.lazy(() => import('components/TestReview/TestReview'));
const PublicCourses = React.lazy(() =>
  import('components/PublicCourses/PublicCourses'),
);
const Template = React.lazy(() =>
  import('components/PublicCourses/Template/Template'),
);
const ImportPublicCourse = React.lazy(() =>
  import('components/Actions/ImportPublicCourse'),
);

const MsTeamsAuthForm = React.lazy(() =>
  import('components/MsTeams/AuthForm/AuthForm'),
);
const MsTeamsAuthCallback = React.lazy(() =>
  import('components/MsTeams/AuthCallback/AuthCallback'),
);
const MsTeamsAuthStart = React.lazy(() =>
  import('components/MsTeams/AuthStart/AuthStart'),
);
const MsTeamsConfigure = React.lazy(() =>
  import('components/MsTeams/Configure/Configure'),
);
const MsTeamsCourse = React.lazy(() =>
  import('components/MsTeams/Course/Course'),
);

import('./Vendors');

const commonRoutes = [
  <Route exact key="/error" path="/error" component={PublicError} />,
  <Route
    key="authenticate"
    path="/authenticate"
    component={ActionsAuthenticate}
  />,
  <Route
    exact
    key="reset-password"
    path="/auth/reset-password"
    component={AuthResetPassword}
  />,
  <Route
    key="coupon"
    path={['/coupon/:accessCode', '/microlearning/:accessCode']}
    component={ActionsCoupon}
  />,
  <Route
    exact
    key="export-wooclap"
    path="/export-wooclap"
    component={ActionsExportWooclap}
  />,
  <Route key="moodle" path="/moodle/:accessCode" component={ActionsMoodle} />,
  <Route exact key="verify" path="/auth/verify" component={AuthVerify} />,
  <Route
    exact
    key="change-email-confirm"
    path="/auth/change-email-confirm"
    component={AuthChangeEmailConfirm}
  />,
  <Route key="referral" path="/referral/:referralCode" component={Referral} />,
  <Route exact key="support" path="/support" component={Support} />,
  <Route
    exact
    key="ms-teams-auth"
    path="/ms-teams/auth"
    component={MsTeamsAuthForm}
  />,
  <Route
    exact
    key="ms-teams-auth-callback"
    path="/ms-teams/auth-callback"
    component={MsTeamsAuthCallback}
  />,
  <Route
    exact
    key="ms-teams-auth-start"
    path="/ms-teams/auth-start"
    component={MsTeamsAuthStart}
  />,
  <Route
    exact
    key="ms-teams-configure"
    path="/ms-teams/configure"
    component={MsTeamsConfigure}
  />,
  <Route
    key="ms-teams-course"
    path="/ms-teams/course/:courseId"
    component={MsTeamsCourse}
  />,
];

const restrictedRoutes = [
  <Route
    exact
    key="import-quizwizard"
    path="/import-quizwizard"
    component={ActionsImportQuizWizard}
  />,
  <Route
    exact
    key="join"
    path={['/join/:accessCode', '/join-by-code/:accessCode']}
    component={ActionsJoinByCode}
  />,
  <Route
    key="join-by-invitation"
    path="/join-by-invitation/:invitationId"
    component={ActionsJoinByInvitation}
  />,
  <Route
    key="join-group"
    path={['/join/:accessCode/:groupId', '/join-by-code/:accessCode/:groupId']}
    component={ActionsJoinByCode}
  />,
  <Route
    exact
    key="publicCourses"
    path="/public-courses"
    component={PublicCourses}
  />,
  <Route
    key="template"
    path="/public-courses/template/:publicCourseId/"
    component={Template}
  />,
  <Route
    key="course-import"
    path="/course-import/:courseId/"
    component={ImportPublicCourse}
  />,
];

const garRoutes = [<Route key="default" component={ActionsGAR} />];

const unloggedNormalRoutes = [
  <Route exact key="login" path="/auth/login" component={AuthLogin} />,
  <Route exact key="register" path="/auth/register" component={AuthRegister} />,
  <Route
    exact
    key="forgot-password"
    path="/auth/forgot-password"
    component={AuthRecoverPassword}
  />,
  <Route
    key="default"
    render={({ location }) => {
      if (location.pathname !== '/auth/logout') {
        storeJS.set('redirectTo', `${location.pathname}?${location.search}`);
      }
      return (
        <Redirect
          to={{
            pathname: '/auth/login',
          }}
        />
      );
    }}
  />,
];

const unloggedTeamsRoutes = [
  <Route exact key="login" path="/auth/login" component={AuthLogin} />,
  <Route exact key="register" path="/auth/register" component={AuthRegister} />,
  <Route
    exact
    key="forgot-password"
    path="/auth/forgot-password"
    component={AuthRecoverPassword}
  />,
  <Route
    key="default"
    render={({ location }) => (
      <Redirect
        to={{
          pathname: '/ms-teams/auth',
          search: location.search,
        }}
      />
    )}
  />,
];

const loggedRoutes = [
  <Route exact key="verify" path="/auth/verify" component={AuthVerify} />,
  <Route exact key="logout" path="/auth/logout" component={AuthLogout} />,
  <Route exact key="dashboard" path="/" component={Dashboard} />,
  <Route exact key="settings" path="/settings" component={Settings} />,
  <Route
    exact
    key="subscription"
    path="/subscription"
    component={Subscription}
  />,
  <Route exact key="agenda" path="/agenda" component={AgendaPage} />,
  <Route
    exact
    key="notifications"
    path="/notifications"
    component={Notifications}
  />,
  <Route key="course" path="/course/:courseId/" component={Course} />,
  <Route key="study" path="/study/:courseId" component={Study} />,
  <Route
    key="expert"
    path="/expert/:courseId/:questionId?"
    component={Expert}
  />,
  <Route key="play" path="/play/:gameId" component={Play} />,
  <Route key="test" path="/test/:courseId" component={Test} />,
  <Route
    key="test-review"
    path="/test-review/:courseId"
    component={TestReview}
  />,
  <Route
    key="default"
    render={({ location }) => (
      <Redirect to={{ pathname: '/', search: location.search }} />
    )}
  />,
];

const App = () => {
  const { expoPushToken, isEditionDisabled, isInTeams, isGAR } =
    useSiteContext();

  const mutationMeUpdate = useMutationMeUpdate();

  const {
    data: {
      app: { isAuthenticated },
    },
  } = useQueryLocalApp();

  const { error, isLoading: isLoadingMe } = useQueryMe({
    skip: !isAuthenticated,
  });

  useEffect(() => {
    function keyListener(event) {
      if (event.keyCode === 32 && event.target.href) {
        event.target.click();
      }
    }
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  });

  useEffect(() => {
    (async function asyncEffect() {
      try {
        if (error) {
          await mutationAppLogOut();
        }
      } catch (err) {
        reportError('app', 'init', err);
      }
    })();
  }, [error]);

  useEffect(() => {
    async function updateMe() {
      if (isAuthenticated && expoPushToken) {
        try {
          await mutationMeUpdate({
            pushToken: expoPushToken,
          });
        } catch {
          //
        }
      }
    }
    updateMe();
  }, [expoPushToken, isAuthenticated, mutationMeUpdate]);

  useEffect(() => {
    if (isAuthenticated && isGAR) {
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + 1);
      storeJS.set('garsecurelogout', true, exdate.toUTCString());
    }
  }, [isAuthenticated, isGAR]);

  const wasGAR = !isAuthenticated && !!storeJS.get('garsecurelogout');

  const isLoading = isAuthenticated && isLoadingMe;

  return (
    <Router history={history}>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <I18N />
          {!wasGAR && <CookieConsent />}
          <NotificationsContainer />
          <LightBox />
          {isAuthenticated && (
            <>
              <AccountVerification />
              <Onboarding />
              <DownloadApp />
              {/* <NPS /> */}
              <OpenDyslexic />
            </>
          )}
          <Switch>
            {commonRoutes}
            {!isEditionDisabled && restrictedRoutes}
            {wasGAR && garRoutes}
            {!wasGAR && isAuthenticated && loggedRoutes}
            {!wasGAR && !isAuthenticated && !isInTeams && unloggedNormalRoutes}
            {!wasGAR && !isAuthenticated && isInTeams && unloggedTeamsRoutes}
          </Switch>
        </>
      )}
    </Router>
  );
};

export default App;
