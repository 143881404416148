import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';

import styles from './VideoRecorder.module.scss';
import VideoPreview from '../VideoPreview';

const cx = classNames.bind(styles);

const VideoRecorder = ({
  className,
  btnRecordStop,
  handleCurrentTimeUpdate,
  handleModalCloseAndReset,
  handleRecordedVideoReset,
  handleRecordingRestart,
  handleRecordVideo,
  handleSelect,
  isLoading,
  isModalOpen,
  isRecording,
  isRestarting,
  isSubmitting,
  t,
  theme,
  timer,
  videoDuration,
  videoPreview,
  videoRef,
}) => (
  <div
    className={cx(
      className,
      'container',
      `theme-${theme}`,
    )}
  >
    <Button
      className={cx('open-modal-button')}
      icon="video"
      intent="white"
      onClick={handleRecordVideo}
    >
      {t('uikit/VideoPicker/VideoRecorder/record')}
    </Button>
    <Modal
      className={cx(
        { 'hidden-overlay': isRecording },
        `theme-${theme}`,
      )}
      isOpen={isModalOpen}
      onExit={isLoading || isRecording ? null : handleModalCloseAndReset}
    >
      {isLoading && <h1 className={cx('countdown')}>{timer}</h1>}
      {(isLoading || isRecording) && (
        <div className={cx('video-preview')}>
          <video
            autoPlay
            className={cx('stream')}
            id="videoStream"
            muted
            onTimeUpdate={handleCurrentTimeUpdate}
            ref={videoRef}
          >
            <track default kind="captions" />
          </video>
          {isLoading && (
            <p className={cx('loading-message')}>
              {t('uikit/VideoPicker/VideoRecorder/loading')}
            </p>
          )}
          <div className={cx('controller')}>
            <Button
              className={cx('stop-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="stop"
              intent="white"
              ref={btnRecordStop}
            />
            <div className={cx('duration')}>{videoDuration}</div>
            <Button
              className={cx('restart-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="arrow-rotate-right"
              intent="white"
              onClick={handleRecordingRestart}
            />
            <Button
              className={cx('delete-button', { disabled: isLoading })}
              disabled={isLoading}
              icon="trash-alt"
              intent="white"
              onClick={handleModalCloseAndReset}
            />
          </div>
        </div>
      )}
      {videoPreview && !isRestarting && (
        <div className={cx('modal', 'large')}>
          <div className={cx('header')}>
            <h1 className={cx('title')}>
              {t('uikit/VideoPicker/VideoRecorder/record')}
            </h1>
            <Button
              aria-label={t('uikit/VideoPicker/VideoRecorder/aria-close')}
              icon="times"
              onClick={handleModalCloseAndReset}
            />
          </div>
          {videoPreview && !isRecording && (
            <div className={cx('recorded-preview-container')}>
              <div className={cx('recorded-preview')}>
                <VideoPreview video={videoPreview} />
                <div className={cx('footer')}>
                  <Button
                    className={cx('retake-button')}
                    onClick={handleRecordedVideoReset}
                    size="large"
                  >
                    {t('uikit/VideoPicker/VideoRecorder/delete')}
                  </Button>
                  <Button
                    className={cx('submit-button')}
                    intent="primary"
                    isLoading={isSubmitting}
                    onClick={handleSelect}
                    size="large"
                  >
                    {t('uikit/VideoPicker/VideoRecorder/select')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {isRecording ? (
            <div
              aria-label={t('uikit/VideoPicker/VideoRecorder/aria-recording')}
              role="alert"
            />
          ) : (
            videoPreview && (
              <div
                aria-label={t(
                  'uikit/VideoPicker/VideoRecorder/aria-recording-over',
                )}
                role="alert"
              />
            )
          )}
        </div>
      )}
    </Modal>
  </div>
);

VideoRecorder.propTypes = {
  className: PropTypes.string,
  btnRecordStop: PropTypes.object,
  handleCurrentTimeUpdate: PropTypes.func.isRequired,
  handleModalCloseAndReset: PropTypes.func.isRequired,
  handleRecordedVideoReset: PropTypes.func.isRequired,
  handleRecordingRestart: PropTypes.func.isRequired,
  handleRecordVideo: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isRestarting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  timer: PropTypes.number.isRequired,
  videoDuration: PropTypes.number.isRequired,
  videoPreview: PropTypes.string,
  videoRef: PropTypes.object,
};

VideoRecorder.defaultProps = {
  btnRecordStop: undefined,
  className: undefined,
  videoPreview: undefined,
  videoRef: undefined,
};

export default VideoRecorder;
