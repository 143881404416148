import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGameCreate } from 'gql/mutations/game';
import { useQueryCourseChapters } from 'gql/queries/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Modal from 'uikit/ModalLegacy';
import InputText from 'uikit/InputText';
import Select from 'uikit/Select';

import styles from './GameCreate.module.scss';

const cx = classNames.bind(styles);

const GameCreate = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games/GameCreate',
  });

  const mutationGameCreate = useMutationGameCreate();

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const isModalOpen = editorStatus === EDITOR_STATUSES.GAME_CREATE;

  const { data } = useQueryCourseChapters({
    variables: {
      courseId: course.id,
    },
    skip: !isModalOpen,
  });

  async function handleGameCreate(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      mutationGameCreate({
        ...variables,
        courseId: course.id,
      });
      mutationEditorClear();
      trackEvent('game', 'create');
    } catch (err) {
      form.setStatus(err.message);
      reportError('game', 'create', err);
    }
  }

  const validationSchema = yup.object().shape({
    type: yup
      .string()
      .required(t('type-error-required'))
      .trim()
      .max(64, t('type-error-required')),
    title: yup
      .string()
      .required(t('title-error-required'))
      .trim()
      .max(256, t('title-error-required')),
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
  });

  const chapters = data?.course.chapters || [];

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear}>
      <Formik
        initialValues={{
          type: '',
          title: '',
          chapterId: '',
        }}
        onSubmit={handleGameCreate}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form className={cx('container')}>
            <Button
              aria-label={t('aria-close')}
              className={cx('close')}
              icon="times"
              onClick={mutationEditorClear}
            />
            <h1 className={cx('title')}>{t('title')}</h1>
            <p className={cx('subtitle')}>{t('subtitle')}</p>
            {status && (
              <p className={cx('error')} role="alert">
                {t('game-create-error')}
              </p>
            )}
            <InputText
              className={cx('field')}
              icon="gamepad"
              label={t('title-label')}
              name="title"
            />
            <Select
              className={cx('field')}
              icon="chess-queen"
              label={t('type-label')}
              name="type"
            >
              <option disabled value="">
                {t('type-level')}
              </option>
              <option value="water-level">{t('type-water-level')}</option>
            </Select>
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('confirm-create-game')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

GameCreate.propTypes = {
  course: PropTypes.object.isRequired,
};

export default GameCreate;
