import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Options from 'questions/Shared/Options/Options';
import Tags from 'questions/Shared/Tags/Tags';
import AudioPicker from 'uikit/AudioPicker';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import InputText from 'uikit/InputText';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Dictation/Editor',
  });

  return (
    <div className={cx('container', className)}>
      <AudioPicker className={cx('field', 'audio')} name="audio" />
      <InputText
        className={cx('field')}
        label={t('answer-label')}
        name="answer"
        type="textarea"
      />
      <InputRich
        helper={
          <Tooltip tooltip={t('feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('feedback-label')}
        name="feedback"
      />
      <Options className={cx('options')}>
        <Switch
          className={cx('field')}
          label={t('ignore-case-label')}
          name="ignoreCase"
        />
        <Tags />
      </Options>
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Dictation/Editor/default-title')),
    audio: yup
      .string()
      .trim()
      .required(t('Questions/Dictation/Editor/audio-error-required'))
      .url(t('Questions/Dictation/Editor/audio-error-required')),
    answer: yup
      .string()
      .trim()
      .required(t('Questions/Dictation/Editor/answer-error-required')),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
  });

export default Editor;
