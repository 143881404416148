import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/ModalLegacy';

import styles from './CourseLeave.module.scss';

const cx = classNames.bind(styles);

const CourseLeave = ({
  className,
  handleCourseLeave,
  handleModalClose,
  handleModalOpen,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <>
    <MenuItem className={className} icon="sign-out" onClick={handleModalOpen}>
      {t('Shared/CourseLeave/leave-course')}
    </MenuItem>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Shared/CourseLeave/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>{t('Shared/CourseLeave/title')}</h1>
        <p className={cx('subtitle')}>{t('Shared/CourseLeave/subtitle')}</p>
        {status && (
          <p className={cx('error')} role="alert">
            {t('Shared/CourseLeave/course-leave-error')}
          </p>
        )}
        <Button
          className={cx('action')}
          intent="danger"
          isLoading={isLoading}
          onClick={handleCourseLeave}
          size="large"
        >
          {t('Shared/CourseLeave/confirm-leave-course')}
        </Button>
      </div>
    </Modal>
  </>
);

CourseLeave.propTypes = {
  className: PropTypes.string,
  handleCourseLeave: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CourseLeave.defaultProps = {
  className: undefined,
  status: undefined,
};

export default CourseLeave;
