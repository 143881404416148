import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';
import * as yup from 'yup';

import { apiUploadsS3 } from 'api/upload';
import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorClear,
  mutationEditorQuestionCreate,
  mutationEditorQuestionPreview,
  mutationEditorQuestionPropose,
  mutationEditorQuestionVerify,
} from 'gql/mutations/editor';
import {
  useMutationQuestionApprove,
  useMutationQuestionCreate,
  useMutationQuestionDelete,
  useMutationQuestionImportFile,
  useMutationQuestionPropose,
  useMutationQuestionUpdate,
} from 'gql/mutations/question';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import extract from 'helpers/extract';
import useModal from 'hooks/useModal';
import {
  getQuestionsInfo,
  getQuestionInfo,
  getQuestionEditor,
} from 'questions';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';
import SubPage from 'uikit/SubPageLegacy';

import defaultValues from './_defaultValues';
import QuestionEditor from './QuestionEditor/QuestionEditor';
import QuestionGrade from './QuestionGrade/QuestionGrade';
import QuestionPreview from './QuestionPreview/QuestionPreview';
import QuestionTypes from './QuestionTypes/QuestionTypes';
import styles from './QuestionEdit.module.scss';

const cx = classNames.bind(styles);

const QuestionEdit = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit',
  });

  const { t: tValidationSchema } = useTranslation();

  const mutationQuestionApprove = useMutationQuestionApprove();
  const mutationQuestionCreate = useMutationQuestionCreate();
  const mutationQuestionDelete = useMutationQuestionDelete();
  const mutationQuestionImportFile = useMutationQuestionImportFile();
  const mutationQuestionPropose = useMutationQuestionPropose();
  const mutationQuestionUpdate = useMutationQuestionUpdate();

  const {
    isModalOpen: isGradeModalOpen,
    handleModalOpen: handleGradeModalOpen,
    handleModalClose: handleGradeModalClose,
  } = useModal(false);

  const {
    isModalOpen: isDeleteModalOpen,
    handleModalOpen: handleDeleteModalOpen,
    handleModalClose: handleDeleteModalClose,
  } = useModal(false);

  const {
    data: { me },
  } = useQueryMe();

  const {
    data: {
      editor: {
        status: editorStatus,
        chapterSelected,
        questionType,
        questionEditing,
      },
    },
  } = useQueryLocalEditor();

  const [isQuestionSaved, setQuestionSaved] = useState(false);

  const languages = {
    en: 'en-GB',
    fr: 'fr-FR',
    nl: 'nl-NL',
    it: 'it-IT',
  };
  const meLanguage = languages[me?.language] || 'fr-FR';

  const chapterSelectedId = chapterSelected?.id || null;

  const questionsInfo = getQuestionsInfo();

  const questionInfo = getQuestionInfo(questionType);

  const editorComponent = getQuestionEditor(questionType);

  const validationSchema = editorComponent?.validationSchema(tValidationSchema);

  const feedbackValidationSchema = yup.object().shape({
    grade: yup.number().integer().nullable(),
    studentFeedback: yup.string().nullable().trim(),
    notifyStudent: yup.boolean(),
  });

  const allQuestions =
    editorStatus === EDITOR_STATUSES.QUESTION_APPROVE
      ? course.questionsToApprove
      : [].concat(
          ...course.chapters.map((chapter) => chapter.questions),
          course.questions,
        );

  const previousQuestion = !questionEditing
    ? allQuestions[allQuestions.length - 1]
    : allQuestions.find(
        (_, index) => allQuestions[index + 1]?.id === questionEditing?.id,
      );

  const nextQuestion = !questionEditing
    ? allQuestions[0]
    : allQuestions.find(
        (_, index) => allQuestions[index - 1]?.id === questionEditing?.id,
      );

  const isModalOpen =
    editorStatus === EDITOR_STATUSES.QUESTION_CREATE ||
    editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE ||
    editorStatus === EDITOR_STATUSES.QUESTION_APPROVE ||
    editorStatus === EDITOR_STATUSES.QUESTION_UPDATE ||
    editorStatus === EDITOR_STATUSES.QUESTION_PREVIEW;

  const isPreview = editorStatus === EDITOR_STATUSES.QUESTION_PREVIEW;

  const areOtherQuestionsToApprove =
    editorStatus === EDITOR_STATUSES.QUESTION_APPROVE &&
    (nextQuestion || previousQuestion);

  const initialValues = useMemo(
    () =>
      questionEditing
        ? {
            ...defaultValues,
            choices:
              questionType === 'TrueOrFalse'
                ? [
                    { idx: 0, title: '', answer: false, feedback: '' },
                    { idx: 1, title: '', answer: false, feedback: '' },
                    { idx: 2, title: '', answer: false, feedback: '' },
                    { idx: 3, title: '', answer: false, feedback: '' },
                  ]
                : defaultValues.choices,
            ...questionEditing.content,
            tags: questionEditing.tags || [],
            title: questionEditing.title || '',
            experts: [
              ...(questionEditing.content.experts || []),
              0,
              0,
              0,
              0,
              0,
            ],
            expertFeedbacks: [
              ...(questionEditing.content.expertFeedbacks || []),
              [],
              [],
              [],
              [],
              [],
            ],
            titleLang:
              questionEditing.content.titleLang ||
              storeJS.get('titleLang') ||
              meLanguage,
            feedbackLang:
              questionEditing.content.feedbackLang ||
              storeJS.get('feedbackLang') ||
              meLanguage,
            useSpeech:
              questionEditing.content.useSpeech !== undefined
                ? questionEditing.content.useSpeech
                : storeJS.get('useSpeech') !== undefined
                ? storeJS.get('useSpeech')
                : true,
            background:
              questionEditing.content.background ||
              course.themeBackground ||
              'white',
            textColor: questionEditing.content.textColor || 'black',
          }
        : {},
    [course, meLanguage, questionEditing, questionType],
  );

  const checkIfDirty = (values) => {
    const copiedValues = { ...values };
    const copiedInitialValues = { ...initialValues };
    delete copiedValues.wantPreview;
    delete copiedValues.wantRecreate;
    delete copiedInitialValues.wantPreview;
    delete copiedInitialValues.wantRecreate;
    return JSON.stringify(copiedValues) !== JSON.stringify(copiedInitialValues);
  };

  const displaySavedQuestionMessage = () => {
    setQuestionSaved(true);
    setTimeout(() => setQuestionSaved(false), 2000);
  };

  const handleApprove = async (values) => {
    const { tags, title, ...content } = extract(values, validationSchema);
    const { grade, notifyStudent, studentFeedback } = extract(
      values,
      feedbackValidationSchema,
    );
    const {
      data: { questionApprove },
    } = await mutationQuestionApprove(questionEditing, {
      courseId: course.id,
      chapterId: chapterSelectedId || values.chapterId,
      type: questionType,
      tags,
      title,
      content,
      grade,
      studentFeedback,
      notifyStudent,
    });
    notify('success', t('approve-success'));
    handleGradeModalClose();
    trackEvent('question', 'approve');
    return questionApprove;
  };

  const handleClose = async ({
    dirty = false,
    handleSubmit,
    setValues,
    values,
  }) => {
    if (dirty && editorStatus !== EDITOR_STATUSES.QUESTION_APPROVE) {
      handleSubmit(values);
    } else {
      await setValues(initialValues);
      mutationEditorClear();
    }
  };

  const handleCreate = async (values) => {
    const { tags, title, ...content } = extract(values, validationSchema);
    const {
      data: { questionCreate },
    } = await mutationQuestionCreate({
      courseId: course.id,
      chapterId: chapterSelectedId || values.chapterId,
      type: questionType,
      tags,
      title,
      content,
    });
    trackEvent('question', 'create');
    return questionCreate;
  };

  const handleDelete = async (values) => {
    const { grade, notifyStudent, studentFeedback } = extract(
      values,
      feedbackValidationSchema,
    );
    await mutationQuestionDelete(questionEditing, {
      grade,
      studentFeedback,
      notifyStudent,
    });
    notify('success', t('delete-success'));
    handleDeleteModalClose();
    trackEvent('question', 'delete');
    if (areOtherQuestionsToApprove) {
      mutationEditorQuestionVerify(nextQuestion || previousQuestion);
    } else {
      mutationEditorClear();
    }
  };

  const handlePropose = async (values) => {
    const { tags, title, ...content } = extract(values, validationSchema);
    const {
      data: { questionPropose },
    } = await mutationQuestionPropose({
      courseId: course.id,
      type: questionType,
      tags,
      title,
      content,
    });
    notify('success', t('propose-success'));
    trackEvent('question', 'propose');
    return questionPropose;
  };

  const handleQuestionsImport = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      const extension = variables.file.name.split('.').slice(-1)[0];
      if (
        [
          'ppt',
          'pps',
          'pot',
          'pptx',
          'ppsx',
          'potx',
          'odp',
          'key',
          'pdf',
          'apkg',
        ].includes(extension)
      ) {
        const filePath = await apiUploadsS3(variables.file);
        const {
          data: { questionImportFile: questions },
        } = await mutationQuestionImportFile({
          ...variables,
          courseId: course.id,
          extension,
          fileName: variables.file.name,
          filePath,
        });
        if (Array.isArray(questions)) {
          if (!questions.length) {
            notify('error', t('question-import-error-empty'));
          } else {
            notify(
              'success',
              t('question-import-success', {
                count: questions.length,
              }),
            );
          }
        }
        mutationEditorClear();
        trackEvent('question', 'import-file');
      }
    } catch (err) {
      form.setStatus('500');
      notify('error', t('question-import-error'));
      reportError('question', 'import-file', err);
    }
  };

  const handleUpdate = async (values) => {
    const { tags, title, ...content } = extract(values, validationSchema);
    const {
      data: { questionUpdate },
    } = await mutationQuestionUpdate(questionEditing, {
      type: questionType,
      tags,
      title,
      content,
    });
    trackEvent('question', 'update');
    return questionUpdate;
  };

  const handleSave = async (values, { setValues }) => {
    try {
      const isDirty = checkIfDirty(values);
      let question = questionEditing;
      const wantToRecreateQuestion =
        (editorStatus === EDITOR_STATUSES.QUESTION_CREATE ||
          editorStatus === EDITOR_STATUSES.QUESTION_UPDATE) &&
        values.wantRecreate;
      const wantToReProposeQuestion =
        editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE &&
        values.wantRecreate;
      const shouldCreateQuestion =
        editorStatus === EDITOR_STATUSES.QUESTION_CREATE && isDirty;
      const shouldProposeQuestion =
        editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE && isDirty;
      const shouldApproveQuestion =
        editorStatus === EDITOR_STATUSES.QUESTION_APPROVE;
      const shouldUpdateQuestion =
        editorStatus === EDITOR_STATUSES.QUESTION_UPDATE && isDirty;
      const shouldDisplaySavedQuestionMessage =
        shouldApproveQuestion ||
        shouldCreateQuestion ||
        shouldProposeQuestion ||
        shouldUpdateQuestion;
      if (shouldCreateQuestion) {
        question = await handleCreate(values);
      } else if (shouldProposeQuestion) {
        question = await handlePropose(values);
      } else if (shouldApproveQuestion) {
        question = await handleApprove(values);
      } else if (shouldUpdateQuestion) {
        question = await handleUpdate(values);
      }
      if (values.wantPreview && question) {
        mutationEditorQuestionPreview(question);
      } else if (wantToRecreateQuestion) {
        mutationEditorQuestionCreate(questionType);
        await setValues(initialValues);
      } else if (wantToReProposeQuestion) {
        mutationEditorQuestionPropose(questionType);
        await setValues(initialValues);
      } else if (areOtherQuestionsToApprove) {
        mutationEditorQuestionVerify(nextQuestion || previousQuestion);
      } else {
        mutationEditorClear();
      }
      if (shouldDisplaySavedQuestionMessage) {
        displaySavedQuestionMessage();
      }
    } catch (err) {
      reportError('question', 'edition', err);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={
        questionInfo?.type === 'PDFOrPPT' ? handleQuestionsImport : handleSave
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ dirty, handleSubmit, setValues, values }) => (
        <>
          <SubPage
            isOpen={isModalOpen}
            onExit={() =>
              handleClose({ dirty, handleSubmit, setValues, values })
            }
            isSidePanel={true}
          >
            {isPreview ? (
              <QuestionPreview
                course={course}
                isQuestionSaved={isQuestionSaved}
                nextQuestion={nextQuestion}
                onCancel={() =>
                  handleClose({ dirty: false, handleSubmit, setValues, values })
                }
                previousQuestion={previousQuestion}
              />
            ) : editorComponent ? (
              <Form className={cx('form')}>
                <QuestionEditor
                  course={course}
                  isQuestionSaved={isQuestionSaved}
                  nextQuestion={nextQuestion}
                  onCancel={() =>
                    handleClose({
                      dirty: false,
                      handleSubmit,
                      setValues,
                      values,
                    })
                  }
                  onDelete={handleDelete}
                  onDeleteModalOpen={handleDeleteModalOpen}
                  onExit={() =>
                    handleClose({ dirty, handleSubmit, setValues, values })
                  }
                  onGradeModalOpen={handleGradeModalOpen}
                  previousQuestion={previousQuestion}
                />
              </Form>
            ) : isModalOpen ? (
              <QuestionTypes
                onExit={() =>
                  handleClose({ dirty, handleSubmit, setValues, values })
                }
                questionsInfo={questionsInfo}
              />
            ) : null}
          </SubPage>
          {/* Validate a proposed question */}
          <QuestionGrade
            isOpen={isGradeModalOpen}
            onModalClose={handleGradeModalClose}
            onSubmit={handleSubmit}
          />
          {/* Delete a proposed question */}

          <QuestionGrade
            isOpen={isDeleteModalOpen}
            onModalClose={handleDeleteModalClose}
            onSubmit={() => handleDelete(values)}
          />
        </>
      )}
    </Formik>
  );
};

QuestionEdit.propTypes = {
  course: PropTypes.object.isRequired,
};

export default QuestionEdit;
