import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useMutationChapterCreate } from 'gql/mutations/chapter';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationQuestionImportQuizWizard } from 'gql/mutations/question';
import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import { reportError, trackEvent } from 'lib/tracking';
import { notify } from 'uikit/Notifications';
import SubPage from 'uikit/SubPageLegacy';

import { useQueryWoogen } from '../../../../gql/queries/woogen';
import styles from './QuestionCreateFromIA.module.scss';

const cx = classNames.bind(styles);

const QuestionCreateFromIA = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/QuestionCreateFromIA',
  });

  const mutationChapterCreate = useMutationChapterCreate();

  const mutationQuestionImportQuizWizard =
    useMutationQuestionImportQuizWizard();

  const { isLoading, data } = useQueryWoogen();

  const { data: dataMe } = useQueryMe();

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const {
    data: {
      app: { language: localLanguage },
    },
  } = useQueryLocalApp();

  const me = dataMe?.me;

  useEffect(() => {
    async function handleMessage(message) {
      try {
        const {
          data: { type, questions },
        } = message;
        if (type !== 'quizwizard' || !questions.length) {
          return;
        }
        const {
          data: { chapterCreate: newChapter },
        } = await mutationChapterCreate({
          courseId: course.id,
          title: t('ia-chapter-title'),
        });

        const slices = [];

        const sliceSize = 50;
        for (let i = 0; i < questions.length; i += sliceSize) {
          slices.push(questions.slice(i, i + sliceSize));
        }

        await Promise.all(
          slices.map(async (slice) => {
            await mutationQuestionImportQuizWizard({
              courseId: course.id,
              chapterId: newChapter.id,
              payload: {
                questions: slice,
              },
            });
          }),
        );

        notify('ia', t(`questions-created-${me.role}`));
        trackEvent('question', 'import-quizwizard');
        mutationEditorClear();
      } catch (err) {
        notify('error', t('questions-not-created'));
        reportError('question', 'import-quizwizard', err);
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [course, me, mutationChapterCreate, mutationQuestionImportQuizWizard, t]);

  const language = ['en', 'fr'].includes(localLanguage) ? localLanguage : 'en';

  const isModalOpen = editorStatus === EDITOR_STATUSES.QUESTION_CREATE_FROM_IA;

  return (
    <SubPage
      headerSize="default"
      isOpen={isModalOpen}
      onExit={mutationEditorClear}
    >
      {isLoading ? undefined : (
        <iframe
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={cx('iframe')}
          frameBorder="0"
          height="315"
          src={`${process.env.REACT_APP_QUIZ_WIZARD_URL}/create-content/source?wooflash=true&language=${language}&authToken=${data?.woogen.token}`}
          title="YouTube video player"
          width="560"
        />
      )}
    </SubPage>
  );
};

QuestionCreateFromIA.propTypes = {
  course: PropTypes.object.isRequired,
};

export default QuestionCreateFromIA;
