import t from 'helpers/t';

import Editor from './Editor/Editor';
import Recto from './Recto/Recto';
import Verso from './Verso/Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const HomeScreen = {
  info: {
    type: 'HomeScreen',
    name: t('Questions/HomeScreen/name'),
    tooltip: t('Questions/HomeScreen/tooltip'),
    icon: svg,
    picture,
    color: 'blue',
    isCorrectable: false,
    isOneSided: true,
    isInScore: false,
    isReportable: false,
    study: true,
    play: false,
    test: false,
    isNew: false,
    canFullForceCorrect: false,
    canPartialForceCorrect: false,
  },
  Editor,
  Recto,
  Verso,
};

export default HomeScreen;
