import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
  getDimensions,
  getEventCursorPosition,
  getNormalizedPinPosition,
  getNormalizedZonePosition,
} from 'questions/Shared/utils/images';

import LegendPin from './LegendPin/LegendPin';
import Zone from './Zone/Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ZONE_MIN_SIZE = 25;

const ImageContainer = ({
  className = null,
  imageSrc,
  legends,
  onLegendsSet,
  onZonesSet,
  zones,
}) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const [activeElementRef, setActiveElementRef] = useState(null);
  const [data, setData] = useState({
    mouse: {
      positionX: null,
      positionY: null,
      fingerId: null,
    },
    draggingMode: null,
    updatedElementRef: null,
    updatedElement: null,
    basePosition: {
      baseLeft: undefined,
      baseTop: undefined,
      baseHeight: undefined,
      baseWidth: undefined,
    },
  });

  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, [imageRef, wrapperRef]);

  useEffect(() => {
    function handleScreenResize() {
      if (wrapperRef && imageRef) {
        handleImageLoaded();
      }
    }

    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleImageLoaded, imageRef, wrapperRef]);

  useEffect(() => {
    function handleMouseUp(event) {
      const {
        mouse: { positionX, positionY, fingerId },
        draggingMode,
        basePosition,
      } = data;
      const [clientX, clientY] = getEventCursorPosition(event, fingerId);
      const distance = Math.sqrt(
        (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
      );
      if (draggingMode === 'create' && distance < 10) {
        const position = getNormalizedPinPosition(
          {
            left: clientX - basePosition.baseLeft,
            top: clientY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const newLegend = {
          x: position.x,
          y: position.y,
          content: [],
        };
        onLegendsSet([...legends, newLegend]);
      }
      setData({
        mouse: {
          positionX: null,
          positionY: null,
          fingerId: null,
        },
        draggingMode: null,
        updatedElementRef: null,
        updatedElement: null,
        basePosition: {
          baseLeft: undefined,
          baseTop: undefined,
          baseHeight: undefined,
          baseWidth: undefined,
        },
      });
    }

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [data, image.diagonal, legends, onLegendsSet]);

  function handleElementUpdate(ref, updatedData) {
    if (ref.type === 'zone') {
      const normalizedData = getNormalizedZonePosition(
        updatedData,
        image.diagonal,
        zones[ref.index].shapeType,
      );
      const updatedZones = [...zones];
      updatedZones[ref.index] = {
        ...updatedZones[ref.index],
        ...normalizedData,
      };
      onZonesSet(updatedZones);
    } else {
      const normalizedData = getNormalizedPinPosition(
        updatedData,
        image.diagonal,
      );
      const updatedLegends = [...legends];
      updatedLegends[ref.index] = {
        ...updatedLegends[ref.index],
        ...normalizedData,
      };
      onLegendsSet(updatedLegends);
    }
  }

  function handleMouseDown(event, index, dragMode, type) {
    event.stopPropagation();
    const [positionX, positionY, fingerId] = getEventCursorPosition(event);
    const elementPosition =
      type === 'zone'
        ? getDenormalizedZonePosition(zones[index], image.diagonal)
        : type === 'legend'
        ? getDenormalizedPinPosition(legends[index], image.diagonal)
        : imageRef.current.getBoundingClientRect();
    setActiveElementRef({
      type,
      index,
    });
    setData({
      mouse: {
        positionX,
        positionY,
        fingerId,
      },
      draggingMode: dragMode,
      updatedElementRef: {
        type,
        index,
      },
      updatedElement:
        type === 'zone'
          ? zones[index]
          : type === 'legend'
          ? legends[index]
          : null,
      basePosition: {
        baseLeft: elementPosition.left,
        baseTop: elementPosition.top,
        baseHeight: elementPosition.height,
        baseWidth: elementPosition.width,
      },
    });
  }

  function handleMouseMove(event) {
    const {
      mouse: { positionX, positionY, fingerId },
      draggingMode,
      updatedElementRef,
      updatedElement,
      basePosition,
    } = data;
    const [clientX, clientY] = getEventCursorPosition(event, fingerId);
    if (draggingMode === 'create') {
      const distance = Math.sqrt(
        (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
      );
      if (distance < 10) {
        return;
      }
      const startPosition = getNormalizedPinPosition(
        {
          left: positionX - basePosition.baseLeft,
          top: positionY - basePosition.baseTop,
        },
        image.diagonal,
      );
      const endPosition = getNormalizedPinPosition(
        {
          left: clientX - basePosition.baseLeft,
          top: clientY - basePosition.baseTop,
        },
        image.diagonal,
      );
      const newZone = {
        h: Math.abs(endPosition.y - startPosition.y),
        r: null,
        w: Math.abs(endPosition.x - startPosition.x),
        x: Math.min(startPosition.x, endPosition.x),
        y: Math.min(startPosition.y, endPosition.y),
        shapeType: 'rectangle',
      };
      if (newZone.h < 0.01 || newZone.w < 0.01) {
        return;
      }
      const zonePosition = getDenormalizedZonePosition(newZone, image.diagonal);
      setActiveElementRef({
        type: 'zone',
        index: zones.length,
      });
      setData((d) => ({
        ...d,
        draggingMode: 'resize',
        updatedElementRef: {
          type: 'zone',
          index: zones.length,
        },
        updatedElement: newZone,
        basePosition: {
          baseLeft: zonePosition.left,
          baseTop: zonePosition.top,
          baseHeight: zonePosition.height,
          baseWidth: zonePosition.width,
        },
      }));
      onZonesSet([...zones, newZone]);
      return;
    }
    if (!draggingMode || !updatedElement) {
      return;
    }
    const elementPosition =
      updatedElementRef.type === 'zone'
        ? getDenormalizedZonePosition(updatedElement, image.diagonal)
        : getDenormalizedPinPosition(updatedElement, image.diagonal);
    const offsetX = clientX - positionX;
    const offsetY = positionY - clientY;
    const elementWidth = basePosition.baseWidth || 0;
    const elementHeight = basePosition.baseHeight || 0;
    const maxWidth = wrapperRef.current.offsetWidth;
    const maxHeight = wrapperRef.current.offsetHeight;
    if (draggingMode === 'position') {
      let newLeftPosition = basePosition.baseLeft + offsetX;
      let newTopPosition = basePosition.baseTop - offsetY;
      if (newLeftPosition <= 0 - elementWidth / 2) {
        newLeftPosition = 0 - elementWidth / 2;
      } else if (newLeftPosition >= maxWidth - elementWidth / 2) {
        newLeftPosition = maxWidth - elementWidth / 2;
      }
      if (newTopPosition <= 0 - elementHeight / 2) {
        newTopPosition = 0 - elementHeight / 2;
      } else if (newTopPosition >= maxHeight - elementHeight / 2) {
        newTopPosition = maxHeight - elementHeight / 2;
      }
      handleElementUpdate(updatedElementRef, {
        width: elementPosition.width,
        height: elementPosition.height,
        left: newLeftPosition,
        top: newTopPosition,
      });
    } else if (draggingMode === 'resize') {
      const dimensions = {
        left: elementPosition.left,
        top: elementPosition.top,
      };
      let newWidth;
      let newHeight;
      newWidth = basePosition.baseWidth + offsetX;
      newHeight = basePosition.baseHeight - offsetY;
      if (newWidth >= maxWidth - elementPosition.left) {
        newWidth = maxWidth - elementPosition.left;
      } else if (newWidth < ZONE_MIN_SIZE) {
        newWidth = ZONE_MIN_SIZE;
      }
      if (newHeight >= maxHeight - elementPosition.top) {
        newHeight = maxHeight - elementPosition.top;
      } else if (newHeight < ZONE_MIN_SIZE) {
        newHeight = ZONE_MIN_SIZE;
      }
      dimensions.width = newWidth;
      dimensions.height = newHeight;
      handleElementUpdate(updatedElementRef, dimensions);
    }
  }

  function handleZoneDelete(index) {
    const filteredZones = zones.filter(
      (item, itemIndex) => itemIndex !== index,
    );
    onZonesSet(filteredZones);
    setActiveElementRef(null);
    setData({
      mouse: {
        positionX: null,
        positionY: null,
        fingerId: null,
      },
      draggingMode: null,
      updatedElementRef: null,
      updatedElement: null,
      basePosition: {
        baseLeft: undefined,
        baseTop: undefined,
        baseHeight: undefined,
        baseWidth: undefined,
      },
    });
  }

  return (
    <div className={cx('container', className)} ref={wrapperRef}>
      <button
        className={cx('wrapper')}
        onMouseDown={(event) => handleMouseDown(event, -1, 'create', 'new')}
        onTouchStart={(event) => handleMouseDown(event, -1, 'create', 'new')}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
        type="button"
      >
        <img
          alt="img"
          className={cx('image')}
          draggable="false"
          onLoad={handleImageLoaded}
          ref={imageRef}
          src={imageSrc}
          style={{
            width: image.width,
            height: image.height,
          }}
        />
      </button>
      {zones.map((zone, index) => (
        <Zone
          handleDelete={() => handleZoneDelete(index)}
          handleMouseMove={handleMouseMove}
          handleStartGrab={(event) =>
            handleMouseDown(event, index, 'position', 'zone')
          }
          handleStartResize={(event) =>
            handleMouseDown(event, index, 'resize', 'zone')
          }
          image={image}
          imageSrc={imageSrc}
          isActive={
            activeElementRef &&
            activeElementRef.type === 'zone' &&
            activeElementRef.index === index
          }
          key={`zone-${index}`}
          sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
        />
      ))}
      {legends.map((legend, index) => (
        <LegendPin
          handleMouseMove={handleMouseMove}
          handleStartGrab={(event) =>
            handleMouseDown(event, index, 'position', 'legend')
          }
          index={index}
          isActive={
            activeElementRef &&
            activeElementRef.type === 'legend' &&
            activeElementRef.index === index
          }
          key={`legend-${index}`}
          positionData={getDenormalizedPinPosition(legend, image.diagonal)}
        />
      ))}
    </div>
  );
};

ImageContainer.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLegendsSet: PropTypes.func.isRequired,
  onZonesSet: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageContainer;
